import { gql } from '@apollo/client';

export const COMPANY_LIST = gql`
  query (
    $limit: Int
    $page: Int
    $search_term: String
    $sorting: [SorterInput]
  ) {
    CompanyItems(
      limit: $limit
      page: $page
      search_term: $search_term
      sorting: $sorting
    ) {
      items {
        _id
        name
        cnpj
        is_disabled
      }
      pages
    }
  }
`;

export const UPDATE_COMPANY = gql`
  mutation companySetup($id: String!, $isDisabled: Boolean) {
    companySetup(id: $id, isDisabled: $isDisabled)
  }
`;

export const QUERY_COMPANY = gql`
  query queryCompany($companyId: String!) {
    CompanyItems(limit: 1, page: 1, id: $companyId) {
      items {
        name
        legalName
        cityTaxId
        stateTaxId
        contacts {
          name
          email
        }
        cnpj
        cpom
        fiscalCategory
        publicityAgency
        bankAccounts {
          name
          accountNumber
          accountType
          agencyNumber
          isPrincipal
        }
        directTax
        receiptTax
        flagKey
        flagReceipt
        files {
          _id
          resourceType
          resourceId
          path
          filename
          originalname
          mimetype
          encoding
          fileExt
          url
          filesize
        }
        created_at
        updated_at
        deleted_at
        is_disabled
        defaultProposalPrompt
        monthlyValue
        defaultPoPrompt
      }
    }
  }
`;

import styled from 'styled-components';
import { colors } from 'styles/colors';

export const NewBudgetContainer = styled.div`
  margin-bottom: 2rem;
`;

export const FieldsContainer = styled.div`
  background-color: ${colors.backgroundLight};
  min-height: 42.87rem;
  padding: 1.25rem 0;
  border-radius: 4px;
  margin-bottom: 0.5rem;
`;

export const FieldContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;

  > div {
    margin: 0 0.75rem;
  }
`;

export const ButtonsContainer = styled.div`
  padding: 1.25rem 1.875rem;
  display: flex;
  justify-content: space-between;
  background-color: ${colors.backgroundLight};

  > div {
    display: flex;
  }
`;

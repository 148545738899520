import { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { useWorkspace } from 'contexts/WorkspaceContext';
import { AnimatePresence } from 'framer-motion';
import { capitalize } from 'utils/formatters/capitalize';

import { HomeIcon } from '../Icons/HomeIcon';
import { NeuronOsIcon } from '../Icons/NeuronOsIcon';
import { COMPANY_LIST } from './query';
import {
  ArrowStyleContainer,
  SelectedTextContainer,
  WorkspaceSelectorStyled,
  WorkspaceListDiv,
  ListStyleContainer,
  ListItemStyleContainer,
  ListTextContainer,
  InvisibleDivContainer,
  LabelHolder,
  NeuronIconStyledDiv,
  NeuronSelectorDiv,
  NeuronTextContainer,
  NeuronSelectorBorder,
} from './style';

interface WorkspaceSelectorProps {
  isExpanded: boolean;
}
interface Company {
  text: string;
  key: string;
}

export function WorkspaceSelector({ isExpanded }: WorkspaceSelectorProps) {
  const [toogleMenu, setToggleMenu] = useState(false);
  const [getCompany, getCompanyRes] = useLazyQuery(COMPANY_LIST);
  const [companyList, setCompanyList] = useState([] as Company[]);
  const handleClick = (e: any) => {
    if (
      e.type === 'click' ||
      (e.type === 'keydown' && (e.keyCode === 32 || e.keyCode === 13))
    )
      setToggleMenu((prev) => !prev);
  };
  const { neuronMode, setCurrentCompany, setNeuronMode, currentCompanyId } =
    useWorkspace();

  function handleWorkspaceChange(company: Company) {
    setNeuronMode(false);
    setCurrentCompany(company.key, company.text);
  }

  function enterNeuronOsMode() {
    setNeuronMode(true);
  }

  useEffect(() => {
    getCompany();
  }, [getCompany]);
  useEffect(() => {
    if (getCompanyRes.data?.CompanyItems?.items)
      setCompanyList(
        getCompanyRes.data?.CompanyItems?.items
          ?.filter((company) => !company.is_disabled)
          .map((company) => {
            return {
              text: capitalize(company.name),
              key: company._id,
            };
          }),
      );
  }, [getCompanyRes.data]);
  return (
    <>
      <WorkspaceSelectorStyled
        role="button"
        tabIndex={0}
        onClick={handleClick}
        onKeyDown={handleClick}
      >
        <LabelHolder>
          <HomeIcon variant="light" width={24} height={24} />
          <SelectedTextContainer
            initial={false}
            animate={{
              opacity: isExpanded ? 1 : 0,
              width: isExpanded ? 140 : 0,
            }}
            transition={{
              type: 'linear',
              duration: 0.3,
              delay: isExpanded ? 0.3 : 0,
            }}
          >
            {neuronMode
              ? 'Neuron OS'
              : companyList.find((company) => company.key === currentCompanyId)
                  ?.text}
          </SelectedTextContainer>
        </LabelHolder>

        <ArrowStyleContainer direction={toogleMenu ? 'down' : 'up'} />
      </WorkspaceSelectorStyled>
      <AnimatePresence>
        {toogleMenu && (
          <>
            <InvisibleDivContainer
              onClick={() => {
                setToggleMenu(false);
              }}
            />
            <WorkspaceListDiv
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <ListStyleContainer>
                <NeuronSelectorDiv
                  role="button"
                  onClick={() => {
                    enterNeuronOsMode();
                    setToggleMenu(false);
                  }}
                  onKeyDown={(key) => {
                    if (key.code === 'Enter' || key.code === 'Space ') {
                      enterNeuronOsMode();
                      setToggleMenu(false);
                    }
                  }}
                >
                  <NeuronIconStyledDiv>
                    <NeuronOsIcon width={15} height={15} variant="light" />
                  </NeuronIconStyledDiv>
                  <NeuronTextContainer>Neuron OS</NeuronTextContainer>
                </NeuronSelectorDiv>
                <NeuronSelectorBorder />
                {companyList.map((option) => {
                  return (
                    <ListItemStyleContainer
                      key={`workspace-${option.text}`}
                      role="button"
                      onClick={() => {
                        handleWorkspaceChange(option);
                        setToggleMenu(false);
                      }}
                      onKeyDown={(key) => {
                        if (key.code === 'Enter' || key.code === 'Space ') {
                          handleWorkspaceChange(option);
                          setToggleMenu(false);
                        }
                      }}
                    >
                      <ListTextContainer>{option.text}</ListTextContainer>
                    </ListItemStyleContainer>
                  );
                })}
              </ListStyleContainer>
            </WorkspaceListDiv>
          </>
        )}
      </AnimatePresence>
    </>
  );
}

/* eslint-disable array-callback-return */
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';

import { useLazyQuery, useMutation } from '@apollo/client';
import { CopyIcon } from 'components/atoms/Icons/CopyIcon';
import { GearIcon } from 'components/atoms/Icons/GearIcon';
import { TrashIcon } from 'components/atoms/Icons/TrashIcon';
import { DeleteModal } from 'components/molecules/DeleteModal';
import { Table } from 'components/organisms/Table';
import { Tabs } from 'components/organisms/Tabs';
import { Toolbar } from 'components/organisms/Toolbar';
import { useModal } from 'contexts/ModalContext';
import { useWorkspace } from 'contexts/WorkspaceContext';
import { CLIENT_LIST } from 'pages/Clients/query';
import { IconButton } from 'styles/miscellany';
import { toCurrencyBrl } from 'utils/masks';

import { ActionIcon } from '../../components/atoms/Icons/ActionIcon';
import AwaitingApproval from './components/AwaitingApproval';
import { CopyModal } from './components/CopyModal';
import { CREATE_BUDGET } from './components/NewBudget/query';
import { DELETE_BUDGET, GET_BUDGET_LIST, SEND_FOR_APPROVAL } from './query';
import { BudgetsContainer } from './style';

export function Budgets() {
  const [searchTerm, setSearchTerm] = useState('');

  const { currentCompanyId } = useWorkspace();

  const { openModal } = useModal();
  const [getBudgets, getBudgetsRes] = useLazyQuery(GET_BUDGET_LIST, {
    notifyOnNetworkStatusChange: true,
  });
  const [deleteBudget] = useMutation(DELETE_BUDGET);
  const [sendForApproval] = useMutation(SEND_FOR_APPROVAL);
  const history = useHistory();
  const budgetItems = getBudgetsRes.data?.BudgetItems?.items || [];
  const [getClients] = useLazyQuery(CLIENT_LIST, {
    notifyOnNetworkStatusChange: true,
  });
  const [createBudget] = useMutation(CREATE_BUDGET);

  useEffect(() => {
    getClients({ variables: { unit: currentCompanyId } });
  }, [currentCompanyId, getClients]);

  const handleDelete = (id: string) => {
    openModal({
      content: (
        <DeleteModal
          onAccept={() =>
            deleteBudget({ variables: { id } }).then(() => {
              if (getBudgetsRes.refetch) {
                getBudgetsRes.refetch();
              }
            })
          }
        />
      ),
    });
  };

  const cloneBudget = (row) => {
    openModal({
      content: (
        <CopyModal
          onAccept={() => {
            const item = budgetItems.find((i) => i._id === row._id);
            const payload = {
              ...item,
              clientId: item.client._id,
              unit: currentCompanyId,
            };
            delete payload._id;
            payload?.budGroups?.forEach((group) => delete group.__typename);
            payload?.budComissions?.forEach((group) => delete group.__typename);
            payload?.budDeductions?.forEach((group) => delete group.__typename);

            createBudget({
              variables: payload,
            })
              .then((result) => {
                toast.success('Copiado com Sucesso!');
                const newId = result?.data?.createBudget?._id;
                history.push(`/budgets/${newId}/information`);
              })
              .catch(() =>
                toast.error('Ocorreu um erro ao clonar o orçamento.'),
              );
          }}
        />
      ),
    });
  };

  const handleAction = () => {
    history.push('/budgets/newbudget/information');
  };

  const tabHeader = ['Orçando', 'Aguardando Aprovação'];

  const budgetingColumns = [
    {
      Header: 'ID',
      accessor: '_id',
    },
    {
      Header: 'Cliente',
      accessor: 'client.name',
    },
    {
      Header: 'Área/Produto',
      accessor: 'area',
    },
    {
      Header: 'Campanha/Projeto/Task',
      accessor: 'campaign',
    },
    {
      Header: 'Faturamento',
      accessor: 'revenue',
      Cell: ({ value }) => toCurrencyBrl(value),
    },
  ];

  return (
    <BudgetsContainer>
      <Toolbar
        title="Orçamentos"
        onSearch={setSearchTerm}
        onAction={handleAction}
      />
      <Tabs
        tabHeader={tabHeader}
        tabContent={[
          <Table
            style={{ marginTop: '2rem' }}
            pages={1}
            fetchData={getBudgets}
            fetchVariables={{ status: null, unit: currentCompanyId }}
            searchTerm={searchTerm}
            columns={budgetingColumns}
            loading={getBudgetsRes.loading}
            emptyText="Não há registros para consulta"
            data={budgetItems}
            actions={(row) => [
              {
                component: (
                  <IconButton
                    role="button"
                    tabIndex={0}
                    title="Deletar"
                    onClick={() => handleDelete(row._id)}
                  >
                    <TrashIcon variant="light" width={20} height={20} />
                  </IconButton>
                ),
              },
              {
                component: (
                  <IconButton
                    role="button"
                    tabIndex={0}
                    title="Enviar para aprovação"
                    onClick={() => {
                      sendForApproval({ variables: { id: row._id } }).then(
                        (_) => {
                          toast.success('Orçamento enviado para aprovação!');
                          if (getBudgetsRes.refetch) {
                            getBudgetsRes.refetch();
                          }
                        },
                      );
                    }}
                  >
                    <ActionIcon />
                  </IconButton>
                ),
              },
              {
                component: (
                  <IconButton
                    role="button"
                    tabIndex={0}
                    title="Editar"
                    onClick={() =>
                      history.push(`/budgets/${row._id}/information`)
                    }
                  >
                    <GearIcon variant="light" width={20} height={20} />
                  </IconButton>
                ),
              },
              {
                component: (
                  <IconButton
                    role="button"
                    tabIndex={0}
                    title="Clonar orçamento"
                    onClick={() => cloneBudget(row)}
                  >
                    <CopyIcon />
                  </IconButton>
                ),
              },
            ]}
          />,
          <AwaitingApproval
            key="awaitingApproval"
            searchTerm={searchTerm}
            deleteAction={handleDelete}
          />,
        ]}
      />
    </BudgetsContainer>
  );
}

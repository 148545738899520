import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useLazyQuery } from '@apollo/client';
import { GearIcon } from 'components/atoms/Icons/GearIcon';
import { Table } from 'components/organisms/Table';
import { Toolbar } from 'components/organisms/Toolbar';
import { useWorkspace } from 'contexts/WorkspaceContext';
import { IconButton, StatusLabel } from 'styles/miscellany';
import { capitalize } from 'utils/formatters/capitalize';
import { toCurrencyBrl } from 'utils/masks';

import { GET_PROJECTS_LIST } from './query';
import { ProjectsContainer } from './style';

export function Projects() {
  const history = useHistory();
  const { currentCompanyId } = useWorkspace();
  const [searchTerm, setSearchTerm] = useState('');
  const [getProjects, getProjectsRes] = useLazyQuery(GET_PROJECTS_LIST, {
    notifyOnNetworkStatusChange: true,
  });
  const items = getProjectsRes.data?.ProjectItems?.items || [];

  const handleAction = (id) => {
    history.push(`projects/${id}`);
  };

  return (
    <ProjectsContainer>
      <Toolbar title="Projetos" onSearch={setSearchTerm} />
      <Table
        pages={getProjectsRes.data?.ProjectItems?.pages}
        fetchData={getProjects}
        searchTerm={searchTerm}
        fetchVariables={{ unit: currentCompanyId }}
        filter
        manualSort
        columns={[
          {
            Header: 'Status',
            accessor: (row) => (
              <StatusLabel
                variant={row.status === 'finalizado' ? 'inactive' : 'active'}
              >
                {capitalize(row.status)}
              </StatusLabel>
            ),
          },
          { Header: 'ID', accessor: '_id' },
          {
            Header: 'Cliente',
            accessor: (row) => row?.client?.legalName,
          },
          { Header: 'Produto', accessor: 'area' },
          { Header: 'Campanha', accessor: 'campaign' },
          {
            Header: 'Valor Faturado',
            accessor: (row) =>
              row?.revenue ? toCurrencyBrl(`${row?.revenue.toFixed(2)}`) : '',
          },
          {
            Header: 'Custos Projetados',
            accessor: (row) =>
              row?.budget?.revenue
                ? toCurrencyBrl(`${row?.budget?.revenue}`)
                : '',
          },
          {
            Header: 'Custo Realizado',
            accessor: (row) =>
              row?.costSoFar ? toCurrencyBrl(`${row?.costSoFar}`) : '',
          },
        ]}
        loading={getProjectsRes.loading}
        emptyText="Não há registros para consulta"
        data={items}
        actions={(rowData) => [
          {
            component: (
              <IconButton
                role="button"
                tabIndex={0}
                onClick={() => handleAction(rowData._id)}
              >
                <GearIcon variant="light" width={20} height={20} />
              </IconButton>
            ),
          },
        ]}
      />
    </ProjectsContainer>
  );
}

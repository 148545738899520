import { gql } from '@apollo/client';

export const GET_PROJECT_BY_ID = gql`
  query ($id: String) {
    ProjectItems(id: $id) {
      items {
        _id
        area
        campaign
        revenue
        client {
          _id
        }
        budget {
          _id
          area
          campaign
          task
          revenue
          unit
          expectation
        }
        costSoFar {
          value
          percentage
        }
        budgetCost {
          value
          percentage
        }
      }
      pages
    }
  }
`;

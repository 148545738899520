import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { useWorkspace } from 'contexts/WorkspaceContext';
import { FieldContainer } from 'pages/Budgets/components/NewBudget/style';
import { calculateAllBudgetTotals } from 'pages/Budgets/service';
import { colors } from 'styles/colors';
import { FlexContainer } from 'styles/miscellany';
import { toCurrencyBrl, toCurrency, clearCurrencyNumberBrl } from 'utils/masks';

import { GroupsText, StructureRevenueContainer } from './style';

interface Props {
  companyTax: number;
}

export function StructureRevenue(props: Props) {
  const methods = useFormContext();
  const budgetState = methods.watch();

  const {
    totalBilling,
    netBilling,
    internalProductionTotal,
    outsourceTotal,
    repassTotal,
    comissionsTotal,
  } = calculateAllBudgetTotals(budgetState);

  const { companyTax } = props;
  const { currentCompanyName } = useWorkspace();

  const earnings = totalBilling - repassTotal;

  useEffect(() => {
    methods.setValue('revenue', totalBilling.toString());
  }, [totalBilling]);

  const updateEarnings = () => {
    let total = earnings;
    if (companyTax) {
      total = +Number(
        (earnings * (1 - clearCurrencyNumberBrl(companyTax) / 100)) / 100,
      ).toFixed(2);
    }
    return total;
  };

  const netEarnings = updateEarnings();

  return (
    <StructureRevenueContainer>
      <GroupsText>Subtotal</GroupsText>
      <FieldContainer style={{ alignItems: 'flex-start' }}>
        <FlexContainer>
          <div>
            <p>
              Faturamento Líquido <strong>{currentCompanyName}</strong>
            </p>
          </div>
          <h4>{toCurrencyBrl(netBilling)}</h4>
        </FlexContainer>
        <FlexContainer>
          <div>
            <p>
              Receita <strong>Bruta</strong>
            </p>
          </div>
          <h4>{toCurrencyBrl(earnings)}</h4>
          <div>
            {toCurrency(Number((earnings / totalBilling) * 100).toFixed(2)) ||
              0}
            %
          </div>
        </FlexContainer>
        <FlexContainer>
          <div>
            <p>
              Receita <strong>Líquida</strong>
            </p>
          </div>
          <h4>{toCurrencyBrl(netEarnings)}</h4>
          <div>
            {toCurrency(
              Number(
                (netEarnings /
                  clearCurrencyNumberBrl(toCurrency(totalBilling))) *
                  100,
              ).toFixed(2),
            ) || 0}
            %
          </div>
        </FlexContainer>
        <FlexContainer>
          <div>
            <p>Faturamento Total</p>
          </div>
          <h4 style={{ color: colors.primary }}>
            {toCurrencyBrl(totalBilling)}
          </h4>
        </FlexContainer>
      </FieldContainer>
      <GroupsText>Resumo</GroupsText>
      <FieldContainer>
        <FlexContainer>
          <div>
            <p>Produção Interna</p>
          </div>
          <div>{toCurrencyBrl(internalProductionTotal)}</div>
        </FlexContainer>
        <FlexContainer>
          <div>
            <p>Terceiros</p>
          </div>
          <div>{toCurrencyBrl(outsourceTotal)}</div>
        </FlexContainer>
        <FlexContainer>
          <div>
            <p>Repasses</p>
          </div>
          <div>{toCurrencyBrl(repassTotal)}</div>
        </FlexContainer>
        <FlexContainer>
          <div>
            <p>Comissões</p>
          </div>
          <div>{toCurrencyBrl(comissionsTotal)}</div>
        </FlexContainer>
        <FlexContainer>
          <div>
            <p>Impostos</p>
          </div>
          <div>
            {toCurrencyBrl(Number(earnings / 100 - netEarnings).toFixed(2))}
          </div>
        </FlexContainer>
      </FieldContainer>
    </StructureRevenueContainer>
  );
}

/* eslint-disable no-console */
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import { useLazyQuery } from '@apollo/client';
import { SelectField } from 'components/atoms/SelectField';
import { Spinner } from 'components/atoms/Spinner';
import { TextArea } from 'components/atoms/TextArea';
import { TextField } from 'components/molecules/TextField';
import { useDrawer } from 'contexts/DrawerContext';
import { useWorkspace } from 'contexts/WorkspaceContext';
import { useFetch } from 'hooks/useFetch';
import { CompanyFile } from 'pages/Companies/components/CompanyForm';
import { DateFieldCustom } from 'pages/ManageProject/components/RecognitionControl/components/DateFieldCustom';
import { Services } from 'services/service';
import { DefaultForm, GridColumn, Line } from 'styles/miscellany';
import { toCurrencyBrl } from 'utils/masks';
import { validator } from 'utils/validators/form-validations';

import { GET_PAYMENT_ITEMS_BY_ID } from './query';
import ReceiptUpload from './ReceiptUpload';
import { BillingControlFormContainer } from './style';

export function BillingControlForm({ paymentId, refetch }) {
  const [files, setFiles] = useState<CompanyFile[]>([]);

  const methods = useForm();
  const { currentCompanyId } = useWorkspace();
  const { closeDrawer, setIsLoading } = useDrawer();
  const { fetchAuthorized } = useFetch();

  const [getPaymentItem, getPaymentItemRes] = useLazyQuery(
    GET_PAYMENT_ITEMS_BY_ID,
  );
  const paymentItem = getPaymentItemRes?.data?.PaymentItems?.items[0];

  useEffect(() => {
    getPaymentItem({ variables: { id: paymentId, unit: currentCompanyId } });
  }, [paymentId, currentCompanyId]);

  useEffect(() => {
    if (paymentItem) {
      Object.entries(paymentItem).forEach(([key, value]) => {
        if (key !== '__typename') {
          methods.setValue(key, value);
        }
        if (key === 'realizedValue') {
          methods.setValue(key, toCurrencyBrl(value));
        }
      });

      if (paymentItem.files?.length) {
        setFiles(
          paymentItem.files.map((file) => {
            const metadata = file.metadata ? JSON.parse(file.metadata) : {};
            return {
              type: 'uploadedFile',
              field: 'other',
              name: file.originalname,
              size: file.filesize,
              file: undefined,
              id: file._id,
              fileUrl: file.url,
              fileType: metadata.fileType,
            } as CompanyFile;
          }),
        );
      }
    }
  }, [paymentItem]);

  const closeAndRefetch = () => {
    if (refetch) {
      refetch();
    }
    closeDrawer();
  };

  const updateFileMetadata = (fileId, fileType) => {
    return fetchAuthorized(`${Services.Filemeta}/${fileId}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ fileType }),
    });
  };

  const uploadFiles = async () => {
    const formdata = new FormData();

    const filesToUpdate = files.filter((file) => {
      return file.type !== 'fileToUpload';
    });
    const filesToUpload = files.filter((file) => {
      return file.type === 'fileToUpload';
    });

    if (filesToUpdate.length > 0) {
      await Promise.all(
        filesToUpdate.map((item) => updateFileMetadata(item.id, item.fileType)),
      );
    }

    if (filesToUpload.length > 0) {
      toast.success('Enviando arquivos, por favor aguarde');
      setIsLoading(true);

      filesToUpload.forEach((fileToUpload) => {
        if (fileToUpload.file) formdata.append('documents', fileToUpload.file);
      });

      const requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow' as RequestRedirect,
      };

      fetchAuthorized(
        `${Services.Upload}?for=payment&attachTo=${paymentId}`,
        requestOptions,
      )
        .then((response) => response.json())
        .then((resp) =>
          Promise.all(
            resp.map((item, index) =>
              updateFileMetadata(item.fileId, filesToUpload[index].fileType),
            ),
          ),
        )
        .then(() => {
          toast.success('Arquivos enviados com sucesso!');
          closeAndRefetch();
          setIsLoading(false);
        })
        .catch(() => {
          toast.error('Ocorreu um erro ao enviar os arquivos.');
          setIsLoading(false);
        });
    } else {
      closeAndRefetch();
    }
  };

  const onSubmit = () => {
    uploadFiles();
  };

  const paymentMethods = [
    { value: 'pix', label: 'Pix' },
    { value: 'ted', label: 'TED' },
    { value: 'doc', label: 'DOC' },
    { value: 'boleto', label: 'Boleto' },
  ];

  if (getPaymentItemRes.loading) {
    return <Spinner />;
  }

  return (
    <BillingControlFormContainer>
      <FormProvider {...methods}>
        <DefaultForm
          onSubmit={methods.handleSubmit(onSubmit)}
          id="billing-control-form"
        >
          <GridColumn length={2} gap={1} fragment>
            <SelectField
              name="status"
              label="Status"
              placeholder="Status"
              options={[
                { value: 'pendente', label: 'Pendente' },
                { value: 'NF Recebida', label: 'NF Recebida' },
                { value: 'PO Emitida', label: 'PO Emitida' },
                { value: 'vencido', label: 'Vencido' },
                { value: 'Pago', label: 'Pago' },
              ]}
              readOnly
            />
            <TextField name="poCode" label="PO" readOnly />
          </GridColumn>
          <SelectField
            name="category"
            label="Categoria"
            placeholder="Categoria"
            options={[
              { value: 'outsource', label: 'Terceiro' },
              { value: 'repass', label: 'Repasse' },
              { value: 'comission', label: 'Comissão' },
            ]}
            readOnly
          />
          <TextField
            name="realizedValue"
            label="Valor"
            pattern="moneyPrefix"
            readOnly
          />
          <GridColumn length={2} gap={1} fragment>
            <TextField name="providerName" label="Fornecedor" readOnly />
            <TextField
              name="client.cnpj"
              label="CNPJ"
              validation={validator('cnpjNotRequired')}
              pattern="cnpjMask"
              readOnly
            />
          </GridColumn>
          <TextField
            name="providerEmail"
            label="Email do fornecedor"
            validation={validator('emailNotRequired')}
            readOnly
          />

          <Line />
          <ReceiptUpload
            files={files}
            onChange={setFiles}
            maxFiles={paymentItem?.category === 'repass' ? 2 : 1}
          />
          <TextField name="nfCode" label="Número da nota" readOnly />
          <GridColumn length={2} gap={1} fragment>
            <DateFieldCustom
              name="nfEmissionDate"
              label="Data da Emissão"
              readOnly
            />
            <DateFieldCustom
              name="nfDueDate"
              label="Data do Vencimento"
              readOnly
            />
            <SelectField
              name="paymentMethod"
              label="Forma de pagamento"
              placeholder="Forma de pagamento"
              options={paymentMethods}
              readOnly
            />
            <TextField name="paymentDate" label="Data de pagamento" readOnly />
          </GridColumn>
          <Line />
          <h6 style={{ margin: '2rem 0 1.5rem' }}>Legal</h6>
          <TextArea name="detailedDescription" label="Observações" readOnly />
        </DefaultForm>
      </FormProvider>
    </BillingControlFormContainer>
  );
}

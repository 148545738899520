import { gql } from '@apollo/client';

export const COMPANY_LIST = gql`
  query ($limit: Int, $page: Int, $search_term: String) {
    CompanyItems(limit: $limit, page: $page, search_term: $search_term) {
      items {
        _id
        name
        is_disabled
      }
      pages
    }
  }
`;

export const UPDATE_COMPANY = gql`
  mutation companySetup(
    $id: String!
    $name: String
    $legalName: String
    $cityTaxId: String
    $stateTaxId: String
    $operationsContactName: String
    $operationsContactEmail: String
    $cnpj: String
    $cpom: String
    $fiscalCategory: String
    $publicityAgency: String
    $bankName: String
    $bankAccountNumber: String
    $bankAgencyNumber: String
    $directTax: String
    $receiptTax: String
    $flagKey: String
    $flagReceipt: String
    $isDisabled: Boolean
  ) {
    companySetup(
      id: $id
      name: $name
      legalName: $legalName
      cityTaxId: $cityTaxId
      stateTaxId: $stateTaxId
      operationsContactName: $operationsContactName
      operationsContactEmail: $operationsContactEmail
      cnpj: $cnpj
      cpom: $cpom
      fiscalCategory: $fiscalCategory
      publicityAgency: $publicityAgency
      bankName: $bankName
      bankAccountNumber: $bankAccountNumber
      bankAgencyNumber: $bankAgencyNumber
      directTax: $directTax
      receiptTax: $receiptTax
      flagKey: $flagKey
      flagReceipt: $flagReceipt
      isDisabled: $isDisabled
    )
  }
`;

import { useCallback, useMemo } from 'react';

import { Services } from 'services/service';
import { storageKeys } from 'services/storage-keys';

export function useCurrencyConverter() {
  const BASE_URL = Services.Currency;

  const fetchRates = useCallback(() => {
    const accessKey = 'ff346242528e6d710dfed0a36c4b8aab';

    return fetch(
      `${BASE_URL}?access_key=${accessKey}&symbols=AUD,BRL,CAD,EUR,GBP,USD`,
    );
  }, []);

  const eurTobrl = (rates) => {
    return {
      BRL: 1,
      AUD: Number(rates.BRL / rates.AUD).toFixed(4),
      CAD: Number(rates.BRL / rates.CAD).toFixed(4),
      EUR: Number(rates.BRL).toFixed(4),
      GBP: Number(rates.BRL / rates.GBP).toFixed(4),
      USD: Number(rates.BRL / rates.USD).toFixed(4),
    };
  };

  const getCurrency = useCallback(async () => {
    const currencyData = localStorage.getItem(storageKeys.currencyData);
    const today = new Date().toISOString().split('T')[0];

    if (currencyData && JSON.parse(currencyData)?.date === today) {
      return JSON.parse(currencyData);
    }

    const ratesData = await fetchRates()
      .then((res) => res.json())
      .then((data) => {
        const currencyConverted = eurTobrl(data.rates);
        localStorage.setItem(
          storageKeys.currencyData,
          `{"date": ${JSON.stringify(data.date)}, "rates": ${JSON.stringify(
            currencyConverted,
          )}}`,
        );
      });

    return ratesData;
  }, []);

  return useMemo(() => {
    return {
      getCurrency,
    };
  }, [getCurrency]);
}

import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import { useMutation } from '@apollo/client';
import { InputFieldWithSelect } from 'components/atoms/InputFieldWithSelect';
import { SelectField } from 'components/atoms/SelectField';
import { TextArea } from 'components/atoms/TextArea';
import { TextField } from 'components/molecules/TextField';
import { useDrawer } from 'contexts/DrawerContext';
import { useCurrencyConverter } from 'hooks/useCurrencyConverter';
import { DefaultForm, GridColumn } from 'styles/miscellany';
import {
  clearCurrencyNumberBrl,
  clearCurrencyBrl,
  toCurrencyBrl,
  toCurrencyBrlCurrency,
} from 'utils/masks';
import { validator } from 'utils/validators/form-validations';

import { CREATE_PAYMENT, UPDATE_PAYMENT } from '../../query';
import { CostControlFormContainer, SelectSpacer } from './style';

interface CostControlFormProps {
  presetData?: any;
  projectIds?: any;
  isEditing?: boolean;
  groupName?: string;
}

export function CostControlForm(props: CostControlFormProps) {
  const { presetData, projectIds, isEditing, groupName } = props;
  const methods = useForm();
  const { getCurrency } = useCurrencyConverter();
  const { closeDrawer } = useDrawer();
  const [currencyRates, setCurrencyRates] = useState({});

  const [updatePayment, updatePaymentRes] = useMutation(UPDATE_PAYMENT);
  const [createPayment, createPaymentRes] = useMutation(CREATE_PAYMENT);

  const fetchCurrency = async () => {
    const currency = await getCurrency();
    setCurrencyRates(currency?.rates);
  };

  useEffect(() => {
    fetchCurrency();
  }, []);

  const updateTotal = (field) => {
    const value = clearCurrencyNumberBrl(methods.getValues(`${field}Value`));
    const quotation = clearCurrencyNumberBrl(
      methods.getValues(`${field}Currency`),
    );

    methods.setValue(
      `${field}Total`,
      toCurrencyBrl(Number((value * quotation) / 100).toFixed(2)),
    );
  };

  const updateConversionValue = (data, field) => {
    if (data.value) {
      const quotation = currencyRates[data.value];
      methods.setValue(
        `${field}Currency`,
        toCurrencyBrlCurrency(Number(quotation).toFixed(4)),
      );
    }
    updateTotal(field);
  };

  useEffect(() => {
    if (updatePaymentRes.data || createPaymentRes.data) {
      toast.success('Cadastro atualizado!');
      closeDrawer();
    }
    if (updatePaymentRes.error || createPaymentRes.error) {
      toast.error('Ops, falha ao atualizar o cadastro.');
    }
  }, [
    updatePaymentRes.data,
    updatePaymentRes.error,
    createPaymentRes.data,
    createPaymentRes.error,
  ]);

  const onSubmit = (data) => {
    // console.log('SUBMIT DATA', data);
    const payload = { ...data };

    Object.entries(data).forEach(([key, value]) => {
      if (key === '_id') {
        payload.id = value;
      }
      if (typeof value === 'string') {
        if (value?.includes('R$')) {
          if (key === 'realizedCurrency') {
            payload[key] = clearCurrencyBrl(value);
          } else {
            payload[key] = clearCurrencyNumberBrl(value);
          }
        }
      }
    });
    // console.log('SUBMIT PAYLOAD', payload);
    if (isEditing) {
      updatePayment({ variables: payload });
    } else {
      createPayment({
        variables: {
          ...payload,
          groupName: groupName || 'Novo Grupo',
          projectId: projectIds._id,
          clientId: projectIds.client._id,
          budgetId: projectIds.budget._id,
        },
      });
    }
  };

  useEffect(() => {
    if (presetData) {
      Object.entries(presetData).forEach(([key, value]) => {
        if (typeof value === 'number') {
          methods.setValue(key, toCurrencyBrl(Number(value).toFixed(2)));
        } else {
          methods.setValue(key, value);
        }
      });
    }
  }, []);

  return (
    <CostControlFormContainer>
      <FormProvider {...methods}>
        <DefaultForm
          onSubmit={methods.handleSubmit(onSubmit)}
          id="cost-control-form"
        >
          <GridColumn length={2} gap={1} fragment>
            <SelectField
              name="status"
              label="Status"
              placeholder="Status*"
              options={[
                { value: 'pendente', label: 'Pendente' },
                { value: 'NF Recebida', label: 'NF Recebida' },
                { value: 'PO Emitida', label: 'PO Emitida' },
                { value: 'vencido', label: 'Vencido' },
                { value: 'Pago', label: 'Pago' },
              ]}
              validation={validator('required')}
            />
            <TextField name="poCode" label="Numero da PO*" readOnly />
          </GridColumn>
          <TextField
            name="itemDescription"
            label="Item*"
            validation={validator('required')}
          />
          <TextField
            name="providerName"
            label="Fornecedor*"
            validation={validator('required')}
          />
          <TextField
            name="providerEmail"
            label="Email do fornecedor*"
            validation={validator('required')}
          />
          <TextArea name="details" label="Detalhes*" />
          <SelectField
            name="category"
            label="Categoria"
            placeholder="Categoria"
            options={[
              { value: 'internalProduction', label: 'Produção Interna' },
              { value: 'presets', label: 'Presets' },
              { value: 'outsource', label: 'Terceiro' },
              { value: 'repass', label: 'Repasse' },
              { value: 'comission', label: 'Comissão' },
            ]}
          />
          <h6>Invoice</h6>
          <GridColumn length={2} gap={1} fragment>
            <SelectSpacer>
              <InputFieldWithSelect
                inputName="budgetedValue"
                label="Valor Orçado"
                options={[
                  { value: 'BRL', label: 'R$' },
                  { value: 'USD', label: 'US$' },
                  { value: 'CAD', label: 'C$' },
                  { value: 'EUR', label: '€' },
                  { value: 'GBP', label: '£' },
                ]}
                defaultValue={{ value: 'BRL', label: 'R$' }}
                menuPlacement="top"
                validation={validator('required')}
                actionTrigger={(data) => {
                  updateConversionValue(data, 'budgeted');
                }}
              />
            </SelectSpacer>
            <SelectSpacer>
              <InputFieldWithSelect
                inputName="realizedValue"
                label="Valor Realizado"
                options={[
                  { value: 'BRL', label: 'R$' },
                  { value: 'USD', label: 'US$' },
                  { value: 'CAD', label: 'C$' },
                  { value: 'EUR', label: '€' },
                  { value: 'GBP', label: '£' },
                ]}
                defaultValue={{ value: 'BRL', label: 'R$' }}
                menuPlacement="top"
                validation={validator('required')}
                actionTrigger={(data) => {
                  updateConversionValue(data, 'realized');
                }}
              />
            </SelectSpacer>
            <TextField
              name="budgetedCurrency"
              label="Cotação Orçada"
              pattern="quota"
              readOnly
            />
            <TextField
              name="realizedCurrency"
              label="Cotação Realizada"
              pattern="quota"
              readOnly
            />
            <TextField
              name="budgetedTotal"
              label="Total Orçado*"
              pattern="money"
              validation={validator('required')}
              readOnly
            />
            <TextField
              name="realizedTotal"
              label="Total Realizado*"
              pattern="money"
              validation={validator('required')}
              readOnly
            />
          </GridColumn>
        </DefaultForm>
      </FormProvider>
    </CostControlFormContainer>
  );
}

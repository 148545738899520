import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Select from 'react-select';

import { ErrorMessage } from '@hookform/error-message';
import { TextField } from 'components/molecules/TextField';

import { ErrorLabel, SelectContainer, Preffix } from './style';

interface OptionProp {
  value: string;
  label: string;
}

interface SelectFieldProps {
  inputName: string;
  label: string;
  placeholder?: string;
  menuPlacement?: string;
  options: OptionProp[];
  validation?: any;
  isSearchable?: boolean;
  isDisabled?: boolean;
  defaultValue?: OptionProp;
  actionTrigger?: (data: any) => void;
}

export function InputFieldWithSelect(props: SelectFieldProps) {
  const {
    inputName,
    label,
    menuPlacement,
    options,
    validation,
    isSearchable,
    isDisabled,
    defaultValue,
    actionTrigger,
  } = props;
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const [currencyPreffix, setCurrencyPreffix] = useState('R$');
  const [isDirty, setIsDirty] = useState(false);

  const registration = !isDisabled && {
    ...register(inputName, validation || undefined),
  };
  const errorMessage = errors[inputName]?.message;

  return (
    <SelectContainer>
      <Preffix isDirty={isDirty}>{currencyPreffix}</Preffix>
      <TextField
        {...registration}
        name={inputName}
        label={label}
        pattern="money"
        onKeyUp={(data) => {
          if (actionTrigger) {
            actionTrigger(data);
          }
        }}
        onInput={() => {
          setIsDirty(true);
        }}
      />
      <Select
        options={options}
        classNamePrefix="select-field"
        menuPlacement={menuPlacement}
        className={errorMessage && 'error'}
        isSearchable={isSearchable}
        isDisabled={isDisabled}
        noOptionsMessage={() => 'Nenhum resultado encontrado'}
        onChange={(data) => {
          setCurrencyPreffix(data.label);
          if (actionTrigger) {
            actionTrigger(data);
          }
        }}
        defaultValue={defaultValue}
      />
      <ErrorLabel>
        <ErrorMessage errors={errors} name={inputName} />
      </ErrorLabel>
    </SelectContainer>
  );
}

InputFieldWithSelect.defaultProps = {
  placeholder: 'selecionar',
  menuPlacement: 'auto',
  validation: null,
  isSearchable: false,
  isDisabled: false,
  defaultValue: null,
  actionTrigger: undefined,
};

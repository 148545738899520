import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useMutation, useLazyQuery } from '@apollo/client';
import { GearIcon } from 'components/atoms/Icons/GearIcon';
import { Switch } from 'components/atoms/Switch';
import { Table } from 'components/organisms/Table';
import { Toolbar } from 'components/organisms/Toolbar';
import { useDrawer } from 'contexts/DrawerContext';
import { useWorkspace } from 'contexts/WorkspaceContext';
import { IconButton } from 'styles/miscellany';
import { capitalize } from 'utils/formatters/capitalize';

import { ClientForm } from './components/ClientForm';
import { TOGGLE_CLIENT, CLIENT_LIST } from './query';

export function Clients() {
  const [searchTerm, setSearchTerm] = useState('');
  const { openDrawer } = useDrawer();
  const [getClients, getClientsRes] = useLazyQuery(CLIENT_LIST);
  const clients = getClientsRes.data?.ClientItems?.items || [];
  const { neuronMode, currentCompanyId } = useWorkspace();
  const [toggleClient, toggleClientRes] = useMutation(TOGGLE_CLIENT);
  const history = useHistory();

  const handleAction = (client?: string) => {
    openDrawer({
      title: client ? 'Editar Cliente' : 'Adicionar Cliente',
      content: <ClientForm client={client} refetch={getClientsRes.refetch} />,
      formId: 'client-form',
      width: 600,
    });
  };

  const handleInfo = (client?: string) => {
    history.push(`/clients/${client}`);
  };

  const handleSwitch = (e, id) => {
    toggleClient({
      variables: {
        id,
        isDisabled: !e,
      },
    });
  };

  useEffect(() => {
    getClients(
      neuronMode ? undefined : { variables: { unit: currentCompanyId } },
    );
  }, [currentCompanyId, getClients, neuronMode]);

  return (
    <>
      <Toolbar
        title="Clientes"
        onSearch={setSearchTerm}
        onAction={handleAction}
      />
      <Table
        pages={getClientsRes.data?.ClientItems?.pages}
        fetchData={getClients}
        fetchVariables={{ unit: currentCompanyId }}
        searchTerm={searchTerm}
        columns={[
          {
            Header: 'Nome',
            accessor: 'name',
            Cell: ({ value }) => capitalize(value),
          },
        ]}
        loading={getClientsRes.loading}
        emptyText="Não há registros para consulta"
        data={clients}
        actions={(rowData) => [
          {
            component: (
              <Switch
                onChange={(e) => handleSwitch(e, rowData._id)}
                disabled={toggleClientRes.loading}
                defaultChecked={!rowData?.is_disabled}
              />
            ),
          },
          {
            component: (
              <IconButton onClick={() => handleInfo(rowData._id)}>
                <GearIcon variant="light" width={20} height={20} />
              </IconButton>
            ),
          },
        ]}
      />
    </>
  );
}

import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { TextField } from 'components/molecules/TextField';
import { DefaultForm } from 'styles/miscellany';
import { validator } from 'utils/validators/form-validations';

interface AccountsChartsFormProps {
  data?: any;
  onSubmit: (data) => void;
}

export function AccountsChartsForm(props: AccountsChartsFormProps) {
  const { onSubmit, data } = props;
  const methods = useForm();

  useEffect(() => {
    Object.entries(data).forEach(([key, val]) => {
      methods.setValue(key, val);
    });
  }, [methods, data]);

  return (
    <FormProvider {...methods}>
      <DefaultForm
        onSubmit={methods.handleSubmit(onSubmit)}
        id="accounts-charts-form"
      >
        <TextField
          name="name"
          label="Nome"
          validation={validator('required')}
        />
        <TextField name="vbsCode" label="Código VBS" maxLength={15} />
      </DefaultForm>
    </FormProvider>
  );
}

AccountsChartsForm.defaultProps = {
  data: false,
};

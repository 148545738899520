/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-no-bind */
// eslint-disable-next-line no-use-before-define
import { useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Pdf from 'react-to-pdf';

import { Button } from 'components/atoms/Button';
import { DownloadIcon } from 'components/atoms/Icons/DownloadIcon';
import { NextIcon } from 'components/atoms/Icons/NextIcon';
import { PreviousIcon } from 'components/atoms/Icons/PreviousIcon';
import { PrinterIcon } from 'components/atoms/Icons/PrinterIcon';
import { UnstyledButton } from 'pages/Companies/components/CompanyBankAccounts/style';
import { toCurrencyBrl } from 'utils/masks';

import {
  ToolbarHolder,
  PrintedBudgetToolbarContainer,
  ButtonsContainer,
} from './style';

const options = {
  unit: 'in',
  format: [19.5, 8.35],
};

interface Props {
  value: any;
  onSave: () => void;
  budgetState: any;
}

export function PrintedBudgetToolbar(props: Props) {
  const { value, onSave, budgetState } = props;
  const methods = useFormContext();
  const [formStateStep, setFormStateStep] = useState(0);

  const historyArray = useRef<any[]>([JSON.parse(JSON.stringify(budgetState))]);

  const updateState = (stateIndex: number) => {
    const newIndex = stateIndex > 0 ? stateIndex : 0;

    if (newIndex < historyArray.current.length) {
      const rootMetadata = historyArray.current[newIndex].metadata;
      methods.setValue(
        'metadata',
        rootMetadata ? JSON.parse(JSON.stringify(rootMetadata)) : {},
      );

      historyArray.current[newIndex]?.budGroups?.forEach((item: any, index) => {
        methods.setValue(`budGroups.${index}`, {
          name: item.name,
          metadata: JSON.parse(JSON.stringify(item.metadata)),
        });
      });
      historyArray.current[newIndex]?.budDeductions?.forEach(
        (item: any, index) => {
          methods.setValue(`budDeductions.${index}`, {
            description: item.description,
            value: toCurrencyBrl(item.value * 100),
          });
        },
      );
      setFormStateStep(newIndex);
      onSave();
    }
  };

  const addState = () => {
    historyArray.current = historyArray.current.slice(0, formStateStep + 1);
    historyArray.current.push(JSON.parse(JSON.stringify(budgetState)));
    setFormStateStep(historyArray.current.length - 1);
  };

  return (
    <ToolbarHolder>
      <PrintedBudgetToolbarContainer>
        <UnstyledButton title="Imprimir" type="button" onClick={window.print}>
          <PrinterIcon />
        </UnstyledButton>
        <Pdf
          targetRef={value}
          filename="code-example.pdf"
          options={options}
          y={-0.5}
          scale={0.415}
        >
          {({ toPdf }) => (
            <UnstyledButton title="Download" type="button" onClick={toPdf}>
              <DownloadIcon />
            </UnstyledButton>
          )}
        </Pdf>
        <UnstyledButton
          title="Desfazer"
          type="button"
          onClick={() => updateState(formStateStep - 1)}
        >
          <PreviousIcon />
        </UnstyledButton>
        <UnstyledButton
          title="Refazer"
          type="button"
          onClick={() => updateState(formStateStep + 1)}
        >
          <NextIcon />
        </UnstyledButton>
      </PrintedBudgetToolbarContainer>
      <ButtonsContainer>
        <Button
          variant="secondary"
          type="button"
          onClick={() => {
            addState();
            onSave();
          }}
        >
          SALVAR
        </Button>
      </ButtonsContainer>
    </ToolbarHolder>
  );
}

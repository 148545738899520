import styled, { css } from 'styled-components';

export const TextAreaCustomContainer = styled.div``;

interface TextAreaCustomStyledProps {
  isRight?: boolean;
  isBold?: boolean;
}

export const TextAreaCustomStyled = styled.textarea<TextAreaCustomStyledProps>`
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  line-height: 100%;
  letter-spacing: 0.15px;
  width: 100%;
  height: 5rem;
  border: none;
  resize: none;

  ${({ isRight }) =>
    isRight &&
    css`
      text-align: right;
    `}

  ${({ isBold }) =>
    isBold &&
    css`
      font-weight: bold;
    `}

  &:disabled {
    color: black;
    background-color: transparent;
  }
`;

import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useLazyQuery } from '@apollo/client';
import { Breadcrumb } from 'components/atoms/Breadcrumb';
import { Tabs } from 'components/organisms/Tabs';

import { BillingControl } from './components/BillingControl';
import { CostControl } from './components/CostControl';
import { ProjectCosts } from './components/ProjectCosts';
import { RecognitionControl } from './components/RecognitionControl';
import { RevenueControl } from './components/RevenueControl';
import { GET_PROJECT_BY_ID } from './query';
import { TabsHolder } from './style';

export function ManageProject() {
  const [getProjectById, getProjectByIdRes] = useLazyQuery(GET_PROJECT_BY_ID, {
    notifyOnNetworkStatusChange: true,
  });
  const project = getProjectByIdRes.data?.ProjectItems?.items[0] || [];
  const params = useParams<any>();

  useEffect(() => {
    getProjectById({ variables: { id: params.id } });
  }, [getProjectById]);

  const tabHeader = [
    'Controle de Custos',
    'Controle de Pagamento',
    'Controle de Faturamento',
    'Controle de Reconhecimento',
  ];

  const tabContent = [
    <CostControl key="CostControl" projectData={project} />,
    <BillingControl key="BillingControl" />,
    <RevenueControl key="RevenueControl" />,
    <RecognitionControl key="RecognitionControl" />,
  ];

  const breadcrumbs = [
    {
      title: 'Projetos',
      link: '/projects',
    },
    {
      title: `${project.area} ${project.campaign}`,
      link: `/projects/${project._id}`,
    },
  ];

  return (
    <div>
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <ProjectCosts
        costSoFar={project.costSoFar}
        budgetCost={project.budgetCost}
        totalRevenue={project.revenue}
      />
      <TabsHolder>
        <Tabs tabHeader={tabHeader} tabContent={tabContent} />
      </TabsHolder>
    </div>
  );
}

/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-array-index-key */
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Link, useHistory, useParams } from 'react-router-dom';

import { useLazyQuery, useMutation } from '@apollo/client';
import { Button } from 'components/atoms/Button';
import { TrashIcon } from 'components/atoms/Icons/TrashIcon';
import { DeleteModal } from 'components/molecules/DeleteModal';
import { TextField } from 'components/molecules/TextField';
import { BudgetGroup } from 'components/organisms/BudgetGroup';
import { useModal } from 'contexts/ModalContext';
import { useWorkspace } from 'contexts/WorkspaceContext';
import { calculateAllBudgetTotals } from 'pages/Budgets/service';
import {
  DefaultForm,
  FlexContainer,
  GridColumn,
  IconButton,
  Line,
} from 'styles/miscellany';
import { clearCurrencyNumberBrl, toCurrencyBrl } from 'utils/masks';

import { FormGroupWithAdding } from '../../../../components/organisms/FormGroupWithAdding';
import { NewBudgetTab } from '../NewBudget/components/NewBudgetTab';
import { NewBudgetToolbar } from '../NewBudget/components/NewBudgetToolbar';
import { GET_BUDGET_BY_ID, UPDATE_BUDGET } from '../NewBudget/query';
import {
  ButtonsContainer,
  FieldContainer,
  FieldsContainer,
} from '../NewBudget/style';
import { Comissions } from './components/Comissions';
import { StructureGroup } from './components/StructureGroup';
import { COMPANY_TAX } from './components/StructureGroup/query';
import { StructureRevenue } from './components/StructureRevenue';
import { StructureContainer } from './style';

export function Structure() {
  const { currentCompanyId } = useWorkspace();

  const [getCompanyTax, getCompanyTaxRes] = useLazyQuery(COMPANY_TAX);
  const [getBudget, getBudgetRes] = useLazyQuery(GET_BUDGET_BY_ID, {
    notifyOnNetworkStatusChange: true,
  });
  const [updateBudget, updateBudgetRes] = useMutation(UPDATE_BUDGET);

  const methods = useForm();
  const history = useHistory();
  const { id } = useParams<any>();

  const companyTax = getCompanyTaxRes.data?.CompanyItems?.items[0].directTax;
  const budgetItem = getBudgetRes?.data?.BudgetItems?.items[0];

  const formValues = methods.getValues();
  const budgetState = methods.watch();
  const budGroups = formValues?.budGroups || [{ name: 'Novo grupo' }];

  const { openModal } = useModal();

  useEffect(() => {
    if (id) getBudget({ variables: { id } });
  }, [id, getBudget]);

  useEffect(() => {
    getCompanyTax({ variables: { companyId: currentCompanyId } });
  }, [getCompanyTax, currentCompanyId]);

  useEffect(() => {
    if (budgetItem) {
      budgetItem?.budGroups?.forEach((item: any, index) => {
        methods.setValue(`budGroups.${index}`, {
          name: item.name,
          metadata: { ...JSON.parse(item.metadata) },
        });
      });
      budgetItem?.budComissions?.forEach((item: any, index) => {
        methods.setValue(`budComissions.${index}`, {
          item: item.item,
          percentage: item.percentage,
          responsible: item.responsible,
          type: item.type,
        });
      });
      budgetItem?.budDeductions?.forEach((item: any, index) => {
        methods.setValue(`budDeductions.${index}`, {
          description: item.description,
          value: toCurrencyBrl(item.value * 100),
        });
      });
    }
  }, [budgetItem]);

  useEffect(() => {
    if (updateBudgetRes.data) {
      toast.success('Cadastro atualizado!');
    }
    if (updateBudgetRes.error) {
      toast.error('Ops, falha ao atualizar o cadastro.');
    }
  }, [updateBudgetRes.data, updateBudgetRes.error]);

  const saveData = (data) => {
    // console.log('SUBMIT DATA', data);
    const payload = {
      ...data,
      id,
      clientId: budgetItem.client?._id,
      unit: currentCompanyId,
    };

    payload.budGroups?.forEach((group, index) => {
      if (group.metadata) {
        group.metadata = JSON.stringify(group.metadata);
        group.name = budGroups[index].name || 'Novo Grupo';
      }
    });
    payload.budComissions = payload.budComissions?.map((group) => {
      return {
        item: group.item,
        percentage: clearCurrencyNumberBrl(group.percentage),
        responsible: group.responsible,
        type: group.type,
      };
    });
    payload.budDeductions = payload.budDeductions?.map((group) => {
      return {
        description: group.description,
        value: clearCurrencyNumberBrl(group.value),
      };
    });
    // console.log('SUBMIT PAYLOAD', payload);
    updateBudget({ variables: payload });
  };

  const onSubmit = (data) => {
    saveData(data);

    window.open(`/budgets/${id}/printedBudget`, '_blank');
    history.push('/budgets');
  };

  const addGroup = () => {
    budGroups.push({ name: `Novo grupo ${budGroups.length}` });
    methods.reset({ budGroups: [] }, { keepValues: true });
  };

  const deleteGroup = (index) => {
    openModal({
      content: (
        <DeleteModal
          onAccept={() => {
            budGroups.splice(index, 1);
            methods.reset({ budGroups: [] }, { keepValues: true });
          }}
        />
      ),
    });
  };

  const changeGroupName = (index, name) => {
    methods.setValue(`budGroups.${index}.name`, name);
  };

  const { totalBilling, netBilling, outsourceTotal } =
    calculateAllBudgetTotals(budgetState);

  return (
    <StructureContainer>
      <NewBudgetToolbar title={budgetItem?.campaign} id={id} />
      <NewBudgetTab id={1} />
      <Line style={{ margin: '1.5rem 0 2rem 0' }} />
      <FormProvider {...methods}>
        <DefaultForm onSubmit={methods.handleSubmit(onSubmit)}>
          <FieldsContainer>
            {budGroups.map((group, index) => (
              <BudgetGroup
                key={index}
                groupState={
                  budgetState.budGroups ? budgetState.budGroups[index] : null
                }
                title={group.name}
                titleName={`budGroups.${index}.name`}
                onChange={(name) => changeGroupName(index, name)}
                defaultOpen={index === 0}
                deleteGroup={() => deleteGroup(index)}
              >
                <StructureGroup
                  groupName={`budGroups.${index}.metadata`}
                  companyTax={companyTax}
                />
              </BudgetGroup>
            ))}

            <GridColumn length={1} gap={1}>
              <Button
                variant="primary"
                style={{ margin: '2rem 0 0 1.875rem' }}
                type="button"
                onClick={addGroup}
              >
                CRIAR NOVO GRUPO
              </Button>
            </GridColumn>

            <Line style={{ margin: '2rem 0 1rem' }} />

            <Comissions
              outsourceTotal={outsourceTotal}
              projectTotal={totalBilling}
              revenueTotal={netBilling}
              comissions={budgetItem?.budComissions}
            />

            <FormGroupWithAdding
              title="Deduções"
              groupName="budDeductions"
              renderRow={({ index, onDelete }) => (
                <FieldContainer>
                  <FlexContainer value={6}>
                    <TextField
                      name={`budDeductions.${index}.description`}
                      label="Descrição"
                    />
                  </FlexContainer>
                  <FlexContainer>
                    <TextField
                      name={`budDeductions.${index}.value`}
                      label="Total"
                      pattern="moneyPrefix"
                    />
                  </FlexContainer>
                  <IconButton
                    type="button"
                    tabIndex={0}
                    onClick={() => onDelete(index)}
                  >
                    <TrashIcon variant="light" width={20} height={20} />
                  </IconButton>
                </FieldContainer>
              )}
            />

            <Line style={{ margin: '2rem 0 1rem' }} />

            <StructureRevenue companyTax={companyTax} />
          </FieldsContainer>

          <ButtonsContainer>
            <div>
              <Link to="/budgets">
                <Button variant="secondary">CANCELAR</Button>
              </Link>
            </div>
            <div>
              <Link to={`/budgets/${id}/information`}>
                <Button variant="secondary">VOLTAR</Button>
              </Link>
              <Button
                variant="secondary"
                style={{ marginLeft: '1rem' }}
                onClick={() => {
                  saveData(budgetState);
                }}
                type="button"
              >
                SALVAR
              </Button>
              <Button variant="tertiary" style={{ marginLeft: '1rem' }}>
                AVANÇAR
              </Button>
            </div>
          </ButtonsContainer>
        </DefaultForm>
      </FormProvider>
    </StructureContainer>
  );
}

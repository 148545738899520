import styled from 'styled-components';

export const CompanyGridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 6fr 6fr 8fr 3fr 1fr;
  gap: 1.5rem;

  > div > div {
    margin-top: 10px;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const UnstyledButton = styled.button`
  background-color: transparent;
  border: none;
`;

export const RadioButtonContainer = styled(FlexContainer)`
  cursor: pointer;
`;

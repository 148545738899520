import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { useLazyQuery } from '@apollo/client';
import { TrashIcon } from 'components/atoms/Icons/TrashIcon';
import { InputFieldWithSelect } from 'components/atoms/InputFieldWithSelect';
import { SelectField } from 'components/atoms/SelectField';
import { TextField } from 'components/molecules/TextField';
import { useWorkspace } from 'contexts/WorkspaceContext';
import { useCurrencyConverter } from 'hooks/useCurrencyConverter';
import { FieldContainer } from 'pages/Budgets/components/NewBudget/style';
import { FlexContainer, IconButton } from 'styles/miscellany';
import {
  clearCurrencyStr,
  toCurrencyBrl,
  clearCurrencyNumberBrl,
} from 'utils/masks';
import { validator } from 'utils/validators/form-validations';

import { FormGroupWithAdding } from '../../../../../../../components/organisms/FormGroupWithAdding';
import { GET_INTERNAL_PRODUCTIONS_LIST } from '../query';

interface Props {
  groupName: string;
}
export function Invoice(props: Props) {
  const { groupName } = props;
  const { getCurrency } = useCurrencyConverter();
  const { currentCompanyId } = useWorkspace();
  const [currencyRates, setCurrencyRates] = useState({});

  const [getInvoice] = useLazyQuery(GET_INTERNAL_PRODUCTIONS_LIST, {
    notifyOnNetworkStatusChange: true,
  });
  const { setValue, getValues } = useFormContext();

  const updateTotal = (index) => {
    const value = clearCurrencyNumberBrl(
      getValues(`${groupName}.invoice.${index}.value`),
    );
    const hedge = clearCurrencyStr(
      getValues(`${groupName}.invoice.${index}.hedgePercentage`),
    );
    const quotation = getValues(
      `${groupName}.invoice.${index}.currencyQuotation`,
    );

    const totalValue = Number(
      (value + (hedge / 100) * value) / quotation,
    ).toFixed(2);

    setValue(
      `${groupName}.invoice.${index}.currencyConversion`,
      toCurrencyBrl(Number(value / quotation).toFixed(2)),
    );

    setValue(`${groupName}.invoice.${index}.total`, toCurrencyBrl(totalValue));
  };

  const updateConversionValue = (index, selectedCurrency?) => {
    if (selectedCurrency.value) {
      const quotation = +currencyRates[selectedCurrency.value];

      setValue(`${groupName}.invoice.${index}.currencyQuotation`, quotation);
    }

    updateTotal(index);
  };

  useEffect(() => {
    getInvoice({
      variables: { type: 'invoice', unit: currentCompanyId },
    });
  }, [getInvoice, currentCompanyId]);

  const fetchCurrency = async () => {
    const currency = await getCurrency();
    setCurrencyRates(currency?.rates);
  };

  useEffect(() => {
    fetchCurrency();
  }, []);

  return (
    <div>
      <FormGroupWithAdding
        title="Invoice"
        groupName={`${groupName}.invoice`}
        renderRow={({ index, onDelete }) => (
          <FieldContainer>
            <FlexContainer value={3.5} style={{ marginTop: '4px' }}>
              <InputFieldWithSelect
                inputName={`${groupName}.invoice.${index}.value`}
                label="Valor"
                options={[
                  { value: 'BRL', label: 'R$' },
                  { value: 'USD', label: 'US$' },
                  { value: 'CAD', label: 'C$' },
                  { value: 'EUR', label: '€' },
                  { value: 'GBP', label: '£' },
                ]}
                defaultValue={{ value: 'BRL', label: 'R$' }}
                menuPlacement="top"
                validation={validator('required')}
                actionTrigger={(data) => {
                  updateConversionValue(index, data);
                }}
              />
            </FlexContainer>
            <FlexContainer value={2} style={{ marginTop: '5px' }}>
              <SelectField
                name={`${groupName}.invoice.${index}.type`}
                label="Tipo"
                placeholder="Tipo"
                options={[{ value: 'Invoice', label: 'Invoice' }]}
                validation={validator('required')}
              />
            </FlexContainer>
            <FlexContainer value={1.5} style={{ marginTop: '5px' }}>
              <SelectField
                name={`${groupName}.invoice.${index}.isParadise`}
                label="Paraíso Fiscal"
                placeholder="Paraíso Fiscal"
                options={[
                  { value: 'Sim', label: 'Sim' },
                  { value: 'Não', label: 'Não' },
                ]}
                validation={validator('required')}
              />
            </FlexContainer>
            <FlexContainer value={1.5}>
              <TextField
                name={`${groupName}.invoice.${index}.hedgePercentage`}
                label="Hedge"
                suffix="%"
                defaultValue={1}
                onKeyUp={() => {
                  updateTotal(index);
                }}
              />
            </FlexContainer>
            <FlexContainer value={1.2}>
              <TextField
                name={`${groupName}.invoice.${index}.currencyQuotation`}
                label="Cotação"
                pattern="quotaMask"
                defaultValue={1}
                readOnly
              />
            </FlexContainer>
            <FlexContainer value={2}>
              <TextField
                name={`${groupName}.invoice.${index}.currencyConversion`}
                label="R$ Conversão"
                pattern="moneyPrefix"
                defaultValue={0}
                readOnly
              />
            </FlexContainer>
            <FlexContainer value={2}>
              <TextField
                name={`${groupName}.invoice.${index}.total`}
                label="R$ Custo total"
                defaultValue={0}
                readOnly
              />
            </FlexContainer>
            <IconButton
              type="button"
              tabIndex={0}
              onClick={() => onDelete(index)}
            >
              <TrashIcon variant="light" width={20} height={20} />
            </IconButton>
          </FieldContainer>
        )}
      />
    </div>
  );
}

import { gql } from '@apollo/client';

export const APPROVE_BUDGET = gql`
  mutation (
    $budId: String!
    $serviceType: ProjectServiceType
    $totalRevenue: Float!
    $paymentDate: String
    $approvedBy: String!
    $billingContactName: String!
    $billingContactEmail: String!
    $billingContactPhone: String!
    $notes: String!
    $start_at: String
    $finish_at: String
  ) {
    approveBudget(
      budId: $budId
      serviceType: $serviceType
      totalRevenue: $totalRevenue
      paymentDate: $paymentDate
      approvedBy: $approvedBy
      billingContactName: $billingContactName
      billingContactEmail: $billingContactEmail
      billingContactPhone: $billingContactPhone
      notes: $notes
      start_at: $start_at
      finish_at: $finish_at
    ) {
      _id
    }
  }
`;

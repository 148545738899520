import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { useLazyQuery, useMutation } from '@apollo/client';
import { GearIcon } from 'components/atoms/Icons/GearIcon';
import { TrashIcon } from 'components/atoms/Icons/TrashIcon';
import { DeleteModal } from 'components/molecules/DeleteModal';
import { Table } from 'components/organisms/Table';
import { Toolbar } from 'components/organisms/Toolbar';
import { useDrawer } from 'contexts/DrawerContext';
import { useModal } from 'contexts/ModalContext';
import { useWorkspace } from 'contexts/WorkspaceContext';
import { IconButton } from 'styles/miscellany';
import { capitalize } from 'utils/formatters/capitalize';
import { clearCurrencyStrBrl, toCurrencyBrl } from 'utils/masks';

import { InternalProductionForm } from './components/InternalProductionForm';
import {
  DELETE_INTERNAL_PRODUCTION,
  GET_INTERNAL_PRODUCTIONS_LIST,
  UPDATE_INTERNAL_PRODUCTION,
} from './query';

export function InternalProductions() {
  const [searchTerm, setSearchTerm] = useState('');
  const { openModal } = useModal();
  const { openDrawer } = useDrawer();
  const { currentCompanyId } = useWorkspace();
  const [, updateInternalProductionRes] = useMutation(
    UPDATE_INTERNAL_PRODUCTION,
  );
  const [deleteInternalProduction] = useMutation(DELETE_INTERNAL_PRODUCTION);
  const [getInternalProductions, getInternalProductionsRes] = useLazyQuery(
    GET_INTERNAL_PRODUCTIONS_LIST,
    {
      notifyOnNetworkStatusChange: true,
    },
  );
  const items =
    getInternalProductionsRes.data?.ConfigurationItems?.items?.map((item) => {
      return { ...JSON.parse(item.metadata), _id: item._id };
    }) || [];

  const newItems = items.map((i) => {
    return {
      ...i,
      costValue: clearCurrencyStrBrl(i.costValue),
      saleValue: clearCurrencyStrBrl(i.saleValue),
    };
  });

  useEffect(() => {
    getInternalProductions({
      variables: { type: 'production', unit: currentCompanyId },
    });
  }, [getInternalProductions, currentCompanyId]);

  const handleAction = (id?) => {
    openDrawer({
      title: `${id ? 'Editar' : 'Adicionar'} Produções internas`,
      content: (
        <InternalProductionForm
          id={id}
          refetch={getInternalProductionsRes.refetch}
        />
      ),
      formId: 'internalproduction-form',
      width: 600,
    });
  };

  const handleDelete = (id) => {
    openModal({
      content: (
        <DeleteModal
          onAccept={() => {
            deleteInternalProduction({ variables: { id } }).then(() => {
              if (getInternalProductionsRes?.refetch)
                getInternalProductionsRes.refetch({
                  variables: getInternalProductionsRes.variables,
                });
            });
          }}
        />
      ),
    });
  };

  useEffect(() => {
    if (updateInternalProductionRes.error) {
      toast.error('Erro ao atualizar produções internas!');
    }
  }, [updateInternalProductionRes.error]);

  return (
    <>
      <Toolbar
        title="Produções internas"
        onSearch={setSearchTerm}
        onAction={handleAction}
      />

      <Table
        pages={getInternalProductionsRes.data?.ConfigurationItems?.pages}
        fetchData={getInternalProductions}
        fetchVariables={{ type: 'production', unit: currentCompanyId }}
        searchTerm={searchTerm}
        manualSort
        columns={[
          {
            Header: 'Nome',
            accessor: 'name',
            Cell: ({ value }) => capitalize(value),
          },
          { Header: 'Categoria', accessor: 'category' },
          {
            Header: 'Valor custo',
            accessor: 'costValue',
            Cell: ({ value }) => toCurrencyBrl(value),
          },
          {
            Header: 'Lucratividade',
            accessor: 'profitability',
            Cell: ({ value }) => `${value} %`,
          },
          {
            Header: 'Valor de Venda',
            accessor: 'saleValue',
            Cell: ({ value }) => toCurrencyBrl(value),
          },
        ]}
        loading={getInternalProductionsRes.loading}
        data={newItems}
        emptyText="Não há registros para consulta"
        actions={(rowData) => [
          {
            component: (
              <IconButton
                role="button"
                tabIndex={0}
                onClick={() => handleDelete(rowData._id)}
              >
                <TrashIcon variant="light" width={24} height={24} />
              </IconButton>
            ),
          },
          {
            component: (
              <IconButton onClick={() => handleAction(rowData._id)}>
                <GearIcon variant="light" width={20} height={20} />
              </IconButton>
            ),
          },
        ]}
      />
    </>
  );
}

import styled from 'styled-components';
import { colors } from 'styles/colors';

export const RecognitionControlContainer = styled.div`
  padding: 1.5rem 0;
`;

export const RecognitionControlHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1.5rem;
`;

export const PercentHolder = styled.div`
  display: flex;
  background: ${colors.backgroundLight};
  border-radius: 8px;
  padding: 0.5rem 1rem;
  align-items: center;
`;

export const PercentTitle = styled.span`
  font-size: 0.75rem;
  color: ${colors.textOnBackground.medium};
`;

export const PercentValue = styled.h5`
  color: ${colors.primary};
  margin-left: 0.5rem;
`;

export const SearchAction = styled.div`
  display: grid;
  grid-template-columns: 20rem 5.5rem;
  grid-column-gap: 1rem;
`;

export const RecognitionControlContent = styled.div`
  background: ${colors.backgroundLight};
  border-radius: 4px;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`;

export const RecognitionFields = styled.div`
  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: 4rem 1fr 1.5rem;
  grid-column-gap: 0.5rem;
  width: 100%;
`;

export const FormHolder = styled.div`
  display: grid;
  grid-template-columns: 2.5fr 1.75fr 2fr 2.5fr 1.75fr 1.5fr;
  grid-column-gap: 1rem;
`;

export const CalendarIconHolder = styled.div`
  position: absolute;
  right: 10px;
  top: 20px;
`;

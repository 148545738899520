import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';

export const BudgetGroupContainer = styled.div``;

export const BudgetGroupHead = styled.div`
  display: grid;
  grid-template-columns: 100fr 20fr 1fr;
  padding: 1.5rem;
  border-bottom: 1px solid ${colors.outline};
`;

export const BudgetCosts = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const TitleHolder = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

interface BudgetTitleProps {
  titleError: boolean;
}

export const BudgetTitle = styled.input<BudgetTitleProps>`
  ${({ titleError }) => css`
    background-color: transparent;
    border: 0;
    padding: 0;
    color: ${colors.textOnBackground.special};
    font-size: 20px;
    letter-spacing: -0.2px;
    margin-right: 0.5rem;
    border-bottom: ${titleError
      ? `1px solid ${colors.textOnBackground.error}`
      : `1px solid transparent`};

    ${!titleError &&
    css`
      &:hover {
        border-bottom: 1px solid ${colors.primary};
      }

      &:focus {
        border-bottom: 1px solid ${colors.primary};
      }
    `}
  `}
`;

export const TotalBudget = styled.span`
  font-size: 0.85rem;
  color: ${colors.textOnBackground.medium};
`;

export const TotalRealizedBudget = styled.span`
  font-size: 0.85rem;
  color: ${colors.primaryDark};
`;

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { useFormContext, useWatch } from 'react-hook-form';

import { ErrorMessage } from '@hookform/error-message';
import { SearchField } from 'components/molecules/SearchField';
import { TextField } from 'components/molecules/TextField';
import { AnimatePresence } from 'framer-motion';

import { CheckBoxIcon } from '../Icons/CheckboxIcon';
import { ErrorLabel } from '../SelectField/style';
import * as S from './style';

interface SelectFieldProps {
  name: string;
  label: string;
  placeholder?: string;
  validation?: any;
  isMulti?: boolean;
  loadOptions: (input: string) => Promise<string[]>;
}

export function AutocompleteSelect(props: SelectFieldProps) {
  const { name, label, placeholder, validation, isMulti, loadOptions } = props;
  const {
    setValue,
    register,
    setFocus,
    formState: { errors },
  } = useFormContext();

  const [isOpen, setIsOpen] = useState(false);
  const [resultList, setResultList] = useState<string[]>([]);

  const containerRef = useDetectClickOutside({
    onTriggered: () => setIsOpen(false),
  });

  const searchFieldName = `search-${name}`;
  const searchInput = useWatch({ name: searchFieldName });

  const value =
    useWatch({
      name,
    }) || (isMulti ? [] : '');

  useEffect(() => {
    register(name, validation || undefined);
  }, []);

  useEffect(() => {
    if (searchInput?.length > 1) {
      loadOptions(searchInput).then((resp) => setResultList(resp));
    } else {
      setResultList([]);
    }
  }, [searchInput]);

  useEffect(() => {
    if (isOpen) {
      requestAnimationFrame(() => setFocus(searchFieldName));
    }
  }, [isOpen]);

  const replaceValue = (newValue) => {
    setValue(name, newValue);
  };

  const handleItemSelected = (selected: string) => {
    if (isMulti) {
      const tmpValues = [...value];
      const index = tmpValues.indexOf(selected);
      if (index >= 0) {
        tmpValues.splice(index, 1);
      } else {
        tmpValues.push(selected);
      }
      replaceValue(tmpValues);
    } else {
      replaceValue(selected);
      setIsOpen(false);
    }
  };

  const handleLabelClicked = () => {
    setIsOpen(!isOpen);
  };

  return (
    <S.SelectContainer ref={containerRef}>
      <S.LabelContainer onClick={handleLabelClicked}>
        <TextField
          name={`aux-${name}`}
          label={`${label}`}
          value={isMulti ? value?.join(', ') || '' : value}
        />
        <S.ArrowDown />
      </S.LabelContainer>
      <AnimatePresence>
        {isOpen && (
          <S.DropdownContainer>
            <SearchField
              name={searchFieldName}
              label={placeholder}
              isSubmit={false}
              iconPosition="right"
            />
            <S.OptionList>
              {resultList.map((item) => (
                <S.OptionItem
                  key={item}
                  onClick={() => handleItemSelected(item)}
                >
                  {isMulti && (
                    <CheckBoxIcon
                      width={18}
                      height={18}
                      checked={value.indexOf(item) >= 0}
                    />
                  )}
                  <S.OptionText>{item}</S.OptionText>
                </S.OptionItem>
              ))}
            </S.OptionList>
          </S.DropdownContainer>
        )}
      </AnimatePresence>
      <ErrorLabel>
        <ErrorMessage errors={errors} name={name} />
      </ErrorLabel>
    </S.SelectContainer>
  );
}

AutocompleteSelect.defaultProps = {
  placeholder: 'Pesquisar',
  validation: null,
  isMulti: false,
};

/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import { useLazyQuery, useMutation } from '@apollo/client';
import { SelectField } from 'components/atoms/SelectField';
import { Spinner } from 'components/atoms/Spinner';
import { TextField } from 'components/molecules/TextField';
import { NotificationsForm } from 'components/organisms/NotificationsForm';
import { useDrawer } from 'contexts/DrawerContext';
import { useWorkspace } from 'contexts/WorkspaceContext';
import {
  ADD_NOTIFICATION,
  GET_NOTIFICATION_BY_EMAIL,
  NOTIFICATION_LIST,
  UPDATE_NOTIFICATION,
} from 'pages/Notifications/query';
import { CREATE_USER, GET_USER_BY_ID, UPDATE_USER } from 'pages/Users/query';
import { DefaultForm } from 'styles/miscellany';
import { validator } from 'utils/validators/form-validations';

interface UserFormProps {
  id?: string | boolean;
  refetch: any;
}

export function UserForm(props: UserFormProps) {
  const { id, refetch } = props;
  const isEditing = !!id;
  const methods = useForm();
  const [getUserById, getUserByIdRes] = useLazyQuery(GET_USER_BY_ID);
  const userItem = getUserByIdRes?.data?.UserItems?.items[0];
  const [updateUser, updateUserRes] = useMutation(UPDATE_USER);
  const [createUser, createUserRes] = useMutation(CREATE_USER);
  const { closeDrawer } = useDrawer();
  const { neuronMode, currentCompanyId } = useWorkspace();
  const [updateNotification] = useMutation(UPDATE_NOTIFICATION);
  const [getNotificationByEmail, getNotificationByEmailRes] = useLazyQuery(
    GET_NOTIFICATION_BY_EMAIL,
  );
  const [getNotifications, getNotificationsRes] =
    useLazyQuery(NOTIFICATION_LIST);
  const notifications =
    getNotificationsRes.data?.NotificationItems?.items || [];

  useEffect(() => {
    getNotifications(
      neuronMode ? undefined : { variables: { unit: currentCompanyId } },
    );
  }, [currentCompanyId, getNotifications, neuronMode]);

  const tmpNotifications = notifications.map((i) => {
    return i.email;
  });

  const notificationId =
    getNotificationByEmailRes?.data?.NotificationItems?.items[0]?._id;

  const notificationTags =
    getNotificationByEmailRes?.data?.NotificationItems?.items[0]?.scope;

  const [createNotification] = useMutation(ADD_NOTIFICATION);

  useEffect(() => {
    if (!userItem && isEditing) getUserById({ variables: { id } });

    if (userItem && isEditing) {
      Object.entries(userItem).forEach(([key, value]) => {
        if (key !== '__typename') {
          methods.setValue(key, value, {
            shouldValidate: true,
            shouldDirty: true,
          });
        }
      });
    }
  }, [userItem, methods, isEditing, getUserById, id]);

  useEffect(() => {
    methods.reset({ tags: '' }, { keepValues: true });
    methods.setValue('tags', notificationTags);
  }, [methods, notificationTags]);

  useEffect(() => {
    if (userItem?.email)
      getNotificationByEmail({
        variables: {
          search_term: userItem.email,
        },
      });
  }, [userItem]);

  const onSubmit = (dataForm) => {
    if (!dataForm?.tags) {
      toast.error('Selecione no mínimo uma permissão');

      return;
    }

    if (isEditing) {
      updateUser({
        variables: {
          id,
          name: dataForm.name,
          nickname: dataForm.nickname,
          email: dataForm.email,
          area: dataForm.area,
          permission: dataForm.permission,
          scope: dataForm.tags,
        },
      });
      let registeredEmail = false;
      tmpNotifications.map((i) => {
        if (i === dataForm.email) {
          registeredEmail = true;
        }
      });
      if (registeredEmail === false) {
        createNotification({
          variables: {
            email: dataForm.email,
            scope: dataForm.tags,
            unit: currentCompanyId,
          },
        });
      }

      if (dataForm?.tags && notificationId) {
        updateNotification({
          variables: {
            id: notificationId,
            email: dataForm.email,
            scope: dataForm.tags,
          },
        });
      }
    } else {
      createUser({
        variables: {
          name: dataForm.name,
          nickname: dataForm.nickname,
          email: dataForm.email,
          area: dataForm.area,
          permission: dataForm.permission,
          scope: dataForm.tags,
        },
      });
      if (dataForm?.tags) {
        createNotification({
          variables: {
            email: dataForm.email,
            scope: dataForm.tags,
            unit: currentCompanyId,
          },
        });
      }
    }
  };

  useEffect(() => {
    if (createUserRes.data) {
      toast.success('Cadastro efetuado!');

      refetch();

      closeDrawer();
    }

    if (createUserRes.error) {
      toast.error('Ops, falha ao realizar o cadastro.');
    }
  }, [createUserRes.data, createUserRes.error, refetch]);
  // FIXME: Resolve re-render caused by closeDrawer in dependencies

  useEffect(() => {
    if (updateUserRes.data) {
      toast.success('Cadastro atualizado!');

      refetch();

      closeDrawer();
    }

    if (updateUserRes.error) {
      toast.error('Ops, falha ao atualizar o cadastro.');
    }
  }, [updateUserRes.data, updateUserRes.error, refetch]);
  // FIXME: Resolve re-render caused by closeDrawer in dependencies

  if (getUserByIdRes.loading || createUserRes.loading || updateUserRes.loading)
    return <Spinner />;

  return (
    <FormProvider {...methods}>
      <DefaultForm onSubmit={methods.handleSubmit(onSubmit)} id="user-form">
        <TextField name="name" label="Nome" validation={validator('name')} />
        <TextField
          name="nickname"
          label="Apelido"
          validation={validator('required')}
        />
        <TextField name="email" label="Email" validation={validator('email')} />
        <SelectField
          name="area"
          label="Área"
          placeholder="Área"
          options={[
            { value: 'advertising-agency', label: 'Agência de Publicidade' },
            { value: 'activation', label: 'Ativação' },
            { value: 'consultancy', label: 'Consultoria' },
            { value: 'holding', label: 'Holding' },
            {
              value: 'audiovisual-producer',
              label: 'Produtora de AudioVisual',
            },
            { value: 'tecnology-producer', label: 'Produtora de Tecnologia' },
            { value: 'vehicle', label: 'Veículo' },
            { value: 'others', label: 'Outros' },
          ]}
          validation={validator('required')}
        />
        <SelectField
          name="permission"
          label="Permissão"
          placeholder="Permissão"
          options={[
            { value: 'admin', label: 'Admin' },
            { value: 'lead', label: 'Lead' },
            { value: 'team', label: 'Team' },
            { value: 'visitor', label: 'Visitor' },
          ]}
          validation={validator('required')}
        />
      </DefaultForm>
      <NotificationsForm />
    </FormProvider>
  );
}

UserForm.defaultProps = {
  id: false,
};

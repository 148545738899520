/* eslint-disable react/no-array-index-key */
import { Fragment } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { Plus } from 'components/atoms/Icons/Plus';
import { DeleteModal } from 'components/molecules/DeleteModal';
import { useModal } from 'contexts/ModalContext';
import { colors } from 'styles/colors';

import * as S from './style';

type RowDataProps = {
  index: number;
  onDelete: (index: number) => void;
};

interface GroupInnerListProps {
  title: string;
  groupName: string;
  renderRow: (rowData: RowDataProps) => any;
  defaultItem?: any;
  withoutAdding?: boolean;
}

export function FormGroupWithAdding(props: GroupInnerListProps) {
  const { title, groupName, renderRow, defaultItem, withoutAdding } = props;

  const { setValue } = useFormContext();

  const { openModal } = useModal();

  const fields =
    useWatch({
      name: groupName,
    }) || (defaultItem ? [defaultItem] : []);

  const replaceItems = (newItems) => {
    setValue(groupName, newItems, { shouldDirty: true });
  };

  const addItem = () => {
    const tmpFields = [...fields];
    tmpFields.push({});
    replaceItems(tmpFields);
  };

  const handleDelete = (index) => {
    openModal({
      content: (
        <DeleteModal
          onAccept={() => {
            const tmpFields = [...fields];
            tmpFields.splice(index, 1);
            replaceItems(tmpFields);
          }}
        />
      ),
    });
  };

  return (
    <>
      {!withoutAdding && (
        <S.TitleContainer>
          <S.Title dangerouslySetInnerHTML={{ __html: title }} />
          <S.AddButton type="button" onClick={addItem}>
            <Plus color={colors.primary} />
            ADD
          </S.AddButton>
        </S.TitleContainer>
      )}

      {fields.map((field, index) => (
        <Fragment key={index}>
          {renderRow({ index, onDelete: handleDelete })}
        </Fragment>
      ))}
    </>
  );
}

FormGroupWithAdding.defaultProps = {
  withoutAdding: false,
};

import { gql } from '@apollo/client';

export const GET_BUDGET_LIST = gql`
  query (
    $limit: Int
    $page: Int
    $search_term: String
    $sorting: [SorterInput]
    $unit: String
    $status: String
  ) {
    BudgetItems(
      limit: $limit
      page: $page
      search_term: $search_term
      unit: $unit
      sorting: $sorting
      status: $status
      is_approved: false
    ) {
      items {
        _id
        area
        campaign
        task
        revenue
        paymentMethod
        unit
        expectation
        status
        client {
          _id
          name
        }
        budGroups {
          name
          metadata
        }
        budComissions {
          item
          type
          percentage
          responsible
        }
        budDeductions {
          description
          value
        }
      }
    }
  }
`;

export const GET_CLIENT = gql`
  query ($id: String!) {
    ClientItems(id: $id) {
      items {
        _id
        name
      }
      pages
    }
  }
`;

export const DELETE_BUDGET = gql`
  mutation deleteBudget($id: String!) {
    deleteBudget(id: $id)
  }
`;

export const SEND_FOR_APPROVAL = gql`
  mutation sendBudgetForApproval($id: String!) {
    sendBudgetForApproval(id: $id, status: "waiting_approval")
  }
`;

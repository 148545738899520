import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import { Dashboard } from 'components/templates/Dashboard';
import { SidebarProvider } from 'contexts/SidebarContext';
import { useWorkspace } from 'contexts/WorkspaceContext';
import { AccountsCharts } from 'pages/AccountsCharts';
import { Budgets } from 'pages/Budgets';
import { NewBudget } from 'pages/Budgets/components/NewBudget';
import { Structure } from 'pages/Budgets/components/Structure';
import { Clients } from 'pages/Clients';
import { Collaborators } from 'pages/Collaborators';
import { Commissions } from 'pages/Commissions';
import { Companies } from 'pages/Companies';
import { InternalProductions } from 'pages/InternalProduction';
import { Login } from 'pages/Login';
import { ManageClient } from 'pages/ManageClient';
import { ManageProject } from 'pages/ManageProject';
import { Notifications } from 'pages/Notifications';
import { Payments } from 'pages/Payments';
import { Presets } from 'pages/Presets';
import { PrintedBudget } from 'pages/PrintedBudget';
import { Projects } from 'pages/Projects';
import { Terms } from 'pages/Terms';
import { ThirdParties } from 'pages/ThirdParties';
import { Users } from 'pages/Users';
import { storageKeys } from 'services/storage-keys';

function ProtectedRoute(props: any) {
  const { neuronModeOnly, companyModeOnly, ...rest } = props;
  const { neuronMode } = useWorkspace();
  const isAuthenticated = !!localStorage.getItem(storageKeys.token);
  if (neuronModeOnly && !neuronMode) return <Redirect to="/collaborators" />;
  if (companyModeOnly && neuronMode) return <Redirect to="/" />;
  return isAuthenticated ? (
    <SidebarProvider>
      <Dashboard>
        <Route {...rest} />
      </Dashboard>
    </SidebarProvider>
  ) : (
    <Redirect to="/login" />
  );
}

function PrivateRoute(props: any) {
  const { neuronModeOnly, companyModeOnly, ...rest } = props;
  const { neuronMode } = useWorkspace();
  const isAuthenticated = !!localStorage.getItem(storageKeys.token);
  if (neuronModeOnly && !neuronMode) return <Redirect to="/collaborators" />;
  if (companyModeOnly && neuronMode) return <Redirect to="/" />;
  return isAuthenticated ? <Route {...rest} /> : <Redirect to="/login" />;
}

function LoginRoute({ ...rest }) {
  const isAuthenticated = !!localStorage.getItem(storageKeys.token);

  return isAuthenticated ? <Redirect to="/" /> : <Route {...rest} />;
}

function Routes() {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Switch>
        {/* unauthenticated routes */}
        <LoginRoute exact path="/login" component={Login} />
        <Route exact path="/terms" component={Terms} />
        {/* common routes */}
        <ProtectedRoute exact path="/notifications" component={Notifications} />
        {/* neuron routes */}
        <ProtectedRoute exact path="/" component={Users} neuronModeOnly />
        <ProtectedRoute
          neuronModeOnly
          exact
          path="/accounts-charts"
          component={AccountsCharts}
        />
        <ProtectedRoute
          exact
          path="/companies"
          component={Companies}
          neuronModeOnly
        />
        <ProtectedRoute exact path="/payments" component={Payments} />
        {/* company routes */}
        <ProtectedRoute
          companyModeOnly
          exact
          path="/collaborators"
          component={Collaborators}
        />
        <ProtectedRoute
          companyModeOnly
          exact
          path="/projects"
          component={Projects}
        />
        <ProtectedRoute
          companyModeOnly
          exact
          path="/projects/:id"
          component={ManageProject}
        />
        <ProtectedRoute
          companyModeOnly
          exact
          path="/clients"
          component={Clients}
        />
        <ProtectedRoute
          companyModeOnly
          exact
          path="/clients"
          component={Clients}
        />
        <ProtectedRoute
          companyModeOnly
          exact
          path="/clients/:id"
          component={ManageClient}
        />
        <ProtectedRoute
          companyModeOnly
          exact
          path="/settings/internalProductions"
          component={InternalProductions}
        />
        <ProtectedRoute
          companyModeOnly
          exact
          path="/settings/presets"
          component={Presets}
        />
        <ProtectedRoute
          companyModeOnly
          exact
          path="/settings/thirdParties"
          component={ThirdParties}
        />
        <ProtectedRoute
          companyModeOnly
          exact
          path="/settings/commissions"
          component={Commissions}
        />
        <ProtectedRoute
          companyModeOnly
          exact
          path="/budgets"
          component={Budgets}
        />
        <ProtectedRoute
          companyModeOnly
          exact
          path="/budgets/newbudget/information"
          component={NewBudget}
        />
        <PrivateRoute
          companyModeOnly
          exact
          path="/budgets/:id/printedBudget"
          component={PrintedBudget}
        />
        <ProtectedRoute
          companyModeOnly
          exact
          path="/budgets/:id/information"
          component={NewBudget}
        />
        <ProtectedRoute
          companyModeOnly
          exact
          path="/budgets/:id/structure"
          component={Structure}
        />
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;

import { useHistory } from 'react-router-dom';

import { useLazyQuery } from '@apollo/client';
import { Expectation } from 'components/atoms/Expectation';
import { GearIcon } from 'components/atoms/Icons/GearIcon';
import { TrashIcon } from 'components/atoms/Icons/TrashIcon';
import { Table } from 'components/organisms/Table';
import { useWorkspace } from 'contexts/WorkspaceContext';
import { GET_BUDGET_LIST } from 'pages/Budgets/query';
import { IconButton } from 'styles/miscellany';

interface Props {
  searchTerm?: string;
  deleteAction: (id: string) => void;
}
const AwaitingApproval = (props: Props) => {
  const { searchTerm, deleteAction } = props;
  const { currentCompanyId } = useWorkspace();
  const history = useHistory();

  const [getBudgets, getBudgetsRes] = useLazyQuery(GET_BUDGET_LIST, {
    notifyOnNetworkStatusChange: true,
  });

  const approvalColumns = [
    {
      Header: 'ID',
      accessor: '_id',
    },
    {
      Header: 'Cliente',
      accessor: 'client.name',
    },
    {
      Header: 'Área/Produto',
      accessor: 'area',
    },
    {
      Header: 'Campanha/Projeto/Task',
      accessor: 'campaign',
    },
    {
      Header: 'Faturamento',
      accessor: 'revenue',
    },
    {
      Header: 'Expectativa',
      accessor: (row) => (
        <Expectation
          budget={row}
          value={row.expectation}
          refetch={getBudgetsRes.refetch}
        />
      ),
    },
  ];

  const budgetItems = getBudgetsRes.data?.BudgetItems?.items || [];

  return (
    <Table
      style={{ marginTop: '2rem' }}
      pages={1}
      fetchData={getBudgets}
      fetchVariables={{ status: 'waiting_approval', unit: currentCompanyId }}
      searchTerm={searchTerm}
      columns={approvalColumns}
      loading={getBudgetsRes.loading}
      emptyText="Não há registros para consulta"
      data={budgetItems}
      actions={(row) => [
        {
          component: (
            <IconButton
              role="button"
              tabIndex={0}
              onClick={() => deleteAction(row._id)}
            >
              <TrashIcon variant="light" width={20} height={20} />
            </IconButton>
          ),
        },
        {
          component: (
            <IconButton
              role="button"
              tabIndex={0}
              title="Editar"
              onClick={() => history.push(`/budgets/${row._id}/information`)}
            >
              <GearIcon variant="light" width={20} height={20} />
            </IconButton>
          ),
        },
      ]}
    />
  );
};

export default AwaitingApproval;

import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { useLazyQuery } from '@apollo/client';
import { TrashIcon } from 'components/atoms/Icons/TrashIcon';
import { SelectField } from 'components/atoms/SelectField';
import { TextField } from 'components/molecules/TextField';
import { useWorkspace } from 'contexts/WorkspaceContext';
import { FieldContainer } from 'pages/Budgets/components/NewBudget/style';
import { FlexContainer, IconButton } from 'styles/miscellany';
import { clearCurrencyStrBrl, toCurrencyBrl } from 'utils/masks';
import { validator } from 'utils/validators/form-validations';

import { FormGroupWithAdding } from '../../../../../../../components/organisms/FormGroupWithAdding';
import { GET_INTERNAL_PRODUCTIONS_LIST } from '../query';

interface Props {
  groupName: string;
}
export function Presets(props: Props) {
  const { groupName } = props;
  const { currentCompanyId } = useWorkspace();

  const [getPresets, getPresetsRes] = useLazyQuery(
    GET_INTERNAL_PRODUCTIONS_LIST,
    {
      notifyOnNetworkStatusChange: true,
    },
  );
  const { setValue, getValues } = useFormContext();

  const presetsItems =
    getPresetsRes.data?.ConfigurationItems?.items?.map((item) => {
      return { ...JSON.parse(item.metadata), _id: item._id };
    }) || [];
  const PresetsMap = presetsItems.map((item) => {
    return { value: item.name, label: item.name };
  });

  const updateTotal = (hours, index) => {
    const totalValue = toCurrencyBrl(
      clearCurrencyStrBrl(getValues(`${groupName}.presets.${index}.value`)) *
        hours,
    );
    setValue(`${groupName}.presets.${index}.total`, totalValue);
  };

  const updateHourValue = (index, element) => {
    const saleValue = presetsItems?.find(
      (opt) => opt.name === getValues(element.name),
    )?.saleValue;

    setValue(`${groupName}.presets.${index}.value`, toCurrencyBrl(saleValue));

    updateTotal(getValues(`${groupName}.presets.${index}.quantity`), index);
  };

  useEffect(() => {
    getPresets({
      variables: { type: 'preset', unit: currentCompanyId },
    });
  }, [getPresets, currentCompanyId]);

  return (
    <div>
      <FormGroupWithAdding
        title="Presets"
        groupName={`${groupName}.presets`}
        renderRow={({ index, onDelete }) => (
          <FieldContainer>
            <FlexContainer value={4} style={{ marginTop: '4px' }}>
              <SelectField
                name={`${groupName}.presets.${index}.item`}
                label="Item"
                placeholder="Item"
                options={PresetsMap}
                validation={validator('required')}
                actionTrigger={(e) => updateHourValue(index, e)}
              />
            </FlexContainer>
            <FlexContainer>
              <TextField
                name={`${groupName}.presets.${index}.quantity`}
                label="Quantidade"
                defaultValue={0}
                onInput={(hours) => {
                  updateTotal(hours, index);
                }}
              />
            </FlexContainer>
            <FlexContainer>
              <TextField
                name={`${groupName}.presets.${index}.value`}
                label="Valor Preset"
                defaultValue={0}
                readOnly
              />
            </FlexContainer>
            <FlexContainer>
              <TextField
                name={`${groupName}.presets.${index}.total`}
                label="Total com Impostos"
                defaultValue={0}
                readOnly
              />
            </FlexContainer>
            <IconButton
              type="button"
              tabIndex={0}
              onClick={() => onDelete(index)}
            >
              <TrashIcon variant="light" width={20} height={20} />
            </IconButton>
          </FieldContainer>
        )}
      />
    </div>
  );
}

/* eslint-disable react/no-array-index-key */
// eslint-disable-next-line no-use-before-define
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';

import { useLazyQuery, useMutation } from '@apollo/client';
import { useWorkspace } from 'contexts/WorkspaceContext';
import { add, format } from 'date-fns';
import { calculateAllBudgetTotals } from 'pages/Budgets/service';
import { FlexContainer, GridColumn, Line } from 'styles/miscellany';
import { capitalize } from 'utils/formatters/capitalize';
import { clearCurrencyNumberBrl, toCurrencyBrl } from 'utils/masks';

import {
  GET_BUDGET_BY_ID,
  UPDATE_BUDGET,
} from '../Budgets/components/NewBudget/query';
import { PrintedBudgetTable } from './components/PrintedBudgetTable';
import { TextAreaCustom } from './components/PrintedBudgetTable/components/TextAreaCustom';
import { RightAlign } from './components/PrintedBudgetTable/style';
import { PrintedBudgetToolbar } from './components/PrintedBudgetToolbar';
import { GET_PROPOSAL_DATE } from './query';
import {
  BoldText,
  DefaultForm,
  FieldContainer,
  PrintedBudgetColumnTitle,
  PrintedBudgetContainer,
  PrintedBudgetFlexContainer,
  PrintedBudgetGrid,
  PrintedBudgetHeader,
  PrintedBudgetSubText,
  PrintedBudgetSubtitle,
  PrintedBudgetTableColumn,
  PrintedBudgetText,
} from './style';

const ref = React.createRef<any>();

export function PrintedBudget() {
  const methods = useForm();
  const { currentCompanyId, currentCompanyName } = useWorkspace();
  const { id } = useParams<any>();

  const [updateBudget, updateBudgetRes] = useMutation(UPDATE_BUDGET);
  const [getBudget, getBudgetRes] = useLazyQuery(GET_BUDGET_BY_ID, {
    notifyOnNetworkStatusChange: true,
  });

  const [getProposalDate, getProposalDateRes] = useLazyQuery(GET_PROPOSAL_DATE);

  const budgetItem = getBudgetRes?.data?.BudgetItems?.items[0];
  const budGroups = budgetItem?.budGroups;
  const proposalDate =
    getProposalDateRes?.data?.CompanyItems?.items[0].defaultProposalPrompt;

  const budgetState = methods.watch();
  const dateResult = add(new Date(), { days: proposalDate });

  useEffect(() => {
    if (currentCompanyId) {
      getProposalDate({ variables: { id: currentCompanyId } });
    }
  }, [id, getProposalDate]);

  useEffect(() => {
    if (id) getBudget({ variables: { id } });
  }, [id, getBudget]);

  useEffect(() => {
    if (budgetItem) {
      if (budgetItem.metadata) {
        methods.setValue('metadata', JSON.parse(budgetItem.metadata));
      }
      if (budgetItem.paymentMethod) {
        methods.setValue('paymentMethod', budgetItem.paymentMethod);
      }

      budgetItem?.budGroups?.forEach((item: any, index) => {
        methods.setValue(`budGroups.${index}`, {
          name: item.name,
          metadata: { ...JSON.parse(item.metadata) },
        });
      });
      budgetItem?.budDeductions?.forEach((item: any, index) => {
        methods.setValue(`budDeductions.${index}`, {
          description: item.description,
          value: toCurrencyBrl(item.value * 100),
        });
      });
    }
  }, [budgetItem]);

  useEffect(() => {
    if (updateBudgetRes.data) {
      toast.success('Alterações salvas', { position: 'top-left' });
    }
    if (updateBudgetRes.error) {
      toast.error('Ops, falha ao salvar as alterações.');
    }
  }, [updateBudgetRes.data, updateBudgetRes.error]);

  const onSubmit = (data) => {
    const payload = {
      ...JSON.parse(JSON.stringify(data)),
      id,
      clientId: budgetItem.client?._id,
      unit: currentCompanyId,
    };

    if (payload.metadata) {
      payload.metadata = JSON.stringify(payload.metadata);
    }

    payload.budGroups?.forEach((group) => {
      group.name = group.name || 'Novo Grupo';
      if (group.metadata) {
        group.metadata = JSON.stringify(group.metadata);
      }
    });
    payload.budComissions = payload.budComissions?.map((group) => {
      return {
        item: group.item,
        percentage: clearCurrencyNumberBrl(group.percentage),
        responsible: group.responsible,
        type: group.type,
      };
    });
    payload.budDeductions = payload.budDeductions?.map((group) => {
      return {
        description: group.description,
        value: clearCurrencyNumberBrl(group.value),
      };
    });
    updateBudget({ variables: payload });
  };

  const { totalBilling } = calculateAllBudgetTotals(budgetState);

  useEffect(() => {
    methods.setValue('revenue', totalBilling.toString());
  }, [totalBilling]);

  return (
    <>
      <FormProvider {...methods}>
        <DefaultForm onSubmit={methods.handleSubmit(onSubmit)}>
          {budgetState?.budGroups && (
            <PrintedBudgetToolbar
              value={ref}
              onSave={() => onSubmit(budgetState)}
              budgetState={budgetState}
            />
          )}

          <PrintedBudgetContainer ref={ref}>
            <PrintedBudgetHeader>
              <h3>
                <BoldText>{currentCompanyName}</BoldText>/
                {budgetItem?.client.name}/{budgetItem?.area}{' '}
                {budgetItem?.campaign}
              </h3>
              <div>
                <p>{id}</p>
                <p>Proposta Comercial</p>
              </div>
            </PrintedBudgetHeader>
            <PrintedBudgetSubtitle>Escopo de Trabalho</PrintedBudgetSubtitle>

            <Line
              color="#c4c4c4"
              style={{ margin: '0.875rem 0 1.8125rem 0' }}
            />

            <PrintedBudgetTableColumn>
              <PrintedBudgetColumnTitle>Entrega</PrintedBudgetColumnTitle>
              <PrintedBudgetColumnTitle>Descritivo</PrintedBudgetColumnTitle>
              <PrintedBudgetColumnTitle>Premissas</PrintedBudgetColumnTitle>
              <RightAlign>
                <PrintedBudgetColumnTitle>Valor</PrintedBudgetColumnTitle>
              </RightAlign>
            </PrintedBudgetTableColumn>

            <Line
              color="#c4c4c4"
              style={{ margin: '0.875rem 0 1.8125rem 0' }}
            />

            {budGroups &&
              budGroups?.map((group, index) => (
                <PrintedBudgetTable
                  key={`budGroups.${index}`}
                  groupName={`budGroups.${index}`}
                  group={
                    budgetState?.budGroups
                      ? budgetState?.budGroups[index]
                      : null
                  }
                />
              ))}

            <FieldContainer>
              <FlexContainer value={2}>
                <PrintedBudgetColumnTitle>Deduções</PrintedBudgetColumnTitle>
              </FlexContainer>
              {budgetItem &&
                budgetItem?.budDeductions?.map((group) => (
                  <>
                    <FlexContainer value={4}>
                      <PrintedBudgetText>{group.description}</PrintedBudgetText>
                    </FlexContainer>
                    <FlexContainer value={2}>
                      <RightAlign>
                        <PrintedBudgetColumnTitle>
                          {group.value && toCurrencyBrl(group.value * 100)}
                        </PrintedBudgetColumnTitle>
                      </RightAlign>
                    </FlexContainer>
                  </>
                ))}
            </FieldContainer>

            <Line
              color="#c4c4c4"
              style={{ margin: '3.0625rem 0 1.5625rem 0' }}
            />

            <PrintedBudgetFlexContainer>
              <PrintedBudgetSubtitle>Investimento Total</PrintedBudgetSubtitle>
              <PrintedBudgetSubtitle>
                {toCurrencyBrl(totalBilling)}
              </PrintedBudgetSubtitle>
            </PrintedBudgetFlexContainer>

            <Line
              color="#c4c4c4"
              style={{ margin: '1.5625rem 0 0.5625rem 0' }}
            />

            <PrintedBudgetGrid>
              <PrintedBudgetColumnTitle>
                Forma de pagamento
              </PrintedBudgetColumnTitle>
              <TextAreaCustom
                placeholder="Escreva a forma de pagamento aqui..."
                name="paymentMethod"
              />
            </PrintedBudgetGrid>

            <Line
              color="#c4c4c4"
              style={{ margin: '6.6875rem 0 3.125rem 0' }}
            />

            <PrintedBudgetColumnTitle>Observações</PrintedBudgetColumnTitle>
            <TextAreaCustom
              placeholder="Escreva a observação aqui..."
              name="metadata.note"
            />

            <GridColumn
              length={4}
              gap={3}
              fragment
              style={{ marginTop: '9.125rem' }}
            >
              <PrintedBudgetColumnTitle>
                Validade da proposta
              </PrintedBudgetColumnTitle>
              <PrintedBudgetText>
                {format(dateResult, 'dd/MM/yyyy')}
              </PrintedBudgetText>
            </GridColumn>
            <GridColumn
              length={2}
              gap={24}
              fragment
              style={{ margin: '6.9375rem 0 3.0625rem 0', textAlign: 'center' }}
            >
              <div>
                <Line color="#c4c4c4" style={{ margin: '0 0 1.1875rem 0' }} />
                {currentCompanyName}
              </div>
              <div>
                <Line color="#c4c4c4" style={{ margin: '0 0 1.1875rem 0' }} />
                {capitalize(budgetItem?.client.name)}
              </div>
            </GridColumn>

            <PrintedBudgetColumnTitle>Legal</PrintedBudgetColumnTitle>
            <Line color="#c4c4c4" style={{ margin: '0 0 2.5rem 0' }} />

            <PrintedBudgetSubText>
              {budgetItem?.client?.legals?.message ||
                'Por meio da aprovação deste documento, a Agência receberá o valor acordado, que deve ser considerado para todos os efeitos como fixo e não sujeitos a reconciliação, auferido conforme faturado. A execução   desta proposta pode envolver a contratação de terceiros.'}
            </PrintedBudgetSubText>

            <PrintedBudgetFlexContainer style={{ marginTop: '14.0625rem' }}>
              <PrintedBudgetSubText>
                PROPRIETARY AND CONFIDENTIAL.{' '}
                {currentCompanyName?.toUpperCase()}, フラグ
              </PrintedBudgetSubText>
              <PrintedBudgetSubText>
                {format(new Date(), 'dd.MM.yyyy')}
              </PrintedBudgetSubText>
            </PrintedBudgetFlexContainer>
          </PrintedBudgetContainer>
        </DefaultForm>
      </FormProvider>
    </>
  );
}

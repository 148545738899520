import { useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';
import { useWorkspace } from 'contexts/WorkspaceContext';
import { Line } from 'styles/miscellany';
import { clearCurrencyStrBrl, toCurrencyBrl } from 'utils/masks';

import { COMPANY_TAX } from '../../../Budgets/components/Structure/components/StructureGroup/query';
import { InternalProduction } from './components/InternalProduction';
import { Invoice } from './components/Invoice';
import { Outsource } from './components/Outsource';
import { Presets } from './components/Presets';
import { Repass } from './components/Repass';
import { TextAreaCustom } from './components/TextAreaCustom';
import {
  PrintedBudgetTableColumn,
  PrintedBudgetTableContainer,
  RightAlign,
} from './style';

interface Props {
  groupName: string;
  group: any;
}

export function PrintedBudgetTable(props: Props) {
  const { groupName, group } = props;
  const { currentCompanyId } = useWorkspace();

  const [getCompanyTax, getCompanyTaxRes] = useLazyQuery(COMPANY_TAX);
  const companyTax = getCompanyTaxRes.data?.CompanyItems?.items[0].directTax;

  useEffect(() => {
    getCompanyTax({ variables: { companyId: currentCompanyId } });
  }, [getCompanyTax, currentCompanyId]);

  const updateGroupTotal = () => {
    const totalsArray: any[] = [];
    if (group?.metadata) {
      Object.entries(group?.metadata).forEach((section: any) => {
        totalsArray?.push(
          section
            .filter((item: any) => typeof item !== 'string')
            .map((item) =>
              item.reduce(
                (sum, current) =>
                  sum +
                  +clearCurrencyStrBrl(current.total ? current.total : '0'),
                0,
              ),
            ),
        );
      });
    }
    return totalsArray.reduce((sum, current) => sum + (+current || 0), 0);
  };

  const totalBudget = updateGroupTotal();
  const metadataGroupName = `${groupName}.metadata`;

  return (
    <PrintedBudgetTableContainer>
      <PrintedBudgetTableColumn>
        <TextAreaCustom name={`${groupName}.name`} isBold />
        <TextAreaCustom
          placeholder="Escreva a descrição aqui..."
          name={`${metadataGroupName}.description`}
        />
        <TextAreaCustom
          placeholder="Escreva as premissas aqui..."
          name={`${metadataGroupName}.premise`}
        />
        <RightAlign>
          <TextAreaCustom
            disabled
            isBold
            isRight
            name={`${groupName}.total`}
            value={toCurrencyBrl(totalBudget)}
          />
        </RightAlign>
      </PrintedBudgetTableColumn>
      {group?.metadata?.internalProduction?.length > 0 && (
        <InternalProduction groupName={metadataGroupName} />
      )}
      {group?.metadata?.presets?.length > 0 && (
        <Presets groupName={metadataGroupName} />
      )}
      {group?.metadata?.outsource?.length > 0 && (
        <Outsource groupName={metadataGroupName} companyTax={companyTax} />
      )}
      {group?.metadata?.repass?.length > 0 && (
        <Repass groupName={metadataGroupName} />
      )}
      {group?.metadata?.invoice?.length > 0 && (
        <Invoice groupName={metadataGroupName} />
      )}
      <Line color="#c4c4c4" style={{ margin: '8rem 0 1.5625rem 0' }} />
    </PrintedBudgetTableContainer>
  );
}

import { useFormContext } from 'react-hook-form';

import { TextField } from 'components/molecules/TextField';
import { FormGroupWithAdding } from 'components/organisms/FormGroupWithAdding';
import { ThirdyColumn } from 'pages/PrintedBudget/components/PrintedBudgetTable/style';
import { PrintedBudgetColumnTitle } from 'pages/PrintedBudget/style';
import {
  clearCurrencyStrBrl,
  clearCurrencyNumberBrl,
  clearCurrencyStr,
  toCurrencyBrl,
} from 'utils/masks';

interface Props {
  groupName: string;
}
export function Repass(props: Props) {
  const { groupName } = props;

  const { setValue, getValues } = useFormContext();

  const updateTotal = (index) => {
    const totalValue =
      +clearCurrencyStrBrl(getValues(`${groupName}.repass.${index}.value`)) +
      +clearCurrencyStrBrl(getValues(`${groupName}.repass.${index}.admValue`));

    setValue(`${groupName}.repass.${index}.total`, toCurrencyBrl(totalValue));
  };

  const updatePercentageValue = (index) => {
    const value = clearCurrencyNumberBrl(
      getValues(`${groupName}.repass.${index}.value`),
    );
    const percentage = clearCurrencyStr(
      getValues(`${groupName}.repass.${index}.admPercentage`),
    );

    setValue(
      `${groupName}.repass.${index}.admValue`,
      toCurrencyBrl(Number(value * (percentage / 100)).toFixed(2)),
    );

    updateTotal(index);
  };

  return (
    <div>
      <PrintedBudgetColumnTitle>Repasses</PrintedBudgetColumnTitle>
      <FormGroupWithAdding
        title="Repasses"
        groupName={`${groupName}.repass`}
        withoutAdding
        renderRow={({ index }) => (
          <ThirdyColumn>
            <TextField
              name={`${groupName}.repass.${index}.outsource`}
              label="Terceiro"
              isLight
            />
            <TextField
              name={`${groupName}.repass.${index}.item`}
              label="Item"
              isLight
            />
            <TextField
              name={`${groupName}.repass.${index}.value`}
              label="Valor"
              pattern="moneyPrefix"
              onKeyUp={() => {
                updatePercentageValue(index);
              }}
              isLight
            />
            <TextField
              name={`${groupName}.repass.${index}.admPercentage`}
              label="%adm"
              suffix="%"
              onKeyUp={() => {
                updatePercentageValue(index);
              }}
              isLight
            />
            <TextField
              name={`${groupName}.repass.${index}.admValue`}
              label="Valor adm"
              readOnly
              isLight
            />
            <TextField
              name={`${groupName}.repass.${index}.total`}
              label="Total sem Impostos"
              readOnly
              isLight
            />
          </ThirdyColumn>
        )}
      />
    </div>
  );
}

import { Card, GridColumn } from 'styles/miscellany';
import { toCurrencyBrl } from 'utils/masks';

import { CostHolder, Percent, ProjectCostsContainer, TitleCost } from './style';

type ValueAndPercentage = {
  value: number;
  percentage: number;
};
interface ProjectsCostsProps {
  costSoFar: ValueAndPercentage;
  budgetCost: ValueAndPercentage;
  totalRevenue: number;
}

export function ProjectCosts(props: ProjectsCostsProps) {
  const { costSoFar, budgetCost, totalRevenue } = props;

  const costs = [
    {
      title: 'Custos Inicialmente Orçados',
      cost: toCurrencyBrl(budgetCost ? budgetCost.value.toFixed(2) : 0),
      percent: budgetCost?.percentage,
      percentState: 'danger',
    },
    {
      title: 'Custos Previstos Até Agora',
      cost: toCurrencyBrl(costSoFar ? costSoFar.value.toFixed(2) : 0),
      percent: costSoFar?.percentage,
      percentState: 'warning',
    },
    {
      title: 'Receita Líquida (<strong>- Imposto - Key</strong>)',
      cost: `R$ 0`,
      percent: 99,
      percentState: 'primary',
    },
    {
      title: 'Faturamento Total',
      cost: toCurrencyBrl(totalRevenue ? totalRevenue.toFixed(2) : 0),
    },
  ];

  return (
    <ProjectCostsContainer>
      <GridColumn length={costs.length} gap={1.5} fragment>
        {costs.map(({ title, cost, percent, percentState }) => (
          <Card key={title}>
            <TitleCost dangerouslySetInnerHTML={{ __html: title }} />
            <CostHolder>
              <h5>{cost}</h5>
              {percentState ? (
                <Percent variant={percentState}>{percent}%</Percent>
              ) : null}
            </CostHolder>
          </Card>
        ))}
      </GridColumn>
    </ProjectCostsContainer>
  );
}

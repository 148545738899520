import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import { useLazyQuery, useMutation } from '@apollo/client';
import { Spinner } from 'components/atoms/Spinner';
import { TextField } from 'components/molecules/TextField';
import { useDrawer } from 'contexts/DrawerContext';
import { useWorkspace } from 'contexts/WorkspaceContext';
import { QUERY_COMPANY } from 'pages/Companies/query';
import {
  CREATE_INTERNAL_PRODUCTION,
  GET_INTERNALPRODUCTION_BY_ID,
  UPDATE_INTERNAL_PRODUCTION,
} from 'pages/InternalProduction/query';
import { DefaultForm } from 'styles/miscellany';
import { capitalize } from 'utils/formatters/capitalize';
import { clearCurrencyNumberBrl, toCurrencyBrl } from 'utils/masks';
import { validator } from 'utils/validators/form-validations';

interface InternalProductionFormProps {
  id?: string | boolean;
  refetch: any;
}

export function InternalProductionForm(props: InternalProductionFormProps) {
  const { currentCompanyId } = useWorkspace();
  const companyId = currentCompanyId;
  const { id, refetch } = props;
  const isEditing = !!id;
  const methods = useForm();

  const [getInternalProductionById, getInternalProductionByIdRes] =
    useLazyQuery(GET_INTERNALPRODUCTION_BY_ID);
  const internalproductionItem =
    getInternalProductionByIdRes?.data?.ConfigurationItems?.items[0];
  const [updateInternalProduction, updateInternalProductionRes] = useMutation(
    UPDATE_INTERNAL_PRODUCTION,
  );
  const [createInternalProduction, createInternalProductionRes] = useMutation(
    CREATE_INTERNAL_PRODUCTION,
  );
  const { closeDrawer } = useDrawer();

  const formValues = methods.watch();

  const [getCompany, getCompanyRes] = useLazyQuery(QUERY_COMPANY);
  const companyData = getCompanyRes.data?.CompanyItems?.items[0];

  useEffect(() => {
    getCompany({ variables: { companyId } });
  }, [companyId, getCompany]);

  useEffect(() => {
    if (!internalproductionItem && isEditing)
      getInternalProductionById({ variables: { id } });

    if (internalproductionItem && isEditing) {
      const decodedValues = JSON.parse(internalproductionItem.metadata);
      Object.entries(decodedValues).forEach(([key, value]) => {
        if (key !== '__typename') {
          methods.setValue(key, value);
        }
      });
    }
  }, [
    internalproductionItem,
    methods,
    isEditing,
    getInternalProductionById,
    id,
  ]);

  useEffect(() => {
    if (formValues?.costValue && formValues?.profitability && companyData) {
      const saleValue =
        clearCurrencyNumberBrl(formValues.costValue) *
        (1 + clearCurrencyNumberBrl(formValues.profitability) / 100);
      const totalValue = toCurrencyBrl(
        (
          saleValue *
          (1 + clearCurrencyNumberBrl(companyData?.directTax) / 100)
        ).toFixed(2),
      );
      methods.setValue('saleValue', totalValue);
    }
  }, [formValues?.costValue, formValues?.profitability, companyData]);

  const onSubmit = (dataForm) => {
    dataForm.name = capitalize(dataForm.name);
    dataForm.category = capitalize(dataForm.category);

    if (isEditing) {
      updateInternalProduction({
        variables: {
          id,
          label: dataForm.name,
          type: 'production',
          metadata: JSON.stringify(dataForm),
          unit: currentCompanyId,
        },
      });
    } else {
      createInternalProduction({
        variables: {
          label: dataForm.name,
          type: 'production',
          metadata: JSON.stringify(dataForm),
          unit: currentCompanyId,
        },
      });
    }
  };

  useEffect(() => {
    if (createInternalProductionRes.data) {
      toast.success('Cadastro efetuado!');

      refetch();

      closeDrawer();
    }

    if (createInternalProductionRes.error) {
      toast.error('Ops, falha ao realizar o cadastro.');
    }
  }, [createInternalProductionRes.data, createInternalProductionRes.error]);

  useEffect(() => {
    if (updateInternalProductionRes.data) {
      toast.success('Cadastro atualizado!');

      refetch();

      closeDrawer();
    }

    if (updateInternalProductionRes.error) {
      toast.error('Ops, falha ao atualizar o cadastro.');
    }
  }, [updateInternalProductionRes.data, updateInternalProductionRes.error]);

  if (
    getInternalProductionByIdRes.loading ||
    createInternalProductionRes.loading ||
    updateInternalProductionRes.loading
  )
    return <Spinner />;

  return (
    <FormProvider {...methods}>
      <DefaultForm
        onSubmit={methods.handleSubmit(onSubmit)}
        id="internalproduction-form"
      >
        <TextField
          name="name"
          label="Nome"
          validation={validator('required')}
          pattern="capitalize"
        />
        <TextField
          name="category"
          label="Categoria"
          validation={validator('required')}
          pattern="capitalize"
        />
        <TextField
          name="costValue"
          label="Valor Custo"
          validation={validator('required')}
          pattern="moneyPrefix"
        />
        <TextField
          name="profitability"
          label="Lucratividade"
          validation={validator('required')}
          pattern="money"
          suffix="%"
        />
        <TextField
          name="saleValue"
          label="Valor Total com Impostos"
          validation={validator('required')}
          pattern="moneyPrefix"
          readOnly
        />
      </DefaultForm>
    </FormProvider>
  );
}

InternalProductionForm.defaultProps = {
  id: false,
};

import { createContext, useContext, useEffect, useState } from 'react';

import { storageKeys } from 'services/storage-keys';

interface WorkspaceContextProps {
  neuronMode: boolean;
  currentCompanyId?: string;
  currentCompanyName?: string;
  setNeuronMode: (isNeuronMode: boolean) => void;
  setCurrentCompany: (id: string, name?: string) => void;
}
export const WorkspaceContext = createContext({} as WorkspaceContextProps);

export const WorkspaceProvider = ({ children }) => {
  const [isNeuronMode, setIsNeuronMode] = useState(
    !localStorage.getItem(storageKeys.companyId),
  );
  const [currentCompanyId, setCurrentCompanyId] = useState(
    localStorage.getItem(storageKeys.companyId) || '',
  );
  const [currentCompanyName, setCurrentCompanyName] = useState(
    localStorage.getItem(storageKeys.companyName) || '',
  );

  useEffect(() => {
    if (isNeuronMode) {
      localStorage.removeItem(storageKeys.companyId);
      localStorage.removeItem(storageKeys.companyName);
    } else {
      localStorage.setItem(storageKeys.companyId, currentCompanyId);
      localStorage.setItem(storageKeys.companyName, currentCompanyName);
    }
  }, [currentCompanyId, currentCompanyName, isNeuronMode]);

  function setCurrentCompany(value: string, name = '') {
    setCurrentCompanyId(value);
    setCurrentCompanyName(name);
  }
  function setNeuronMode(value: boolean) {
    setIsNeuronMode(value);
  }

  const value = {
    neuronMode: isNeuronMode,
    setNeuronMode,
    setCurrentCompany,
    currentCompanyId,
    currentCompanyName,
  };

  return (
    <WorkspaceContext.Provider value={value}>
      {children}
    </WorkspaceContext.Provider>
  );
};

export const useWorkspace = () => useContext(WorkspaceContext);

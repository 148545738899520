import { gql } from '@apollo/client';

export const GET_PROJECT_PAYMENTS = gql`
  query ($unit: String, $project_id: String, $page: Int) {
    PaymentItems(unit: $unit, project_id: $project_id, page: $page) {
      items {
        _id
        status
        poCode
        groupName
        category
        providerName
        providerEmail
        itemDescription
        budget {
          area
          campaign
          task
          revenue
          unit
          expectation
          status
          metadata
          budComissions {
            item
            type
            percentage
            responsible
          }
        }
        budgetedValue
        realizedValue
        budgetedCurrency
        realizedCurrency
      }
      pages
    }
  }
`;

export const DELETE_PAYMENT = gql`
  mutation deletePayment($id: String!) {
    deletePayment(id: $id)
  }
`;

export const CREATE_PAYMENT = gql`
  mutation createPayment(
    $clientId: String!
    $budgetId: String!
    $projectId: String!
    $type: String
    $category: String
    $groupName: String
    $poCode: String
    $itemDescription: String
    $providerName: String
    $providerEmail: String
    $detailedDescription: String
    $paymentDate: String
    $paymentMethod: String
    $nfCode: String
    $nfEmissionDate: String
    $nfDueDate: String
    $realizedValue: Float
    $realizedCurrency: String
    $realizedQuota: Float
    $status: String
    $metadata: String
  ) {
    createPayment(
      clientId: $clientId
      budgetId: $budgetId
      projectId: $projectId
      type: $type
      category: $category
      groupName: $groupName
      poCode: $poCode
      itemDescription: $itemDescription
      providerName: $providerName
      providerEmail: $providerEmail
      detailedDescription: $detailedDescription
      paymentDate: $paymentDate
      paymentMethod: $paymentMethod
      nfCode: $nfCode
      nfEmissionDate: $nfEmissionDate
      nfDueDate: $nfDueDate
      realizedValue: $realizedValue
      realizedCurrency: $realizedCurrency
      realizedQuota: $realizedQuota
      status: $status
      metadata: $metadata
    ) {
      _id
    }
  }
`;

export const UPDATE_PAYMENT = gql`
  mutation paymentSetup(
    $id: String!
    $type: String
    $groupName: String
    $category: String
    $poCode: String
    $itemDescription: String
    $providerName: String
    $providerEmail: String
    $detailedDescription: String
    $paymentDate: String
    $paymentMethod: String
    $nfCode: String
    $nfEmissionDate: String
    $nfDueDate: String
    $realizedValue: Float
    $realizedCurrency: String
    $realizedQuota: Float
    $status: String
    $metadata: String
  ) {
    paymentSetup(
      id: $id
      type: $type
      groupName: $groupName
      category: $category
      poCode: $poCode
      itemDescription: $itemDescription
      providerName: $providerName
      providerEmail: $providerEmail
      detailedDescription: $detailedDescription
      paymentDate: $paymentDate
      paymentMethod: $paymentMethod
      nfCode: $nfCode
      nfEmissionDate: $nfEmissionDate
      nfDueDate: $nfDueDate
      realizedValue: $realizedValue
      realizedCurrency: $realizedCurrency
      realizedQuota: $realizedQuota
      status: $status
      metadata: $metadata
    )
  }
`;

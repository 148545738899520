/* eslint-disable react/jsx-no-bind */
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import { useLazyQuery, useMutation } from '@apollo/client';
import { AutocompleteSelect } from 'components/atoms/AutocompleteSelect';
import { Button } from 'components/atoms/Button';
import { FileDisplay } from 'components/atoms/FileDisplay';
import { UploadIcon } from 'components/atoms/Icons/UploadIcon';
import { SelectField } from 'components/atoms/SelectField';
import { Spinner } from 'components/atoms/Spinner';
import { CnpjSearchField } from 'components/molecules/CnpjSearchField';
import { DeleteModal } from 'components/molecules/DeleteModal';
import { TextField } from 'components/molecules/TextField';
import { useDrawer } from 'contexts/DrawerContext';
import { useHelperData } from 'contexts/HelperDataContext';
import { useModal } from 'contexts/ModalContext';
import { AnimatePresence, motion } from 'framer-motion';
import { useFetch } from 'hooks/useFetch';
import { StyledRowDiv } from 'pages/Clients/components/ClientForm/style';
import { QUERY_COMPANY } from 'pages/Companies/query';
import { Services } from 'services/service';
import { DefaultForm } from 'styles/miscellany';
import { removeMoneyMask } from 'utils/formatters/removeMoneyMask';
import { validator } from 'utils/validators/form-validations';

import { CompanyBankAccounts } from '../CompanyBankAccounts';
import { CompanyContacts } from '../CompanyContacts';
import { ADD_COMPANY, DELETE_FILE, UPDATE_COMPANY } from './query';
import {
  StyledDiv,
  StyledName,
  StyledOtherDocsDiv,
  StyledOtherDocsText,
  StyledRadioDiv,
  StyledRadioInput,
  StyledSubtitle,
  StyledUploadDiv,
  StyledUploadInput,
  StyledUploadText,
} from './style';

export interface CompanyFile {
  type: 'uploadedFile' | 'fileToUpload' | 'fileToDelete';
  field: 'contract' | 'other';
  name: string;
  size: number; // in MB
  file?: File;
  id?: string;
  fileUrl?: string;
  fileType?: 'nf' | 'boleto';
}

interface CompanyFormProps {
  companyId?: string;
  refetch: any;
  isCnpjInUse: (cnpj: string, companyId?: string) => boolean;
}

export function CompanyForm(props: CompanyFormProps) {
  const { closeDrawer, setIsLoading } = useDrawer();
  const { companyId, refetch, isCnpjInUse } = props;
  const isEditing = !!companyId;
  const methods = useForm();
  const { openModal } = useModal();
  const [validityValues, setValidityValues] = useState({
    defaultProposalPrompt: 60,
    defaultPoPrompt: 60,
  });
  const [files, setFiles] = useState<CompanyFile[]>([]);
  const [getCompany, getCompanyRes] = useLazyQuery(QUERY_COMPANY);
  const { fetchAuthorized } = useFetch();
  const { searchCities } = useHelperData();
  const [addCompany, addCompanyRes] = useMutation(ADD_COMPANY);
  const [updateCompany, updateCompanyRes] = useMutation(UPDATE_COMPANY);
  const [deleteFile] = useMutation(DELETE_FILE);

  const companyData = getCompanyRes.data?.CompanyItems?.items[0];

  useEffect(() => {
    if (isEditing) getCompany({ variables: { companyId } });
  }, [isEditing, companyId, getCompany]);

  useEffect(() => {
    if (companyData) {
      Object.entries(companyData).forEach(([key, value]) => {
        if (key !== '__typename') {
          // remove "typename" of nested arrays
          if (Array.isArray(value)) {
            value.forEach((subitem) => delete subitem.__typename);
          }
          methods.setValue(key, value);
        }
      });
      if (companyData.monthlyValue) {
        methods.setValue(
          'monthlyValue',
          (companyData.monthlyValue / 100).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            style: 'currency',
            currency: 'BRL',
          }),
        );
      }

      setValidityValues({
        defaultProposalPrompt: companyData.defaultProposalPrompt || 60,
        defaultPoPrompt: companyData.defaultPoPrompt || 60,
      });
      setFiles(
        companyData.files.map((file) => {
          return {
            type: 'uploadedFile',
            field: 'other',
            name: file.originalname,
            size: file.filesize,
            file: undefined,
            id: file._id,
            fileUrl: file.url,
          } as CompanyFile;
        }),
      );
    }
  }, [companyData]);

  function uploadOther() {
    document.getElementById('otherInput')?.click();
  }

  const onSubmit = (formInfo) => {
    if (isCnpjInUse(formInfo.cnpj, companyId)) {
      methods.setError(
        'cnpj',
        {
          message: 'Já existe uma empresa cadastrada com este CNPJ',
        },
        {
          shouldFocus: true,
        },
      );
      return;
    }

    const payload = {
      ...formInfo,
      defaultProposalPrompt: validityValues.defaultProposalPrompt,
      defaultPoPrompt: validityValues.defaultPoPrompt,
      monthlyValue: removeMoneyMask(formInfo.monthlyValue?.toString()) * 100,
    };

    const submitPromise = isEditing
      ? updateCompany({
          variables: {
            ...payload,
            id: companyId,
          },
        })
      : addCompany({
          variables: {
            ...payload,
            is_disabled: false,
          },
        });
    setIsLoading(true);

    submitPromise
      .then((creationResult) => {
        const createdCompanyId = isEditing
          ? companyId
          : creationResult?.data?.createCompany?._id;
        const formdata = new FormData();
        const filesToUpload = files.filter((file) => {
          return file.type === 'fileToUpload';
        });

        if (filesToUpload.length > 0) {
          toast.success('Enviando arquivos, por favor aguarde');

          filesToUpload.forEach((fileToUpload) => {
            if (fileToUpload.file)
              formdata.append('documents', fileToUpload.file);
          });

          const requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow' as RequestRedirect,
          };

          fetchAuthorized(
            `${Services.Upload}?for=company&attachTo=${createdCompanyId}`,
            requestOptions,
          )
            .then((response) => response.text())
            .then(() => {
              toast.success('Arquivos enviados com sucesso!');
              closeDrawer();
              setIsLoading(false);
            })
            .catch(() => {
              toast.error('Ocorreu um erro ao enviar um ou mais arquivos.');
              if (!isEditing) closeDrawer();
              setIsLoading(false);
            });
        } else {
          setIsLoading(false);
          toast.success(
            isEditing ? 'Salvo com sucesso!' : 'Cadastro efetuado!',
          );
          closeDrawer();
        }
      })
      .catch(() => {
        setIsLoading(false);
        toast.error('Erro ao cadastrar, por favor tente novamente');
      })
      .finally(() => {
        refetch();
      });
  };

  const handleCnpjFound = (inputData) => {
    if (inputData.cnpj !== companyData?.cnpj) {
      if (isCnpjInUse(inputData.cnpj, companyId)) {
        methods.setError('cnpj', {
          message: 'Já existe uma empresa cadastrada com este CNPJ',
        });
      } else {
        Object.entries(inputData).forEach(([key, value]) =>
          methods.setValue(key, value),
        );
      }
    }
  };

  const handleDeleteFile = (clickedFile: CompanyFile) => {
    if (clickedFile.type === 'uploadedFile') {
      openModal({
        content: (
          <DeleteModal
            onAccept={() =>
              deleteFile({ variables: { id: clickedFile.id } }).then(() => {
                setFiles(files.filter((f) => f.id !== clickedFile.id));
              })
            }
          />
        ),
      });
    } else {
      setFiles(files.filter((f) => f.id !== clickedFile.id));
    }
  };

  if (addCompanyRes?.loading || updateCompanyRes?.loading) return <Spinner />;

  return (
    <FormProvider {...methods}>
      <DefaultForm onSubmit={methods.handleSubmit(onSubmit)} id="company-form">
        <StyledDiv>
          <CnpjSearchField name="cnpj" onCnpjFound={handleCnpjFound} />
          <TextField name="legalName" label="Razão Social" />
        </StyledDiv>
        <StyledName>
          <TextField
            name="name"
            label="Nome"
            validation={validator('required')}
          />
        </StyledName>

        <CompanyBankAccounts />
        <CompanyContacts />

        <StyledDiv>
          <StyledSubtitle>Informações Tributárias</StyledSubtitle>
          <StyledRowDiv />
          <AutocompleteSelect
            name="cpom"
            label="Cadastro CPOM"
            placeholder="Pesquisar Município"
            loadOptions={searchCities}
            isMulti
          />
          <SelectField
            name="fiscalCategory"
            label="Regime Fiscal"
            placeholder="Regime Fiscal"
            options={[
              { value: 'Simples Nacional', label: 'Simples Nacional' },
              { value: 'Lucro Real', label: 'Lucro Real' },
              { value: 'Lucro Presumido', label: 'Lucro Presumido' },
            ]}
          />
          <TextField name="cityTaxId" label="Inscrição Municipal" />
          <TextField name="stateTaxId" label="Inscrição Estadual" />

          <StyledSubtitle>Natureza</StyledSubtitle>
          <StyledRowDiv />
          <TextField name="publicityAgency" label="Natureza" />
          <SelectField
            name="area"
            label="Área"
            placeholder="Área"
            options={[
              { value: 'advertising-agency', label: 'Agência de Publicidade' },
              { value: 'activation', label: 'Ativação' },
              { value: 'consultancy', label: 'Consultoria' },
              { value: 'holding', label: 'Holding' },
              {
                value: 'audiovisual-producer',
                label: 'Produtora de AudioVisual',
              },
              { value: 'tecnology-producer', label: 'Produtora de Tecnologia' },
              { value: 'vehicle', label: 'Veículo' },
              { value: 'others', label: 'Outros' },
            ]}
          />
          <StyledRowDiv />

          <StyledSubtitle>Impostos Gerenciais</StyledSubtitle>
        </StyledDiv>
        <StyledDiv>
          <TextField
            name="directTax"
            label="Impostos Diretos"
            pattern="money"
            suffix="%"
          />
          <TextField
            name="receiptTax"
            label="Imposto De Renda"
            pattern="money"
            suffix="%"
          />
          <StyledSubtitle>Key</StyledSubtitle>
        </StyledDiv>
        <StyledDiv>
          <TextField name="flagKey" label="Key" suffix="%" pattern="money" />
          <SelectField
            name="flagReceipt"
            label="Parâmetro"
            placeholder="Parâmetro"
            options={[
              { value: 'Receita líquida', label: 'Receita líquida' },
              { value: 'Faturamento bruto', label: 'Faturamento bruto' },
            ]}
          />
        </StyledDiv>

        <StyledSubtitle>Validade de Propostas</StyledSubtitle>
        <StyledDiv>
          <StyledRadioDiv>
            <StyledRadioInput>
              <Button
                variant={
                  validityValues.defaultProposalPrompt === 15
                    ? 'primary'
                    : 'secondary'
                }
                onClick={(e) => {
                  e.preventDefault();
                  setValidityValues({
                    ...validityValues,
                    defaultProposalPrompt: 15,
                  });
                }}
              >
                15 dias
              </Button>
            </StyledRadioInput>
            <StyledRadioInput>
              <Button
                variant={
                  validityValues.defaultProposalPrompt === 30
                    ? 'primary'
                    : 'secondary'
                }
                onClick={(e) => {
                  e.preventDefault();
                  setValidityValues({
                    ...validityValues,
                    defaultProposalPrompt: 30,
                  });
                }}
              >
                30 dias
              </Button>
            </StyledRadioInput>
            <StyledRadioInput>
              <Button
                variant={
                  validityValues.defaultProposalPrompt === 45
                    ? 'primary'
                    : 'secondary'
                }
                onClick={(e) => {
                  e.preventDefault();
                  setValidityValues({
                    ...validityValues,
                    defaultProposalPrompt: 45,
                  });
                }}
              >
                45 dias
              </Button>
            </StyledRadioInput>
            <StyledRadioInput>
              <Button
                variant={
                  validityValues.defaultProposalPrompt === 60
                    ? 'primary'
                    : 'secondary'
                }
                onClick={(e) => {
                  e.preventDefault();
                  setValidityValues({
                    ...validityValues,
                    defaultProposalPrompt: 60,
                  });
                }}
              >
                60 dias
              </Button>
            </StyledRadioInput>
          </StyledRadioDiv>
        </StyledDiv>
        <StyledSubtitle>Vencimento da PO</StyledSubtitle>
        <StyledDiv>
          <StyledRadioDiv>
            <StyledRadioInput>
              <Button
                variant={
                  validityValues.defaultPoPrompt === 15
                    ? 'primary'
                    : 'secondary'
                }
                onClick={(e) => {
                  e.preventDefault();
                  setValidityValues({
                    ...validityValues,
                    defaultPoPrompt: 15,
                  });
                }}
              >
                15 dias
              </Button>
            </StyledRadioInput>
            <StyledRadioInput>
              <Button
                variant={
                  validityValues.defaultPoPrompt === 30
                    ? 'primary'
                    : 'secondary'
                }
                onClick={(e) => {
                  e.preventDefault();
                  setValidityValues({
                    ...validityValues,
                    defaultPoPrompt: 30,
                  });
                }}
              >
                30 dias
              </Button>
            </StyledRadioInput>
            <StyledRadioInput>
              <Button
                variant={
                  validityValues.defaultPoPrompt === 45
                    ? 'primary'
                    : 'secondary'
                }
                onClick={(e) => {
                  e.preventDefault();
                  setValidityValues({
                    ...validityValues,
                    defaultPoPrompt: 45,
                  });
                }}
              >
                45 dias
              </Button>
            </StyledRadioInput>
            <StyledRadioInput>
              <Button
                variant={
                  validityValues.defaultPoPrompt === 60
                    ? 'primary'
                    : 'secondary'
                }
                onClick={(e) => {
                  e.preventDefault();
                  setValidityValues({
                    ...validityValues,
                    defaultPoPrompt: 60,
                  });
                }}
              >
                60 dias
              </Button>
            </StyledRadioInput>
          </StyledRadioDiv>
        </StyledDiv>

        <StyledOtherDocsDiv>
          <StyledOtherDocsText>Demais Documentos</StyledOtherDocsText>
          <StyledUploadDiv
            onClick={() => {
              uploadOther();
            }}
          >
            <StyledUploadInput
              type="file"
              id="otherInput"
              multiple
              onChange={async (e) => {
                const fileList = e.target.files as FileList;
                const newFiles: File[] = [];
                for (
                  let currentFile = 0;
                  currentFile < fileList.length;
                  currentFile += 1
                ) {
                  const file = fileList.item(currentFile);
                  if (file) newFiles.push(file as File);
                }
                if (newFiles) {
                  const formattedFileArray: CompanyFile[] = [];
                  for (
                    let currentFile = 0;
                    currentFile < fileList.length;
                    currentFile += 1
                  ) {
                    formattedFileArray.push({
                      type: 'fileToUpload',
                      field: 'other',
                      name: newFiles[currentFile].name,
                      size: newFiles[currentFile].size / 1024 / 1024,
                      file: newFiles[currentFile],
                      id:
                        newFiles[currentFile].name +
                        newFiles[currentFile].size +
                        newFiles[currentFile].lastModified +
                        newFiles[currentFile].type,
                    });
                  }
                  setFiles(files.concat(formattedFileArray));
                }
              }}
            />
            <UploadIcon width={24} height={24} variant="light" />
            <StyledUploadText>Anexar</StyledUploadText>
          </StyledUploadDiv>
        </StyledOtherDocsDiv>
        <AnimatePresence>
          {files.length > 0 && (
            <StyledDiv
              key="otherDiv"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <AnimatePresence>
                {files.map((otherFile) => (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    key={otherFile.id || otherFile.name}
                  >
                    <FileDisplay
                      fileLink={otherFile.fileUrl}
                      fileName={otherFile.name}
                      fileSizeMB={otherFile.size?.toFixed(2) || 0}
                      onClickDelete={() => handleDeleteFile(otherFile)}
                    />
                  </motion.div>
                ))}
              </AnimatePresence>
            </StyledDiv>
          )}
        </AnimatePresence>
      </DefaultForm>
    </FormProvider>
  );
}

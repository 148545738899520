import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { useLazyQuery } from '@apollo/client';
import { TrashIcon } from 'components/atoms/Icons/TrashIcon';
import { SelectField } from 'components/atoms/SelectField';
import { TextField } from 'components/molecules/TextField';
import { useWorkspace } from 'contexts/WorkspaceContext';
import { FieldContainer } from 'pages/Budgets/components/NewBudget/style';
import { FlexContainer, IconButton } from 'styles/miscellany';
import { clearCurrencyStrBrl, toCurrencyBrl } from 'utils/masks';
import { validator } from 'utils/validators/form-validations';

import { FormGroupWithAdding } from '../../../../../../../components/organisms/FormGroupWithAdding';
import { GET_INTERNAL_PRODUCTIONS_LIST } from '../query';

interface Props {
  groupName: string;
}
export function InternalProduction(props: Props) {
  const { groupName } = props;
  const { currentCompanyId } = useWorkspace();

  const [getInternalProductions, getInternalProductionsRes] = useLazyQuery(
    GET_INTERNAL_PRODUCTIONS_LIST,
    {
      notifyOnNetworkStatusChange: true,
    },
  );
  const { setValue, getValues } = useFormContext();

  const internalProduction =
    getInternalProductionsRes.data?.ConfigurationItems?.items?.map((item) => {
      return { ...JSON.parse(item.metadata), _id: item._id };
    }) || [];
  const internalProductionMap = internalProduction.map((item) => {
    return { value: item.name, label: item.name };
  });

  const updateTotal = (hours, index) => {
    const totalValue = toCurrencyBrl(
      clearCurrencyStrBrl(
        getValues(`${groupName}.internalProduction.${index}.hourValue`),
      ) * hours,
    );
    setValue(`${groupName}.internalProduction.${index}.total`, totalValue);
  };

  const updateHourValue = (index, element) => {
    const saleValue = internalProduction?.find(
      (opt) => opt.name === getValues(element.name),
    )?.saleValue;

    setValue(`${groupName}.internalProduction.${index}.hourValue`, saleValue);

    updateTotal(
      getValues(`${groupName}.internalProduction.${index}.hours`),
      index,
    );
  };

  useEffect(() => {
    getInternalProductions({
      variables: { type: 'production', unit: currentCompanyId },
    });
  }, [getInternalProductions, currentCompanyId]);

  return (
    <div>
      <FormGroupWithAdding
        title="Produção interna"
        groupName={`${groupName}.internalProduction`}
        renderRow={({ index, onDelete }) => (
          <FieldContainer>
            <FlexContainer value={2} style={{ marginTop: '4px' }}>
              <SelectField
                name={`${groupName}.internalProduction.${index}.item`}
                label="Item"
                placeholder="Item"
                options={internalProductionMap}
                validation={validator('required')}
                actionTrigger={(e) => updateHourValue(index, e)}
              />
            </FlexContainer>
            <FlexContainer value={2}>
              <TextField
                name={`${groupName}.internalProduction.${index}.responsible`}
                label="Responsável"
              />
            </FlexContainer>
            <FlexContainer>
              <TextField
                name={`${groupName}.internalProduction.${index}.hours`}
                label="Quantidade de Horas"
                defaultValue={0}
                onInput={(hours) => {
                  updateTotal(hours, index);
                }}
              />
            </FlexContainer>
            <FlexContainer>
              <TextField
                name={`${groupName}.internalProduction.${index}.hourValue`}
                label="Valor Hora"
                defaultValue={0}
                readOnly
              />
            </FlexContainer>
            <FlexContainer>
              <TextField
                name={`${groupName}.internalProduction.${index}.total`}
                label="Total com Impostos"
                pattern="money"
                defaultValue={0}
                readOnly
              />
            </FlexContainer>
            <IconButton
              type="button"
              tabIndex={0}
              onClick={() => onDelete(index)}
            >
              <TrashIcon variant="light" width={20} height={20} />
            </IconButton>
          </FieldContainer>
        )}
      />
    </div>
  );
}

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
import { createElement, useEffect } from 'react';
import toast from 'react-hot-toast';

import { useMutation } from '@apollo/client';
import { UPDATE_BUDGET } from 'pages/Budgets/components/NewBudget/query';
import { colors } from 'styles/colors';

import { DeadIcon } from '../Icons/DeadIcon';
import { HappyIcon } from '../Icons/HappyIcon';
import { RegularIcon } from '../Icons/RegularIcon';
import { SadIcon } from '../Icons/SadIcon';
import { SmileIcon } from '../Icons/SmileIcon';
import { ExpectationContainer } from './style';

interface Props {
  value: number;
  budget: any;
  refetch: any;
}
export function Expectation({ value, budget, refetch }: Props) {
  const Emojis = [DeadIcon, SadIcon, RegularIcon, SmileIcon, HappyIcon];

  const [updateBudget, updateBudgetRes] = useMutation(UPDATE_BUDGET);

  useEffect(() => {
    if (updateBudgetRes.data) {
      toast.success('alteração salva com sucesso!');
      refetch();
    } else if (updateBudgetRes.error) {
      toast.error('Ops, falha ao salvar a alteração.');
    }
  }, [updateBudgetRes.data, updateBudgetRes.error]);

  return (
    <ExpectationContainer>
      {Emojis.map((item: any, index) => (
        <span
          key={index}
          onClick={() =>
            updateBudget({
              variables: {
                id: budget._id,
                unit: budget.unit,
                clientId: budget.client?._id,
                expectation: index + 1,
              },
            })
          }
        >
          {createElement(item, {
            color:
              index === value - 1
                ? index < 3
                  ? colors.textOnBackground.error
                  : colors.primary
                : undefined,
          })}
        </span>
      ))}
    </ExpectationContainer>
  );
}

import { useFormContext } from 'react-hook-form';

import { TextField } from 'components/molecules/TextField';
import { FormGroupWithAdding } from 'components/organisms/FormGroupWithAdding';
import { PresetsColumn } from 'pages/PrintedBudget/components/PrintedBudgetTable/style';
import { PrintedBudgetColumnTitle } from 'pages/PrintedBudget/style';
import { clearCurrencyStrBrl, toCurrencyBrl } from 'utils/masks';

interface Props {
  groupName: string;
}
export function Presets(props: Props) {
  const { groupName } = props;

  const { setValue, getValues } = useFormContext();

  const updateTotal = (hours, index) => {
    const totalValue = toCurrencyBrl(
      clearCurrencyStrBrl(getValues(`${groupName}.presets.${index}.value`)) *
        hours,
    );
    setValue(`${groupName}.presets.${index}.total`, totalValue);
  };

  return (
    <div>
      <PrintedBudgetColumnTitle>Presets</PrintedBudgetColumnTitle>
      <FormGroupWithAdding
        title="Presets"
        groupName={`${groupName}.presets`}
        withoutAdding
        renderRow={({ index }) => (
          <PresetsColumn>
            <TextField
              name={`${groupName}.presets.${index}.item`}
              label="Item"
              placeholder="Item"
              isLight
            />
            <TextField
              name={`${groupName}.presets.${index}.quantity`}
              label="Quantidade"
              defaultValue={0}
              onInput={(hours) => {
                updateTotal(hours, index);
              }}
              isLight
            />
            <TextField
              name={`${groupName}.presets.${index}.value`}
              label="Valor Preset"
              defaultValue={0}
              readOnly
              isLight
            />
            <TextField
              name={`${groupName}.presets.${index}.total`}
              label="Total com Impostos"
              defaultValue={0}
              readOnly
              isLight
            />
          </PresetsColumn>
        )}
      />
    </div>
  );
}

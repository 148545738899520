import { gql } from '@apollo/client';

export const GET_PAYMENT_ITEMS = gql`
  query (
    $limit: Int
    $page: Int
    $search_term: String
    $sorting: [SorterInput]
    $project_id: String
    $unit: String
  ) {
    PaymentItems(
      limit: $limit
      page: $page
      search_term: $search_term
      unit: $unit
      project_id: $project_id
      sorting: $sorting
    ) {
      items {
        _id
        status
        category
        poCode
        paymentDate
        itemDescription
        realizedValue
        files {
          _id
          path
          filename
          originalname
          fileExt
          url
          filesize
          metadata
        }
      }
      pages
    }
  }
`;

export const DELETE_PAYMENT = gql`
  mutation deletePayment($id: String!) {
    deletePayment(id: $id)
  }
`;

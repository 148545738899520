import styled from 'styled-components';
import { colors } from 'styles/colors';

export const StructureRevenueContainer = styled.div``;

export const GroupsText = styled.p`
  color: ${colors.white};
  font-weight: bold;
  margin: 3rem 0 2rem 0;
  padding: 0 1.875rem;
`;

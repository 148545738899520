import styled from 'styled-components';

export const PrintedBudgetTableContainer = styled.div`
  > div {
    align-items: flex-start;
  }
`;

export const PrintedBudgetTableColumn = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 1fr;
  gap: 3rem;
`;

export const InternalProductColumn = styled.div`
  display: grid;
  grid-template-columns: 2.75fr 2.75fr 1.5fr 1.5fr 1.5fr;
  gap: 0 1rem;
`;

export const PresetsColumn = styled.div`
  display: grid;
  grid-template-columns: 3.75fr 1fr 1fr 1fr;
  gap: 0 1rem;
`;

export const ThirdyColumn = styled.div`
  display: grid;
  grid-template-columns: 5fr 6fr 3fr 2fr 3fr 4fr;
  gap: 0 1rem;
`;

export const InvoiceColumn = styled.div`
  display: grid;
  grid-template-columns: 5fr 3fr 2fr 2fr 2fr 4fr 4fr;
  gap: 0 1rem;
`;

export const RightAlign = styled.span`
  display: flex;
  justify-content: right;
`;

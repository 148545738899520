import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import { useLazyQuery, useMutation } from '@apollo/client';
import { CheckBoxIcon } from 'components/atoms/Icons/CheckboxIcon';
import { SelectField } from 'components/atoms/SelectField';
import { Spinner } from 'components/atoms/Spinner';
import { TextArea } from 'components/atoms/TextArea';
import { TextField } from 'components/molecules/TextField';
import { NotificationsForm } from 'components/organisms/NotificationsForm';
import { useDrawer } from 'contexts/DrawerContext';
import { useWorkspace } from 'contexts/WorkspaceContext';
import {
  CREATE_COLLABORATOR,
  GET_COLLABORATOR_BY_ID,
  UPDATE_COLLABORATOR,
} from 'pages/Collaborators/query';
import { StyledPermissionSubtitle } from 'pages/Notifications/components/NotificationForm/style';
import {
  ADD_NOTIFICATION,
  GET_NOTIFICATION_BY_EMAIL,
  UPDATE_NOTIFICATION,
} from 'pages/Notifications/query';
import { DefaultForm } from 'styles/miscellany';
import { removeMoneyMask } from 'utils/formatters/removeMoneyMask';
import { validator } from 'utils/validators/form-validations';

import {
  StyledGridDivDouble,
  StyledRowDiv,
  StyledCheckbox,
  StyledRoleText,
} from './style';

interface CollaboratorFormProps {
  id?: string | boolean;
  refetch: any;
}

export function CollaboratorForm(props: CollaboratorFormProps) {
  const { id, refetch } = props;
  const { currentCompanyId } = useWorkspace();
  const isEditing = !!id;
  const methods = useForm();
  const [getCollaboratorById, getCollaboratorByIdRes] = useLazyQuery(
    GET_COLLABORATOR_BY_ID,
  );
  const collaboratorItem =
    getCollaboratorByIdRes?.data?.ColaboratorItems?.items[0];

  const [updateCollaborator, updateCollaboratorRes] =
    useMutation(UPDATE_COLLABORATOR);
  const [createCollaborator, createCollaboratorRes] =
    useMutation(CREATE_COLLABORATOR);
  const { closeDrawer } = useDrawer();
  const [isPJ, setIsPJ] = useState(false);

  const [updateNotification] = useMutation(UPDATE_NOTIFICATION);

  const [getNotificationByEmail, getNotificationByEmailRes] = useLazyQuery(
    GET_NOTIFICATION_BY_EMAIL,
  );

  const notificationId =
    getNotificationByEmailRes?.data?.NotificationItems?.items[0]?._id;

  const notificationTags =
    getNotificationByEmailRes?.data?.NotificationItems?.items[0]?.tags;

  const [createNotification] = useMutation(ADD_NOTIFICATION);

  useEffect(() => {
    if (!collaboratorItem && isEditing)
      getCollaboratorById({ variables: { id } });

    if (collaboratorItem && isEditing) {
      Object.keys(collaboratorItem).forEach((key) => {
        if (key === '__typename' || !collaboratorItem[key]) return;
        methods.setValue(key, collaboratorItem[key]);
        if (key === 'salary') {
          methods.setValue(
            key,
            `R$ ${collaboratorItem[key].toFixed(2).replace(/[.]/g, ',')}`,
          );
        }
      });
    }
    setIsPJ(!!collaboratorItem?.cnpj);
  }, [collaboratorItem, methods, isEditing, getCollaboratorById, id]);

  useEffect(() => {
    methods.reset({ tags: '' }, { keepValues: true });
    methods.setValue('tags', [notificationTags]);
  }, [methods]);

  useEffect(() => {
    if (collaboratorItem?.email)
      getNotificationByEmail({
        variables: {
          search_term: collaboratorItem.email,
        },
      });
  }, [collaboratorItem]);

  const onSubmit = (dataForm) => {
    if (isEditing) {
      updateCollaborator({
        variables: {
          ...dataForm,
          id,
          unit: currentCompanyId,
          salary: removeMoneyMask(dataForm.salary),
          cnpj: isPJ ? dataForm.cnpj : '',
          cpj: !isPJ ? dataForm.cpf : '',
          area: dataForm.role,
        },
      });
      if (dataForm?.tags && notificationId) {
        if (dataForm.tags[0] === null) {
          const newTags = dataForm.tags.slice(1, dataForm.tags.length);
          updateNotification({
            variables: {
              id: notificationId,
              email: dataForm.email,
              scope: newTags,
            },
          });
        } else {
          updateNotification({
            variables: {
              id: notificationId,
              email: dataForm.email,
              scope: dataForm.tags,
            },
          });
        }
      }
    } else {
      createCollaborator({
        variables: {
          ...dataForm,
          unit: currentCompanyId,
          salary: removeMoneyMask(dataForm.salary),
          area: dataForm.role,
        },
      });
      if (dataForm?.tags) {
        if (dataForm.tags[0] === null) {
          const newTags = dataForm.tags.slice(1, dataForm.tags.length);
          createNotification({
            variables: {
              email: dataForm.email,
              scope: newTags,
              unit: currentCompanyId,
            },
          });
        } else {
          createNotification({
            variables: {
              email: dataForm.email,
              scope: dataForm.tags,
              unit: currentCompanyId,
            },
          });
        }
      }
    }
  };

  useEffect(() => {
    if (createCollaboratorRes.data) {
      toast.success('Cadastro efetuado!');

      refetch();

      closeDrawer();
    }

    if (createCollaboratorRes.error) {
      toast.error('Ops, falha ao realizar o cadastro.');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createCollaboratorRes.data, createCollaboratorRes.error, refetch]);
  // FIXME: Resolve re-render caused by closeDrawer in dependencies

  useEffect(() => {
    if (updateCollaboratorRes.data) {
      toast.success('Cadastro atualizado!');

      refetch();

      closeDrawer();
    }

    if (updateCollaboratorRes.error) {
      toast.error('Ops, falha ao atualizar o cadastro.');
    }
  }, [updateCollaboratorRes.data, updateCollaboratorRes.error, refetch]);
  // FIXME: Resolve re-render caused by closeDrawer in dependencies

  if (
    getCollaboratorByIdRes.loading ||
    createCollaboratorRes.loading ||
    updateCollaboratorRes.loading
  )
    return <Spinner />;

  return (
    <FormProvider {...methods}>
      <StyledPermissionSubtitle>Informações pessoais</StyledPermissionSubtitle>
      <DefaultForm
        onSubmit={methods.handleSubmit(onSubmit)}
        id="collaborator-form"
      >
        <StyledGridDivDouble>
          <TextField name="name" label="Nome" validation={validator('name')} />
          <TextField
            name="email"
            label="Email"
            validation={validator('emailNotRequired')}
          />
          <TextField
            name="cpf"
            label="CPF"
            validation={validator('cpfNotRequired')}
            disabled={isPJ}
            pattern="cpfMask"
          />
          <TextField
            name="birthdate"
            label="Data de Nascimento"
            validation={validator('dateNotRequired')}
            pattern="dateMask"
          />
          <TextField name="address" label="Endereço" />
          <TextField name="city" label="Cidade" />
          <TextField name="nickname" label="Apelido" />
          <StyledRowDiv />
          <StyledRowDiv>
            <StyledCheckbox
              role="button"
              tabIndex={0}
              onClick={() => {
                setIsPJ((value) => !value);
              }}
              onKeyDown={(e) => {
                if (
                  e.type === 'keydown' &&
                  (e.code === 'Space' || e.code === 'Enter')
                ) {
                  setIsPJ((value) => !value);
                }
              }}
            >
              <CheckBoxIcon width={24} height={24} checked={isPJ} />
            </StyledCheckbox>
            <StyledRoleText>Pessoa Jurídica</StyledRoleText>
          </StyledRowDiv>
          <StyledRowDiv />
          <StyledPermissionSubtitle>
            Informações Contratuais
          </StyledPermissionSubtitle>
          <StyledRowDiv />
          <TextField name="role" label="Cargo" pattern="capitalize" />
          <SelectField
            name="contractType"
            label="Contrato de trabalho"
            placeholder="Contrato de trabalho"
            options={[
              { value: 'CLT/Pessoa Física', label: 'CLT/Pessoa Física' },
              { value: 'Contrato PJ', label: 'Contrato PJ' },
            ]}
            validation={{ none: '' }}
          />
          <TextField name="salary" label="Compensação" pattern="moneyPrefix" />
          <TextField
            name="cnpj"
            label="CNPJ"
            validation={validator('cnpjNotRequired')}
            disabled={!isPJ}
            pattern="cnpjMask"
          />
          <TextField name="bankName" label="Banco" />
          <TextField name="bankAgencyNumber" label="Agência" />
          <TextField name="bankAccountNumber" label="Conta Corrente" />
        </StyledGridDivDouble>
        <TextArea name="notes" label="Observações" height="9.875" />
        <NotificationsForm />
      </DefaultForm>
    </FormProvider>
  );
}

CollaboratorForm.defaultProps = {
  id: false,
};

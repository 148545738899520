import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { useLazyQuery } from '@apollo/client';
import { TrashIcon } from 'components/atoms/Icons/TrashIcon';
import { SelectField } from 'components/atoms/SelectField';
import { TextField } from 'components/molecules/TextField';
import { useWorkspace } from 'contexts/WorkspaceContext';
import { FieldContainer } from 'pages/Budgets/components/NewBudget/style';
import { FlexContainer, IconButton } from 'styles/miscellany';
import { clearCurrencyNumberBrl, toCurrencyBrl } from 'utils/masks';
import { validator } from 'utils/validators/form-validations';

import { FormGroupWithAdding } from '../../../../../../components/organisms/FormGroupWithAdding';
import { GET_INTERNAL_PRODUCTIONS_LIST } from '../StructureGroup/query';

interface Props {
  outsourceTotal: number;
  projectTotal: number;
  revenueTotal: number;
  comissions?: any;
}

export function Comissions(props: Props) {
  const { outsourceTotal, projectTotal, revenueTotal, comissions } = props;
  const { currentCompanyId } = useWorkspace();
  const { setValue, getValues } = useFormContext();

  const [getComissions, getCommissionsRes] = useLazyQuery(
    GET_INTERNAL_PRODUCTIONS_LIST,
    {
      notifyOnNetworkStatusChange: true,
    },
  );

  const items =
    getCommissionsRes.data?.ConfigurationItems?.items?.map((item) => {
      return { ...JSON.parse(item.metadata), _id: item._id };
    }) || [];
  const calcBasisMap = items.map((item) => {
    return { value: item.name, label: item.name };
  });

  const updateTotal = (calculationBasis, percentage, index) => {
    const baseValue =
      calculationBasis === 'Sobre custos de terceiros'
        ? outsourceTotal
        : calculationBasis === 'Sobre o valor total do projeto'
        ? projectTotal
        : revenueTotal;
    const totalValue = toCurrencyBrl(
      Number((baseValue * clearCurrencyNumberBrl(percentage)) / 10000).toFixed(
        2,
      ),
    );

    setValue(`budComissions.${index}.total`, totalValue);
  };

  const updateFields = (index, element) => {
    const item = items?.find((opt) => opt.name === getValues(element.name));

    setValue(`budComissions.${index}.type`, item.calculationBasis);
    setValue(`budComissions.${index}.percentage`, item.percentage);

    updateTotal(item.calculationBasis, item.percentage, index);
  };

  useEffect(() => {
    getComissions({
      variables: { type: 'commission', unit: currentCompanyId },
    });
  }, [getComissions, currentCompanyId]);

  useEffect(() => {
    comissions?.forEach((comission, index) => {
      const item = items?.find((opt) => opt.name === comission.item);

      if (item) {
        updateTotal(item.calculationBasis, item.percentage, index);
      }
    });
  }, [comissions]);

  return (
    <div>
      <FormGroupWithAdding
        title="Comissões"
        groupName="budComissions"
        renderRow={({ index, onDelete }) => (
          <FieldContainer>
            <FlexContainer value={2}>
              <SelectField
                name={`budComissions.${index}.item`}
                label="Item"
                placeholder="Item"
                options={calcBasisMap}
                validation={validator('required')}
                actionTrigger={(e) => updateFields(index, e)}
              />
            </FlexContainer>
            <FlexContainer value={2}>
              <TextField
                name={`budComissions.${index}.responsible`}
                label="Responsável"
              />
            </FlexContainer>
            <FlexContainer>
              <TextField
                name={`budComissions.${index}.total`}
                label="Total"
                pattern="moneyPrefix"
                defaultValue={0}
                readOnly
              />
            </FlexContainer>
            <IconButton
              type="button"
              tabIndex={0}
              onClick={() => onDelete(index)}
            >
              <TrashIcon variant="light" width={20} height={20} />
            </IconButton>
          </FieldContainer>
        )}
      />
    </div>
  );
}

import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { Avatar } from 'components/atoms/Avatar';
import { TrashIcon } from 'components/atoms/Icons/TrashIcon';
import { SelectField } from 'components/atoms/SelectField';
import { TextField } from 'components/molecules/TextField';
import { IconButton } from 'styles/miscellany';
import { clearCurrencyNumberBrl } from 'utils/masks';

import { FormHolder, RecognitionFields } from '../../style';
import { DateFieldCustom } from '../DateFieldCustom';

interface Props {
  index: number;
  handleDelete: (index: number) => void;
  onSave: () => void;
}
const RecognitionFormRow = ({ index, handleDelete, onSave }: Props) => {
  const groupName = `recognitions.${index}`;
  const values = useWatch({ name: groupName });
  const { setValue } = useFormContext();

  useEffect(() => {
    if (values?.revenue && values?.cost) {
      const percentage = +(
        (clearCurrencyNumberBrl(values.cost) * 100) /
        clearCurrencyNumberBrl(values.revenue)
      ).toFixed(2);
      setValue(`${groupName}.percentage`, percentage);
    }
  }, [values?.revenue, values?.cost]);

  return (
    <RecognitionFields>
      <Avatar size={60} src={values?.avatar} />
      <FormHolder>
        <TextField
          name={`${groupName}.item`}
          label="Reconhecimento"
          onBlur={onSave}
        />
        <span style={{ marginTop: '10px' }}>
          <SelectField
            name={`${groupName}.state`}
            label="Estado"
            placeholder="Estado"
            options={[{ value: 'provision', label: 'Provisão' }]}
            onChange={onSave}
          />
        </span>
        <DateFieldCustom
          name={`${groupName}.date`}
          label="Data"
          onBlur={onSave}
        />
        <TextField
          name={`${groupName}.revenue`}
          label="Valor reconhecido"
          pattern="moneyPrefix"
          onBlur={onSave}
        />
        <TextField
          name={`${groupName}.cost`}
          label="Custo"
          pattern="moneyPrefix"
          onBlur={onSave}
        />
        <TextField
          name={`${groupName}.percentage`}
          label="% do valor"
          suffix="%"
          pattern="money"
          onBlur={onSave}
          readOnly
        />
      </FormHolder>

      <IconButton type="button" onClick={() => handleDelete(index)}>
        <TrashIcon variant="light" width={24} height={24} />
      </IconButton>
    </RecognitionFields>
  );
};

export default RecognitionFormRow;

import { motion } from 'framer-motion';
import styled from 'styled-components';

export const StyledGridDiv = styled(motion.div)`
  margin-top: -0.5rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1rem;
  padding-bottom: 0 !important;
`;
export const StyledGridDivDouble = styled(motion.div)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1.5rem;
  > div {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
  padding-bottom: 0 !important;
`;
export const StyledPermissionSubtitle = styled.p`
  /* Subtitle 2 */
  padding-left: 1rem;

  padding-top: 0.375rem;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  letter-spacing: 0.25px;
`;

export const StyledCheckbox = styled.div`
  cursor: pointer;
  padding: 2px;
`;

export const StyledRoleText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  letter-spacing: 0.25px;

  /* Dark Theme/Text on Background/Active */

  color: #e1e1e1;

  /* Inside Auto Layout */

  flex: none;
  order: 1;
  flex-grow: 0;
`;
export const StyledRowDiv = styled.div`
  display: flex;
`;

export const StyledPoDiv = styled.div`
  display: flex;
`;

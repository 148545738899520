import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';

export const LabelStyled = styled.p`
  position: absolute;

  transition: 0.1s all;
  line-height: 1rem;

  cursor: text;

  background: ${colors.backgroundLight};
  color: ${colors.textOnBackground.medium};

  top: -0.5rem;
  left: 0.625rem;

  font-size: 0.75rem;
  padding: 0 0.25rem;
  z-index: 80;
`;

export const SelectContainer = styled.div`
  font-size: 0.875rem;
  position: relative;
  display: flex;
  align-items: center;
  min-height: 62px;

  > div {
    position: absolute;
    right: 0;
    min-width: 80px;
    input {
      padding: 0 0 0 40px;
    }
    &.error {
      > div {
        &.select-field__control {
          border: 1px solid transparent;
        }
        &.select-field__control--is-focused {
          box-shadow: none;
        }
        .select-field__placeholder {
          color: ${colors.textOnBackground.error};
        }
        .select-field__indicator {
          border-top: 5px solid ${colors.textOnBackground.error};
        }
      }
    }
    .select-field__menu {
      > div {
        background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.12),
            rgba(255, 255, 255, 0.12)
          ),
          ${colors.backgroundDark};
        box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.14),
          0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2);
        z-index: 81;
      }

      .select-field__option--is-focused {
        background-color: ${colors.backgroundLight};
        border-radius: 4px;
      }
    }
    .select-field__option {
      color: ${colors.textOnBackground.medium};
      padding: 12px;
    }
    > div {
      .select-field__placeholder {
        color: ${colors.textOnBackground.medium};
        font-size: 0.875rem;
      }
      &.select-field__control {
        width: 100%;
        height: 3.875rem;
        padding: 0 1rem;
        border: 1px solid transparent;
        border-radius: 4px;

        background: transparent;
        color: ${colors.textOnBackground.medium};

        outline: none;
      }
      &.select-field__control--is-focused {
        border: 1px solid ${colors.primary};
        box-shadow: 0 0 0 1px ${colors.primary};
      }
      .select-field__single-value {
        color: ${colors.textOnBackground.medium};
      }
      > div {
        &.select-field__menu-list {
          padding: 0;
          ::-webkit-scrollbar {
            width: 6px;
          }
          ::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px transparent;
          }
          ::-webkit-scrollbar-thumb {
            background: #e0e0e0;
            border-radius: 20px;
          }
          ::-webkit-scrollbar-thumb:hover {
            background: #aaa;
          }
        }
        &.select-field__value-container {
          padding: 0;
        }
        > span {
          &.select-field__indicator-separator {
            display: none;
          }
        }
        > div {
          &.select-field__indicator {
            color: transparent;

            width: 0;
            height: 0;
            padding: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;

            border-top: 5px solid ${colors.textOnBackground.medium};
          }
          &.select-field__option--is-focused {
            background-color: transparent;
          }
          &.select-field__option--is-selected {
            background-color: transparent;
          }
        }
      }
    }
  }
`;

interface PreffixProps {
  isDirty?: boolean;
}

export const Preffix = styled.span<PreffixProps>`
  padding: 0 0 0 10px;
  font-size: 0.875rem;
  color: ${colors.textOnBackground.medium};
  line-height: 1rem;
  opacity: 0;
  ${({ isDirty }) =>
    isDirty &&
    css`
      opacity: 1;
    `}
`;

export const ErrorLabel = styled.p`
  font-size: 0.75rem;
  color: ${colors.textOnBackground.error};
  margin-top: 0.25rem;
  margin-left: 1rem;
`;

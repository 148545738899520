import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { useMutation, useLazyQuery } from '@apollo/client';
import { GearIcon } from 'components/atoms/Icons/GearIcon';
import { TrashIcon } from 'components/atoms/Icons/TrashIcon';
import { DeleteModal } from 'components/molecules/DeleteModal';
import { Table } from 'components/organisms/Table';
import { Toolbar } from 'components/organisms/Toolbar';
import { useDrawer } from 'contexts/DrawerContext';
import { useModal } from 'contexts/ModalContext';
import { useWorkspace } from 'contexts/WorkspaceContext';

import { NotificationForm, NotificationI } from './components/NotificationForm';
import { DELETE_NOTIFICATION, NOTIFICATION_LIST } from './query';
import {
  PermissionColumnDivStyled,
  PermissionTextStyled,
  TrashStyled,
} from './style';

export function Notifications() {
  const [searchTerm, setSearchTerm] = useState('');
  const { openDrawer } = useDrawer();
  const { openModal } = useModal();
  const [getNotifications, getNotificationsRes] =
    useLazyQuery(NOTIFICATION_LIST);
  const [deleteNotification, deleteNotificationRes] =
    useMutation(DELETE_NOTIFICATION);
  const notifications =
    getNotificationsRes.data?.NotificationItems?.items || [];
  const { neuronMode, currentCompanyId } = useWorkspace();

  useEffect(() => {
    getNotifications(
      neuronMode ? undefined : { variables: { unit: currentCompanyId } },
    );
  }, [currentCompanyId, getNotifications, neuronMode]);

  const handleDelete = (id: string) => {
    openModal({
      content: (
        <DeleteModal
          onAccept={() =>
            deleteNotification({ variables: { id } }).then(() => {
              if (getNotificationsRes.refetch) {
                getNotificationsRes.refetch();
              }
              toast.success('Notificação deletada com sucesso!');
            })
          }
        />
      ),
    });
  };

  const handleAction = (notification?: NotificationI) => {
    openDrawer({
      title: notification ? 'Editar Notificação' : 'Adicionar Notificação',
      content: (
        <NotificationForm
          notification={notification as NotificationI}
          refetch={getNotificationsRes.refetch}
        />
      ),
      formId: 'company-form',
      width: 600,
    });
  };

  useEffect(() => {
    if (deleteNotificationRes.error) {
      toast.error('Erro ao apagar notificação!');
    }
  }, [deleteNotificationRes.error]);

  return (
    <>
      <Toolbar
        title="Notificações"
        onSearch={setSearchTerm}
        onAction={handleAction}
      />
      <Table
        pages={getNotificationsRes.data?.NotificationItems?.pages}
        fetchData={getNotifications}
        fetchVariables={neuronMode ? {} : { unit: currentCompanyId }}
        searchTerm={searchTerm}
        columns={[
          { Header: 'Email', accessor: 'email' },
          {
            Header: 'Permissões',
            accessor: (originalRow) => {
              return (
                <PermissionColumnDivStyled>
                  {originalRow.scope?.length > 0 &&
                    originalRow.scope.map((permission) => {
                      return (
                        <PermissionTextStyled>
                          {permission}
                        </PermissionTextStyled>
                      );
                    })}
                </PermissionColumnDivStyled>
              );
            },
          },
        ]}
        loading={getNotificationsRes.loading}
        emptyText="Não há registros para consulta"
        data={notifications}
        actions={(rowData) => [
          {
            component: (
              <TrashStyled
                role="button"
                tabIndex={0}
                onClick={() => handleDelete(rowData._id)}
              >
                <TrashIcon variant="light" width={24} height={24} />
              </TrashStyled>
            ),
          },
          {
            component: (
              <TrashStyled
                role="button"
                tabIndex={0}
                onClick={() => handleAction(rowData)}
              >
                <GearIcon variant="light" width={20} height={20} />
              </TrashStyled>
            ),
          },
        ]}
      />
    </>
  );
}

import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { useLazyQuery } from '@apollo/client';
import { TrashIcon } from 'components/atoms/Icons/TrashIcon';
import { SelectField } from 'components/atoms/SelectField';
import { TextField } from 'components/molecules/TextField';
import { useWorkspace } from 'contexts/WorkspaceContext';
import { FieldContainer } from 'pages/Budgets/components/NewBudget/style';
import { FlexContainer, IconButton } from 'styles/miscellany';
import {
  clearCurrencyStr,
  clearCurrencyStrBrl,
  toCurrencyBrl,
  clearCurrencyNumberBrl,
} from 'utils/masks';
import { validator } from 'utils/validators/form-validations';

import { FormGroupWithAdding } from '../../../../../../../components/organisms/FormGroupWithAdding';
import { GET_INTERNAL_PRODUCTIONS_LIST } from '../query';

interface Props {
  groupName: string;
  companyTax: number;
}
export function Outsource(props: Props) {
  const { groupName, companyTax } = props;
  const { currentCompanyId } = useWorkspace();

  const [getOutsource, getOutsourceRes] = useLazyQuery(
    GET_INTERNAL_PRODUCTIONS_LIST,
    {
      notifyOnNetworkStatusChange: true,
    },
  );
  const { setValue, getValues } = useFormContext();

  const outsource =
    getOutsourceRes.data?.ConfigurationItems?.items?.map((item) => {
      return { ...JSON.parse(item.metadata), _id: item._id };
    }) || [];
  const outsourceMap = outsource.map((item) => {
    return { value: item.role, label: item.role };
  });

  const updateTotal = (index) => {
    const totalValue =
      +clearCurrencyStrBrl(getValues(`${groupName}.outsource.${index}.value`)) +
      +clearCurrencyStrBrl(
        getValues(`${groupName}.outsource.${index}.admValue`),
      );
    const companyTaxPercentage = clearCurrencyNumberBrl(companyTax) / 100 + 1;

    setValue(
      `${groupName}.outsource.${index}.total`,
      toCurrencyBrl(
        Number((totalValue * companyTaxPercentage) / 100).toFixed(2),
      ),
    );
  };

  const updatePercentageValue = (index) => {
    const value = clearCurrencyNumberBrl(
      getValues(`${groupName}.outsource.${index}.value`),
    );
    const percentage = clearCurrencyStr(
      getValues(`${groupName}.outsource.${index}.admPercentage`),
    );

    setValue(
      `${groupName}.outsource.${index}.admValue`,
      toCurrencyBrl(Number(value * (percentage / 100)).toFixed(2)),
    );

    updateTotal(index);
  };

  useEffect(() => {
    getOutsource({
      variables: { type: 'outsource', unit: currentCompanyId },
    });
  }, [getOutsource, currentCompanyId]);

  return (
    <div>
      <FormGroupWithAdding
        title="Terceiros"
        groupName={`${groupName}.outsource`}
        renderRow={({ index, onDelete }) => (
          <FieldContainer>
            <FlexContainer value={2}>
              <TextField
                name={`${groupName}.outsource.${index}.name`}
                label="Terceiro"
              />
            </FlexContainer>
            <FlexContainer value={3} style={{ marginTop: '4px' }}>
              <SelectField
                name={`${groupName}.outsource.${index}.item`}
                label="Item"
                placeholder="Item"
                options={outsourceMap}
                validation={validator('required')}
              />
            </FlexContainer>
            <FlexContainer value={2}>
              <TextField
                name={`${groupName}.outsource.${index}.value`}
                label="Valor"
                pattern="moneyPrefix"
                defaultValue={0}
                onKeyUp={() => {
                  updatePercentageValue(index);
                }}
              />
            </FlexContainer>
            <FlexContainer>
              <TextField
                name={`${groupName}.outsource.${index}.admPercentage`}
                label="%adm"
                suffix="%"
                defaultValue={1}
                onKeyUp={() => {
                  updatePercentageValue(index);
                }}
              />
            </FlexContainer>
            <FlexContainer value={2}>
              <TextField
                name={`${groupName}.outsource.${index}.admValue`}
                label="Valor adm"
                defaultValue={0}
                readOnly
              />
            </FlexContainer>
            <FlexContainer value={2}>
              <TextField
                name={`${groupName}.outsource.${index}.total`}
                label="Total com Impostos"
                defaultValue={0}
                readOnly
              />
            </FlexContainer>
            <IconButton
              type="button"
              tabIndex={0}
              onClick={() => onDelete(index)}
            >
              <TrashIcon variant="light" width={20} height={20} />
            </IconButton>
          </FieldContainer>
        )}
      />
    </div>
  );
}

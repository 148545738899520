/* eslint-disable no-console */
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';

import { useLazyQuery, useMutation } from '@apollo/client';
import { Button } from 'components/atoms/Button';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
} from 'components/atoms/Icons/ArrowIcon';
import { SearchField } from 'components/molecules/SearchField';
import { PaymentGroup } from 'components/organisms/PaymentGroup';
import { useWorkspace } from 'contexts/WorkspaceContext';
import { colors } from 'styles/colors';
import { Line } from 'styles/miscellany';

import { BudgetGroupContent } from './components/BudgetGroupContent';
import { GET_PROJECT_PAYMENTS, UPDATE_PAYMENT } from './query';
import {
  ActionHolder,
  ConstControlContent,
  CostControlContainer,
  CostControlHeader,
  GroupsText,
  PageIndex,
  Pagination,
  PaginationButtons,
  RowPerPage,
} from './style';

interface CostControlProps {
  projectData: any;
}

export function CostControl(props: CostControlProps) {
  const { projectData } = props;
  const methods = useForm();
  const [updatePayment, updatePaymentRes] = useMutation(UPDATE_PAYMENT);
  const [getPayment, getPaymentRes] = useLazyQuery(GET_PROJECT_PAYMENTS, {
    notifyOnNetworkStatusChange: true,
  });

  const paymentObj = getPaymentRes.data?.PaymentItems?.items;
  const pages = getPaymentRes.data?.PaymentItems?.pages;
  const budGroups =
    paymentObj?.filter(
      (item) =>
        item.category !== 'repass' &&
        item.category !== 'outsource' &&
        item.category !== 'comissions',
    ) || [];
  const repassGroup =
    paymentObj?.filter((item) => item.category === 'repass') || [];
  const outsourceGroup =
    paymentObj?.filter((item) => item.category === 'outsource') || [];
  const comissionsGroup =
    paymentObj?.filter((item) => item.category === 'comissions') || [];

  const params: any = useParams();
  const { currentCompanyId } = useWorkspace();
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(1);
  const [groupsNames, setGroupsNames] = useState<string[]>([]);

  const fetchPage = useCallback(
    (pageNumber: number) => {
      return getPayment({
        variables: {
          unit: currentCompanyId,
          project_id: params?.id,
          page: pageNumber,
        },
      });
    },
    [getPayment, currentCompanyId, params?.id],
  );

  const handlePrevious = () => {
    fetchPage(pageIndex - 1);
    setPageIndex(pageIndex - 1);
  };

  const handleNext = () => {
    fetchPage(pageIndex + 1);
    setPageIndex(pageIndex + 1);
  };

  useEffect(() => {
    if (currentCompanyId) {
      getPayment({
        variables: {
          project_id: params?.id,
          page: pageIndex,
        },
      });
    }
  }, [getPayment, currentCompanyId, params?.id]);

  useEffect(() => {
    if (budGroups?.length) {
      const namesArray: any[] = [];
      budGroups?.forEach((item) => {
        if (!namesArray.includes(item.groupName)) {
          namesArray.push(item.groupName);
        }
      });
      setGroupsNames(namesArray);
    }
  }, [budGroups?.length]);

  const handleNewGroup = () => {
    const tmpGroups = [...groupsNames];
    tmpGroups.push(`Novo Grupo ${tmpGroups.length}`);
    setGroupsNames(tmpGroups);
  };

  const handleGroupNameChange = (oldName, nameChanged) => {
    budGroups?.forEach((item) => {
      if (item.groupName === oldName) {
        updatePayment({ variables: { id: item._id, groupName: nameChanged } });
      }
    });
  };

  const updateTotals = (groupName, field) => {
    const tempTotal = budGroups
      ?.filter((item: any) => item.groupName === groupName)
      .reduce((sum, current) => sum + current[field], 0);
    return Number(+tempTotal * 100).toFixed(0);
  };

  useEffect(() => {
    if (updatePaymentRes.data) {
      toast.success('Grupo atualizado!');
    }
    if (updatePaymentRes.error) {
      toast.error('Ops, falha ao atualizar nome do grupo.');
    }
  }, [updatePaymentRes.data, updatePaymentRes.error]);

  return (
    <CostControlContainer>
      <CostControlHeader>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit((data) => console.log(data))}>
            <SearchField name="search" />
          </form>
        </FormProvider>
      </CostControlHeader>
      <ConstControlContent>
        <ActionHolder>
          <Button variant="primary" onClick={handleNewGroup}>
            NOVO GRUPO
          </Button>
        </ActionHolder>
        {groupsNames.length > 0 &&
          groupsNames.map((group) => (
            <>
              <PaymentGroup
                title={group}
                onChange={handleGroupNameChange}
                groupOriented
                budgetedTotal={updateTotals(group, 'budgetedValue')}
                realizedTotal={updateTotals(group, 'realizedValue')}
              >
                <BudgetGroupContent
                  data={budGroups?.filter((item) => item.groupName === group)}
                  refetch={getPaymentRes?.refetch}
                  projectData={projectData}
                  groupName={group}
                />
              </PaymentGroup>
              <Line />
            </>
          ))}

        <GroupsText>Repasses</GroupsText>
        <BudgetGroupContent
          data={repassGroup}
          refetch={getPaymentRes?.refetch}
          projectData={projectData}
        />

        <GroupsText>Terceiros</GroupsText>
        <BudgetGroupContent
          data={outsourceGroup}
          refetch={getPaymentRes?.refetch}
          projectData={projectData}
        />

        <Line style={{ margin: '1.5rem 0' }} />

        <PaymentGroup title="Comissões" titleEdit={false} hideCosts defaultOpen>
          <BudgetGroupContent
            data={comissionsGroup}
            refetch={getPaymentRes?.refetch}
            projectData={projectData}
            groupName="Comissões"
          />
        </PaymentGroup>

        <Line style={{ margin: '1.5rem 1.5rem 0' }} />
        <Pagination>
          <RowPerPage>
            <span>Linhas por página:</span>
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 50, 100].map((pageSizeSelect) => (
                <option key={pageSizeSelect} value={pageSizeSelect}>
                  {pageSizeSelect}
                </option>
              ))}
            </select>
          </RowPerPage>
          <PageIndex>
            <span>
              {pageIndex} de {pages}
            </span>
          </PageIndex>
          <PaginationButtons>
            <button
              type="button"
              onClick={handlePrevious}
              disabled={pageIndex <= 1}
            >
              <ArrowLeftIcon
                color={
                  pageIndex <= 1
                    ? colors.textOnBackground.disable
                    : colors.textOnBackground.active
                }
              />
            </button>
            <button
              type="button"
              onClick={handleNext}
              disabled={pageIndex >= pages}
            >
              <ArrowRightIcon
                color={
                  pageIndex >= pages
                    ? colors.textOnBackground.disable
                    : colors.textOnBackground.active
                }
              />
            </button>
          </PaginationButtons>
        </Pagination>
      </ConstControlContent>
    </CostControlContainer>
  );
}

import { gql } from '@apollo/client';

export const GET_COLLABORATORS_LIST = gql`
  query (
    $unit: String!
    $limit: Int
    $page: Int
    $search_term: String
    $sorting: [SorterInput]
  ) {
    ColaboratorItems(
      limit: $limit
      page: $page
      search_term: $search_term
      sorting: $sorting
      unit: $unit
    ) {
      items {
        _id
        name
        avatar
        area
        permission
        is_disabled
      }
      pages
    }
  }
`;

export const GET_COLLABORATOR_BY_ID = gql`
  query getCollaboratorById($id: String!) {
    ColaboratorItems(limit: 1, page: 1, id: $id) {
      items {
        _id
        name
        email
        phone
        birthdate
        avatar
        area
        role
        permission
        cpf
        cnpj
        salary
        notes
        contractType
        bankName
        bankAccountNumber
        bankAgencyNumber
        unit
        address
        city
        state
        country
        cep
        tags
        nickname
      }
      pages
    }
  }
`;

export const UPDATE_COLLABORATOR = gql`
  mutation updateCollaborator(
    $id: String!
    $name: String
    $email: String
    $phone: String
    $birthdate: String
    $avatar: String
    $area: String
    $role: String
    $permission: String
    $cpf: String
    $cnpj: String
    $salary: Float
    $notes: String
    $contractType: String
    $bankName: String
    $bankAccountNumber: String
    $bankAgencyNumber: String
    $unit: String
    $address: String
    $city: String
    $state: String
    $country: String
    $cep: String
    $tags: [String]
    $isDisabled: Boolean
    $nickname: String
  ) {
    colaboratorSetup(
      id: $id
      name: $name
      email: $email
      phone: $phone
      birthdate: $birthdate
      avatar: $avatar
      area: $area
      role: $role
      permission: $permission
      cpf: $cpf
      cnpj: $cnpj
      salary: $salary
      notes: $notes
      contractType: $contractType
      bankName: $bankName
      bankAccountNumber: $bankAccountNumber
      bankAgencyNumber: $bankAgencyNumber
      unit: $unit
      address: $address
      city: $city
      state: $state
      country: $country
      cep: $cep
      tags: $tags
      isDisabled: $isDisabled
      nickname: $nickname
    )
  }
`;

export const CREATE_COLLABORATOR = gql`
  mutation createCollaborator(
    $name: String!
    $email: String
    $phone: String
    $birthdate: String
    $avatar: String
    $area: String
    $role: String
    $permission: String
    $cpf: String
    $cnpj: String
    $salary: Float
    $notes: String
    $contractType: String
    $bankName: String
    $bankAccountNumber: String
    $bankAgencyNumber: String
    $unit: String
    $address: String
    $city: String
    $state: String
    $country: String
    $cep: String
    $tags: [String]
    $nickname: String
  ) {
    createColaborator(
      name: $name
      email: $email
      phone: $phone
      birthdate: $birthdate
      avatar: $avatar
      area: $area
      role: $role
      permission: $permission
      cpf: $cpf
      cnpj: $cnpj
      salary: $salary
      notes: $notes
      contractType: $contractType
      bankName: $bankName
      bankAccountNumber: $bankAccountNumber
      bankAgencyNumber: $bankAgencyNumber
      unit: $unit
      address: $address
      city: $city
      state: $state
      country: $country
      cep: $cep
      tags: $tags
      nickname: $nickname
    ) {
      _id
    }
  }
`;

/* eslint-disable no-return-assign */
/* eslint-disable no-console */
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { useLazyQuery } from '@apollo/client';
import { SearchField } from 'components/molecules/SearchField';
import { BillingControlTable } from 'components/organisms/BillingControlTable';
import { useWorkspace } from 'contexts/WorkspaceContext';
import { toCurrencyBrl } from 'utils/masks';

import { GET_REVENUES } from './query';
import {
  BillingControlContainer,
  BillingControlHeader,
  InvoiceTitle,
  InvoiceValue,
  SearchAction,
  TotalInvoice,
} from './style';

export function BillingControl() {
  const methods = useForm();
  const { currentCompanyId } = useWorkspace();
  const params: any = useParams();
  const [searchTerm, setSearchTerm] = useState('');
  const [getProject, getProjectRes] = useLazyQuery(GET_REVENUES);

  const totalRevenue = getProjectRes.data?.ProjectItems?.items[0].revenue;
  const revenues = getProjectRes.data?.ProjectItems?.items[0]?.revenues;

  const calcRevenueValue = () => {
    if (revenues?.length) {
      const sumRevenues = revenues
        .map((item) => {
          const metadata = item.metadata ? JSON.parse(item.metadata) : {};
          return metadata.value;
        })
        .reduce((curr, prev) => +curr + +prev, 0);

      const total = totalRevenue - sumRevenues;
      return total > 0 ? total : 0;
    }
    return 0;
  };

  const value = calcRevenueValue();

  useEffect(() => {
    if (params.id) {
      getProject({ variables: { id: params.id, unit: currentCompanyId } });
    }
  }, [params.id, currentCompanyId]);

  return (
    <BillingControlContainer>
      <BillingControlHeader>
        <TotalInvoice>
          <InvoiceTitle>Valor a faturar</InvoiceTitle>
          <InvoiceValue>{toCurrencyBrl(value?.toFixed(2))}</InvoiceValue>
        </TotalInvoice>
        <SearchAction>
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit((data) =>
                setSearchTerm(data.search),
              )}
            >
              <SearchField name="search" />
            </form>
          </FormProvider>
        </SearchAction>
      </BillingControlHeader>
      {params?.id && (
        <BillingControlTable
          defaultFilters={{ unit: currentCompanyId, project_id: params.id }}
          searchTerm={searchTerm}
        />
      )}
    </BillingControlContainer>
  );
}

import { motion } from 'framer-motion';
import styled from 'styled-components';
import { colors } from 'styles/colors';

export const TableContainer = styled(motion.div)`
  padding: 1.5rem 1.5rem 1rem;
  margin: 0 0 1rem;
  border-radius: 4px;
  overflow: hidden;
  background-color: ${colors.backgroundLight};
`;

export const TableContentContainer = styled.div`
  overflow-x: auto;
  padding-bottom: 1rem;
`;

export const TableComponent = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const HeadCell = styled.th`
  text-align: left;
  padding: 1rem 0;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.5rem;
  min-width: 100px;
`;

export const BodyRow = styled.tr`
  height: 3.25rem;
  border-top: 1px solid ${colors.outline};

  &:last-child {
    border-bottom: 1px solid ${colors.outline};
  }
`;

export const Pagination = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 1.25rem 2rem;
`;

export const RowPerPage = styled.div`
  margin-right: 1.5rem;
  display: flex;
  align-items: center;

  > select {
    background: transparent;
    border: none;
    color: ${colors.textOnBackground.active};
    font-size: 0.75rem;

    option {
      color: ${colors.textOnPrimary.special};
    }
  }

  > span {
    color: ${colors.textOnBackground.medium};
    font-size: 0.75rem;
  }
`;

export const WithAddingHolder = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 1rem 0.5rem;
`;

export const PageIndex = styled.div`
  margin-right: 1.5rem;

  > span {
    color: ${colors.textOnBackground.medium};
    font-size: 0.75rem;
  }
`;

export const PaginationButtons = styled.div`
  display: flex;
  align-items: center;

  > button {
    background: transparent;
    border: none;

    &:first-child {
      margin-right: 2rem;
    }
  }
`;

export const ActionsHolder = styled.td`
  width: 100%;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > button {
    margin-left: 10px;
  }
`;

export const InvisibleDivContainer = styled.div`
  position: absolute;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  z-index: 5000;
  width: 100%;
  height: 100%;
`;

export const FilterContainer = styled(motion.div)`
  position: absolute;
  text-align: left;
  padding: 15px;
  z-index: 9999;
  cursor: default;

  width: 248px;
  right: 70px;
  top: 220px;

  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.12),
      rgba(255, 255, 255, 0.12)
    ),
    linear-gradient(0deg, #121212, #121212);
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12),
    0px 3px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;

  > p {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 10px;
  }
`;

export const StyledCheckbox = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0;

  > p {
    font-weight: 400;
    font-size: 12px;
    margin: 0 5px;
  }

  > input {
    opacity: 0;
    position: absolute;
    width: 15px;
    height: 15px;
  }
`;

export const TdContainer = styled.td`
  padding: 0 10px 0 0;
`;

import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { TextField } from 'components/molecules/TextField';
import { FormGroupWithAdding } from 'components/organisms/FormGroupWithAdding';
import { useCurrencyConverter } from 'hooks/useCurrencyConverter';
import { InvoiceColumn } from 'pages/PrintedBudget/components/PrintedBudgetTable/style';
import { PrintedBudgetColumnTitle } from 'pages/PrintedBudget/style';
import {
  clearCurrencyStr,
  clearCurrencyNumberBrl,
  toCurrencyBrl,
} from 'utils/masks';

interface Props {
  groupName: string;
}
export function Invoice(props: Props) {
  const { groupName } = props;
  const { getCurrency } = useCurrencyConverter();
  const [currencyRates, setCurrencyRates] = useState({});

  const { setValue, getValues } = useFormContext();

  const updateTotal = (index) => {
    const value = clearCurrencyNumberBrl(
      getValues(`${groupName}.invoice.${index}.value`),
    );
    const hedge = clearCurrencyStr(
      getValues(`${groupName}.invoice.${index}.hedgePercentage`),
    );
    const quotation = getValues(
      `${groupName}.invoice.${index}.currencyQuotation`,
    );

    const totalValue = Number(
      (value + (hedge / 100) * value) * quotation,
    ).toFixed(2);

    setValue(`${groupName}.invoice.${index}.total`, toCurrencyBrl(totalValue));
  };

  const updateConversionValue = (index, selectedCurrency?) => {
    const value = clearCurrencyNumberBrl(
      getValues(`${groupName}.invoice.${index}.value`),
    );
    const quotation = +currencyRates[selectedCurrency.value];

    setValue(`${groupName}.invoice.${index}.currencyQuotation`, quotation);

    setValue(
      `${groupName}.invoice.${index}.currencyConversion`,
      toCurrencyBrl(Number(value * quotation).toFixed(2)),
    );

    updateTotal(index);
  };

  const fetchCurrency = async () => {
    const currency = await getCurrency();
    setCurrencyRates(currency?.rates);
  };

  useEffect(() => {
    fetchCurrency();
  }, []);

  return (
    <div>
      <PrintedBudgetColumnTitle>Invoice</PrintedBudgetColumnTitle>
      <FormGroupWithAdding
        title="Invoice"
        groupName={`${groupName}.invoice`}
        withoutAdding
        renderRow={({ index }) => (
          <InvoiceColumn>
            <TextField
              name={`${groupName}.invoice.${index}.value`}
              label="Valor"
              onChange={(data) => {
                updateConversionValue(index, data);
              }}
              isLight
            />
            <TextField
              name={`${groupName}.invoice.${index}.type`}
              label="Tipo"
              isLight
            />
            <TextField
              name={`${groupName}.invoice.${index}.isParadise`}
              label="Paraíso Fiscal"
              isLight
            />
            <TextField
              name={`${groupName}.invoice.${index}.hedgePercentage`}
              label="Hedge"
              suffix="%"
              onKeyUp={() => {
                updateTotal(index);
              }}
              isLight
            />
            <TextField
              name={`${groupName}.invoice.${index}.currencyQuotation`}
              label="Cotação"
              pattern="quotaMask"
              readOnly
              isLight
            />
            <TextField
              name={`${groupName}.invoice.${index}.currencyConversion`}
              label="R$ Conversão"
              pattern="moneyPrefix"
              defaultValue={0}
              readOnly
              isLight
            />
            <TextField
              name={`${groupName}.invoice.${index}.total`}
              label="R$ Custo total"
              defaultValue={0}
              readOnly
              isLight
            />
          </InvoiceColumn>
        )}
      />
    </div>
  );
}

import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { CheckBoxIcon } from 'components/atoms/Icons/CheckboxIcon';
import {
  StyledPermissionSubtitle,
  StyledCheckbox,
  StyledRoleText,
  StyledRowDiv,
  StyledGridDiv,
} from 'pages/Notifications/components/NotificationForm/style';

export function NotificationsForm() {
  const { setValue, getValues, reset, register } = useFormContext();

  const possiblePermissions = [
    'Admin',
    'Legal',
    'Contábil',
    'Relatórios',
    'Clientes',
    'Projetos',
    'Contas a pagar',
    'Contas a receber',
  ];

  useEffect(() => {
    register('tags', { value: [] });
  }, [register]);

  const checkedValues = getValues('tags') || [];

  return (
    <>
      <StyledPermissionSubtitle>Notificações</StyledPermissionSubtitle>
      <StyledGridDiv>
        {possiblePermissions.map((item) => {
          return (
            <StyledRowDiv>
              <StyledCheckbox
                role="button"
                tabIndex={0}
                onClick={() => {
                  const oldValues = [...checkedValues];
                  const itemIndex = oldValues.indexOf(item);
                  if (itemIndex >= 0) {
                    oldValues.splice(itemIndex, 1);
                  } else {
                    oldValues.push(item);
                  }
                  reset({ tags: '' }, { keepValues: true });
                  setValue('tags', oldValues);
                }}
              >
                <CheckBoxIcon
                  width={24}
                  height={24}
                  checked={checkedValues.indexOf(item) >= 0}
                />
              </StyledCheckbox>
              <StyledRoleText>{item}</StyledRoleText>
            </StyledRowDiv>
          );
        })}
      </StyledGridDiv>
    </>
  );
}

NotificationsForm.defaultProps = {
  notification: null,
};

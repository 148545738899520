import styled from 'styled-components';
import { colors } from 'styles/colors';

export const ToolbarHolder = styled.div`
  position: fixed;
  width: 100%;
  background-color: ${colors.backgroundLight};
  z-index: 81;
`;

export const PrintedBudgetToolbarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  height: 2.5rem;
  min-height: 65px;
`;

export const ButtonsContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  padding: 0.9rem 1.875rem;
  max-height: 65px;
  display: flex;
  justify-content: end;
  background-color: ${colors.backgroundLight};

  > button {
    width: 200px;
  }
`;

import { FieldProps } from 'components/atoms/Field';
import { UploadIcon } from 'components/atoms/Icons/UploadIcon';
import { TextField } from 'components/molecules/TextField';

import { RevenueLinkContainer, IconContainer } from './style';

interface Props extends FieldProps {
  label: string;
}
export function RevenueLink(props: Props) {
  const { name, label, ...rest } = props;
  return (
    <RevenueLinkContainer>
      <TextField name={name} label={label} {...rest} />
      <IconContainer>
        <UploadIcon variant="special" width={20} height={20} />
      </IconContainer>
    </RevenueLinkContainer>
  );
}

import vanillaMasker from 'vanilla-masker';

const allowedMasks = {
  cpf: '999.999.999-99',
  cnpj: '99.999.999/9999-99',
  date: '99/99/9999',
  phone: '(99) 99999-9999',
  zipCode: '99999-999',
  agency: '9999',
  agencyDigit: 'S',
  accountDigit: 'S',
  account: '99999999999',
  creditCardDate: '99/9999',
  creditCardNumber: '9999 9999 9999 9999',
  creditCardCCV: '999',
  percent: '999,99',
};

export const toCurrency = (val) => {
  return vanillaMasker.toMoney(`${val}`, {
    precision: 2,
    separator: ',',
    delimiter: '.',
  });
};

export const toCurrencyBrl = (val) => {
  return vanillaMasker.toMoney(`${val}`, {
    precision: 2,
    separator: ',',
    delimiter: '.',
    unit: 'R$',
  });
};

export const toCurrencyBrlCurrency = (val) => {
  return vanillaMasker.toMoney(`${val}`, {
    precision: 4,
    separator: ',',
    delimiter: '.',
    unit: 'R$',
  });
};

export const clearCurrencyStr = (val) => {
  return val?.replace(/[.,]/g, '');
};

export const clearCurrencyStrBrl = (val) => {
  return val?.replace(/[.,R$]/g, '');
};

export const clearCurrencyBrl = (val) => {
  return val?.replace(/[R$ ]/g, '').replace(/[,]/g, '.');
};

export const clearCurrencyNumberBrl = (val) => {
  return +val?.replace(/[.,R$]/g, '') / 100;
};

export const dateMask = (val) => {
  return vanillaMasker.toPattern(val, '99/99/9999');
};

export const quotaMask = (val) => {
  return vanillaMasker.toPattern(val, '9,9999');
};

export const phoneMask = (val) => {
  return vanillaMasker.toPattern(val, '(99) 99999-9999');
};

export const cnpjMask = (val) => {
  return vanillaMasker.toPattern(val, allowedMasks.cnpj);
};

export const cpfMask = (val) => {
  return vanillaMasker.toPattern(val, allowedMasks.cpf);
};

type Masks = keyof typeof allowedMasks;
type MasksReturnType<T extends Masks> = typeof allowedMasks[T];

export const masks = <T extends Masks>(maskType: T): MasksReturnType<T> => {
  return allowedMasks[maskType];
};

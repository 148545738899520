import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { useLazyQuery, useMutation } from '@apollo/client';
import { GearIcon } from 'components/atoms/Icons/GearIcon';
import { Switch } from 'components/atoms/Switch';
import { Table } from 'components/organisms/Table';
import { Toolbar } from 'components/organisms/Toolbar';
import { useDrawer } from 'contexts/DrawerContext';
import { IconButton } from 'styles/miscellany';
import { capitalize } from 'utils/formatters/capitalize';

import { UserForm } from './components/UserForm';
import { GET_USERS_LIST, UPDATE_USER } from './query';

export function Users() {
  const [searchTerm, setSearchTerm] = useState('');
  const { openDrawer } = useDrawer();
  const [updateUser, updateUserRes] = useMutation(UPDATE_USER);
  const [getUsers, getUsersRes] = useLazyQuery(GET_USERS_LIST, {
    notifyOnNetworkStatusChange: true,
  });
  const items = getUsersRes.data?.UserItems?.items || [];

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const handleAction = (id?) => {
    openDrawer({
      title: `${id ? 'Editar' : 'Adicionar'} Usuário`,
      content: <UserForm id={id} refetch={getUsersRes.refetch} />,
      formId: 'user-form',
      width: 600,
    });
  };

  const handleSwitch = (e, id) => {
    updateUser({
      variables: {
        id,
        isDisabled: e,
      },
    });
  };

  useEffect(() => {
    if (updateUserRes.error) {
      toast.error('Erro ao atualizar usuário!');
    }
  }, [updateUserRes.error]);

  return (
    <>
      <Toolbar
        title="Usuários"
        onSearch={setSearchTerm}
        onAction={handleAction}
      />

      <Table
        pages={getUsersRes.data?.UserItems?.pages}
        fetchData={getUsers}
        searchTerm={searchTerm}
        columns={[
          {
            Header: 'Name',
            accessor: 'name',
            Cell: ({ value }) => capitalize(value),
          },
          { Header: 'Área', accessor: 'area' },
          { Header: 'Permissão', accessor: 'permission' },
        ]}
        loading={getUsersRes.loading}
        data={items}
        emptyText="Não há registros para consulta"
        withAvatar
        actions={(rowData) => [
          {
            component: (
              <Switch
                onChange={(e) => handleSwitch(e, rowData._id)}
                disabled={updateUserRes.loading}
                defaultChecked={rowData?.is_disabled}
              />
            ),
          },
          {
            component: (
              <IconButton onClick={() => handleAction(rowData._id)}>
                <GearIcon variant="light" width={20} height={20} />
              </IconButton>
            ),
          },
        ]}
      />
    </>
  );
}

import { useFormContext } from 'react-hook-form';

import { TextField } from 'components/molecules/TextField';
import { FormGroupWithAdding } from 'components/organisms/FormGroupWithAdding';
import { InternalProductColumn } from 'pages/PrintedBudget/components/PrintedBudgetTable/style';
import { PrintedBudgetColumnTitle } from 'pages/PrintedBudget/style';
import { clearCurrencyStrBrl, toCurrencyBrl } from 'utils/masks';

interface Props {
  groupName: string;
}
export function InternalProduction(props: Props) {
  const { groupName } = props;

  const { setValue, getValues } = useFormContext();

  const updateTotal = (hours, index) => {
    const totalValue = toCurrencyBrl(
      clearCurrencyStrBrl(
        getValues(`${groupName}.internalProduction.${index}.hourValue`),
      ) * hours,
    );
    setValue(`${groupName}.internalProduction.${index}.total`, totalValue);
  };

  return (
    <div>
      <PrintedBudgetColumnTitle>Produção Interna</PrintedBudgetColumnTitle>
      <FormGroupWithAdding
        title="Produção interna"
        groupName={`${groupName}.internalProduction`}
        withoutAdding
        renderRow={({ index }) => (
          <InternalProductColumn>
            <TextField
              name={`${groupName}.internalProduction.${index}.item`}
              label="Item"
              isLight
            />
            <TextField
              name={`${groupName}.internalProduction.${index}.responsible`}
              label="Responsável"
              isLight
            />
            <TextField
              name={`${groupName}.internalProduction.${index}.hours`}
              label="Quantidade de Horas"
              defaultValue={0}
              onInput={(hours) => {
                updateTotal(hours, index);
              }}
              isLight
            />
            <TextField
              name={`${groupName}.internalProduction.${index}.hourValue`}
              label="Valor Hora"
              defaultValue={0}
              readOnly
              isLight
            />
            <TextField
              name={`${groupName}.internalProduction.${index}.total`}
              label="Total com Impostos"
              pattern="money"
              defaultValue={0}
              readOnly
              isLight
            />
          </InternalProductColumn>
        )}
      />
    </div>
  );
}

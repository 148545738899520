import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { useLazyQuery, useMutation } from '@apollo/client';
import { GearIcon } from 'components/atoms/Icons/GearIcon';
import { TrashIcon } from 'components/atoms/Icons/TrashIcon';
import { DeleteModal } from 'components/molecules/DeleteModal';
import { Table } from 'components/organisms/Table';
import { Toolbar } from 'components/organisms/Toolbar';
import { useDrawer } from 'contexts/DrawerContext';
import { useModal } from 'contexts/ModalContext';
import { useWorkspace } from 'contexts/WorkspaceContext';
import { IconButton } from 'styles/miscellany';
import { capitalize } from 'utils/formatters/capitalize';
import { toCurrency, toCurrencyBrl } from 'utils/masks';

import { PresetForm } from './components/PresetsForm';
import { DELETE_PRESET, GET_PRESETS_LIST, UPDATE_PRESET } from './query';

export function Presets() {
  const [searchTerm, setSearchTerm] = useState('');
  const { openModal } = useModal();
  const { openDrawer } = useDrawer();
  const { currentCompanyId } = useWorkspace();
  const [, updatePresetRes] = useMutation(UPDATE_PRESET);
  const [deletePreset] = useMutation(DELETE_PRESET);
  const [getPresets, getPresetsRes] = useLazyQuery(GET_PRESETS_LIST, {
    notifyOnNetworkStatusChange: true,
  });
  const items =
    getPresetsRes.data?.ConfigurationItems?.items?.map((item) => {
      return { ...JSON.parse(item.metadata), _id: item._id };
    }) || [];

  useEffect(() => {
    getPresets({ variables: { type: 'preset', unit: currentCompanyId } });
  }, [getPresets, currentCompanyId]);

  const handleAction = (id?) => {
    openDrawer({
      title: `${id ? 'Editar' : 'Adicionar'} Presets`,
      content: <PresetForm id={id} refetch={getPresetsRes.refetch} />,
      formId: 'preset-form',
      width: 600,
    });
  };

  const handleDelete = (id) => {
    openModal({
      content: (
        <DeleteModal
          onAccept={() => {
            deletePreset({ variables: { id } }).then(() => {
              if (getPresetsRes?.refetch)
                getPresetsRes.refetch({
                  variables: getPresetsRes.variables,
                });
            });
          }}
        />
      ),
    });
  };

  useEffect(() => {
    if (updatePresetRes.error) {
      toast.error('Erro ao atualizar presets!');
    }
  }, [updatePresetRes.error]);

  return (
    <>
      <Toolbar
        title="Presets"
        onSearch={setSearchTerm}
        onAction={handleAction}
      />

      <Table
        pages={getPresetsRes.data?.ConfigurationItems?.pages}
        fetchData={getPresets}
        fetchVariables={{ type: 'preset', unit: currentCompanyId }}
        searchTerm={searchTerm}
        columns={[
          {
            Header: 'Nome',
            accessor: 'name',
            Cell: ({ value }) => capitalize(value),
          },
          {
            Header: 'Valor custo',
            accessor: 'costValue',
            Cell: ({ value }) => toCurrencyBrl(value),
          },
          {
            Header: 'Lucratividade',
            accessor: 'profitability',
            Cell: ({ value }) => `${toCurrency(value)} %`,
          },
          {
            Header: 'Valor de Venda',
            accessor: 'saleValue',
            Cell: ({ value }) => toCurrencyBrl(value),
          },
        ]}
        loading={getPresetsRes.loading}
        data={items}
        emptyText="Não há registros para consulta"
        actions={(rowData) => [
          {
            component: (
              <IconButton
                role="button"
                tabIndex={0}
                onClick={() => handleDelete(rowData._id)}
              >
                <TrashIcon variant="light" width={24} height={24} />
              </IconButton>
            ),
          },
          {
            component: (
              <IconButton onClick={() => handleAction(rowData._id)}>
                <GearIcon variant="light" width={20} height={20} />
              </IconButton>
            ),
          },
        ]}
      />
    </>
  );
}

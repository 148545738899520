import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import { useLazyQuery, useMutation } from '@apollo/client';
import { SelectField } from 'components/atoms/SelectField';
import { Spinner } from 'components/atoms/Spinner';
import { TextField } from 'components/molecules/TextField';
import { useDrawer } from 'contexts/DrawerContext';
import { useWorkspace } from 'contexts/WorkspaceContext';
import { useFetch } from 'hooks/useFetch';
import {
  CREATE_INTERNAL_PRODUCTION,
  GET_THIRDPARTY_BY_ID,
  UPDATE_INTERNAL_PRODUCTION,
} from 'pages/ThirdParties/query';
import { Services } from 'services/service';
import { DefaultForm } from 'styles/miscellany';
import { validator } from 'utils/validators/form-validations';

interface OptionProp {
  value: string;
  label: string;
}
interface ThirdPartyFormProps {
  id?: string | boolean;
  refetch: any;
}

export function ThirdPartyForm(props: ThirdPartyFormProps) {
  const { currentCompanyId } = useWorkspace();
  const { id, refetch } = props;
  const isEditing = !!id;
  const methods = useForm();

  const [accountPlanOptions, setAccountPlanOptions] = useState<OptionProp[]>(
    [],
  );

  const [getThirdPartyById, getThirdPartyByIdRes] =
    useLazyQuery(GET_THIRDPARTY_BY_ID);
  const thirdpartyItem =
    getThirdPartyByIdRes?.data?.ConfigurationItems?.items[0];
  const [updateThirdParty, updateThirdPartyRes] = useMutation(
    UPDATE_INTERNAL_PRODUCTION,
  );
  const [createThirdParty, createThirdPartyRes] = useMutation(
    CREATE_INTERNAL_PRODUCTION,
  );
  const { closeDrawer } = useDrawer();
  const { fetchAuthorized } = useFetch();

  useEffect(() => {
    fetchAuthorized(Services.Plans)
      .then((res) => res.json())
      .then((res: any) => {
        const plans = res?.plan?.data;
        // GET ONLY CHILDREN OF "BALANÇO PATRIMONIAL"
        if (plans[1]) {
          const options: OptionProp[] = [];

          const mapping = (arr) => {
            arr.map((item) => {
              if (item.deleted) {
                return null;
              }

              options.push({
                value: `${item.id}. ${item.title}`,
                label: `${item.id}. ${item.title}`,
              });

              return mapping(item.children);
            });
          };

          mapping(plans[1].children);
          setAccountPlanOptions(options);
        }
      })
      .catch(() => {
        toast.error('Erro ao carregar o plano de contas');
      });
  }, [fetchAuthorized]);

  useEffect(() => {
    if (!thirdpartyItem && isEditing) getThirdPartyById({ variables: { id } });

    if (thirdpartyItem && isEditing) {
      const decodedValues = JSON.parse(thirdpartyItem.metadata);
      Object.entries(decodedValues).forEach(([key, value]) => {
        if (key !== '__typename') {
          methods.setValue(key, value);
        }
      });
    }
  }, [thirdpartyItem, methods, isEditing, getThirdPartyById, id]);

  const onSubmit = (dataForm) => {
    const payload = {
      label: dataForm.role,
      type: 'outsource',
      metadata: JSON.stringify(dataForm),
      unit: currentCompanyId,
    };

    if (isEditing) {
      updateThirdParty({
        variables: {
          ...payload,
          id,
        },
      });
    } else {
      createThirdParty({
        variables: payload,
      });
    }
  };

  useEffect(() => {
    if (createThirdPartyRes.data) {
      toast.success('Cadastro efetuado!');

      refetch();

      closeDrawer();
    }

    if (createThirdPartyRes.error) {
      toast.error('Ops, falha ao realizar o cadastro.');
    }
  }, [createThirdPartyRes.data, createThirdPartyRes.error]);

  useEffect(() => {
    if (updateThirdPartyRes.data) {
      toast.success('Cadastro atualizado!');

      refetch();

      closeDrawer();
    }

    if (updateThirdPartyRes.error) {
      toast.error('Ops, falha ao atualizar o cadastro.');
    }
  }, [updateThirdPartyRes.data, updateThirdPartyRes.error]);

  if (
    getThirdPartyByIdRes.loading ||
    createThirdPartyRes.loading ||
    updateThirdPartyRes.loading
  )
    return <Spinner />;

  return (
    <FormProvider {...methods}>
      <DefaultForm
        onSubmit={methods.handleSubmit(onSubmit)}
        id="thirdparty-form"
      >
        <TextField
          name="role"
          label="Cargo"
          validation={validator('required')}
        />
        <SelectField
          name="accountPlan"
          label="Plano de Conta"
          placeholder="Plano de Conta"
          options={accountPlanOptions}
          validation={validator('required')}
        />
      </DefaultForm>
    </FormProvider>
  );
}

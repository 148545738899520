/* eslint-disable no-return-assign */
/* eslint-disable no-console */
import toast from 'react-hot-toast';

import { useLazyQuery, useMutation } from '@apollo/client';
import { FileIcon } from 'components/atoms/Icons/FileIcon';
import { GearIcon } from 'components/atoms/Icons/GearIcon';
import { SecondaryFileIcon } from 'components/atoms/Icons/SecondaryFileIcon';
import { TrashIcon } from 'components/atoms/Icons/TrashIcon';
import { DeleteModal } from 'components/molecules/DeleteModal';
import { Table } from 'components/organisms/Table';
import { useDrawer } from 'contexts/DrawerContext';
import { useModal } from 'contexts/ModalContext';
import { IconButton, StatusLabel } from 'styles/miscellany';
import { capitalize } from 'utils/formatters/capitalize';

import { BillingControlForm } from './components/BillingControlForm';
import { DELETE_PAYMENT, GET_PAYMENT_ITEMS } from './query';
import { FileIconsContainer } from './style';

interface Props {
  defaultFilters?: any;
  searchTerm?: string;
}
export function BillingControlTable({ defaultFilters, searchTerm }: Props) {
  const { openDrawer } = useDrawer();
  const { openModal } = useModal();
  const [deletePayment] = useMutation(DELETE_PAYMENT);
  const [getPayment, getPaymentRes] = useLazyQuery(GET_PAYMENT_ITEMS);

  const paymentItems = getPaymentRes?.data?.PaymentItems?.items || [];

  paymentItems.forEach((item) => {
    if (item.files) {
      item.parsedFiles = item.files.map((file) => {
        const metadata = file.metadata ? JSON.parse(file.metadata) : {};
        return {
          fileType: metadata.fileType,
          id: file._id,
          url: file.url,
        };
      });
    } else {
      item.parsedFiles = [];
    }
  });

  const translateStatus = {
    pendente: 'active',
    'NF Recebida': 'active',
    vencido: 'error',
    'PO Emitida': 'warning',
    Pago: 'success',
  };

  const translateCategory = {
    internalProduction: 'Produção Interna',
    presets: 'Presets',
    outsource: 'Terceiro',
    repass: 'Repasse',
  };

  const paymentColumns = [
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ value }) => (
        <StatusLabel variant={translateStatus[value] || 'active'}>
          {capitalize(value)}
        </StatusLabel>
      ),
    },
    {
      Header: 'Tipo',
      accessor: 'category',
      Cell: ({ value }) => capitalize(translateCategory[value] || value),
    },
    { Header: 'PO', accessor: 'poCode' },
    { Header: 'Data de Pagamento', accessor: 'paymentDate' },
    { Header: 'Detalhes', accessor: 'itemDescription' },
    {
      Header: 'Comprovante',
      accessor: (rowData) => (
        <FileIconsContainer>
          <IconButton
            role="button"
            disabled={!rowData.parsedFiles[0]}
            onClick={() => window.open(rowData.parsedFiles[0]?.url, '_blank')}
          >
            {rowData.parsedFiles[0]?.fileType === 'boleto' ? (
              <FileIcon variant="active" width={20} height={20} />
            ) : (
              <SecondaryFileIcon variant="active" width={20} height={20} />
            )}
          </IconButton>
          {rowData.category === 'repass' && (
            <IconButton
              role="button"
              disabled={!rowData.parsedFiles[1]}
              onClick={() => window.open(rowData.parsedFiles[1]?.url, '_blank')}
            >
              {rowData.parsedFiles[1]?.fileType === 'nf' ? (
                <FileIcon variant="active" width={20} height={20} />
              ) : (
                <SecondaryFileIcon variant="active" width={20} height={20} />
              )}
            </IconButton>
          )}
        </FileIconsContainer>
      ),
    },
  ];

  const handleEditRow = (rowData) => {
    openDrawer({
      title: 'Configurar Faturamento',
      width: 600,
      content: (
        <BillingControlForm
          paymentId={rowData._id}
          refetch={getPaymentRes.refetch}
        />
      ),
      formId: 'billing-control-form',
    });
  };

  const deleteRow = (id) => {
    deletePayment({ variables: { id } })
      .then(() => {
        toast.success('Pagamento deletado com sucesso!');
        if (getPaymentRes.refetch) {
          getPaymentRes.refetch();
        }
      })
      .catch(() => toast.error('Ocorreu um erro ao deletar o pagamento.'));
  };

  const handleDelete = (id) => {
    openModal({
      content: <DeleteModal onAccept={() => deleteRow(id)} />,
    });
  };

  return (
    <Table
      pages={getPaymentRes?.data?.PaymentItems?.pages}
      fetchData={getPayment}
      fetchVariables={defaultFilters}
      searchTerm={searchTerm}
      columns={paymentColumns}
      loading={getPaymentRes.loading}
      emptyText="Não há registros para consulta"
      data={paymentItems}
      actions={(rowData) => [
        {
          component: (
            <IconButton role="button" onClick={() => handleDelete(rowData._id)}>
              <TrashIcon variant="light" width={20} height={20} />
            </IconButton>
          ),
        },
        {
          component: (
            <IconButton role="button" onClick={() => handleEditRow(rowData)}>
              <GearIcon variant="light" width={20} height={20} />
            </IconButton>
          ),
        },
      ]}
    />
  );
}

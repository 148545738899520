import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';

export const PrintedBudgetContainer = styled.div`
  color: ${colors.backgroundDark};
  background-color: ${colors.white};
  padding: 10rem 1.5rem 2.125rem 1.5rem;
`;

export const PrintedBudgetHeader = styled.div`
  display: flex;
  text-align: end;
  justify-content: space-between;
  padding: 0 0 4.4375rem 0;

  > h3 {
    font-size: 3.125rem;
    font-weight: 100;
  }

  > div > p {
    font-size: 1.25rem;
    font-weight: 500;
  }
`;

export const PrintedBudgetSubtitle = styled.h2`
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 1.5625rem;
  line-height: 2.25rem;
  letter-spacing: 0.15px;
`;

interface PrintedBudgetColumnTitleProps {
  isBold?: boolean;
}

export const PrintedBudgetColumnTitle = styled.h3<PrintedBudgetColumnTitleProps>`
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 2.25rem;
  letter-spacing: 0.15px;
  margin: 1rem 0;
  display: flex;
  align-items: flex-start;

  ${({ isBold }) =>
    isBold &&
    css`
      font-weight: bold;
    `}
`;

export const PrintedBudgetText = styled.p`
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  line-height: 100%;
  letter-spacing: 0.15px;
`;

export const PrintedBudgetSubText = styled.p`
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.15px;
  max-width: 1111px;
`;

export const PrintedBudgetGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr;
  gap: 1.5rem;

  > div {
    padding: 0 100px;
    display: flex;
    align-items: center;

    > textarea {
      height: fit-content;
      padding-top: 15px;
    }
  }
`;

export const PrintedBudgetFlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PrintedBudgetTableColumn = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 1fr;
  gap: 3rem;
`;

export const FieldContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonsContainer = styled.div`
  padding: 1.25rem 1.875rem;
  display: flex;
  justify-content: end;
  background-color: ${colors.backgroundLight};

  > button {
    width: 200px;
  }
`;

export const DefaultForm = styled.form`
  display: grid;
`;

export const BoldText = styled.span`
  font-weight: bold;
`;
